<template>
  <div>
    <v-card outlined>
      <v-card-title class="px-4">
        {{ config.title }}
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Bulk Edit</v-btn>
      </v-card-title>
      <v-card-subtitle class="px-4 text--secondary">{{ config.subtitle }}</v-card-subtitle>
      <div class="pt-0 pb-4 px-4 d-flex flex-column gap-4">
        <v-container fluid>
          <label class="text--secondary">Multiple Divisions</label>
          <v-radio-group row v-model="multipleDivision">
            <template v-slot:label> </template>
            <v-row>
              <v-radio label="Yes" :value="true" readonly></v-radio>
              <v-radio label="No" :value="false" readonly></v-radio>
            </v-row>
          </v-radio-group>
        </v-container>
      </div>
      <div class="pt-0 pb-4 px-4 d-flex flex-column gap-4">
        <div v-for="item in divisions" :key="item.id">
          <label class="text--secondary">Division Title</label>
          <v-text-field
            class="mt-2"
            name="text-field"
            outlined
            hide-details
            dense
            :placeholder="config.placeholderSecondText"
            :value="item.title"
            readonly
          ></v-text-field>
          <v-data-table
            class="elevation-2 mt-2 standing-table-block"
            checkbox-color="primary"
            :headers="headers"
            disable-pagination
            hide-default-footer
            fixed-header
            item-key="id"
            disable-sort
            :items="item.table"
          >
            <template v-slot:[`item.teamImage`]="{ item }">
              <v-img
                height="48"
                width="48"
                :alt="item?.teamImageAltText || 'Opponent Logo'"
                :src="item?.teamImage || '/default-img.png'"
                class="rounded-sm"
              />
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
    <standing-edit-modal v-model="modal" :data="value" @submit="$emit('input', $event)" />
  </div>
</template>
<script lang="ts">
  import { Standing, Division } from '@/store/client/types';
  import Vue, { PropType } from 'vue';
  import StandingEditModal from './StandingEditModal.vue';
  export default Vue.extend({
    name: 'StandingBlock',
    components: {
      StandingEditModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object as PropType<Standing>,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
        headers: [
          {
            text: 'Rank',
            align: 'start',
            value: 'rank',
          },
          {
            text: 'Image',
            align: 'start',
            value: 'teamImage',
          },
          {
            text: 'Team',
            align: 'start',
            value: 'team',
          },
          {
            text: 'Conference Record',
            align: 'start',
            value: 'record',
          },
          {
            text: 'Overall Record',
            align: 'start',
            value: 'prev',
          },
        ],
      };
    },
    computed: {
      divisions(): Division[] {
        return this.value.divisions;
      },
      multipleDivision(): boolean {
        return this.value.multipleDivisions;
      },
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
    },
  });
</script>
<style lang="scss">
  .standing-table-block {
    th {
      background: #f5f5f5 !important;
    }
    td:nth-child(1),
    th:nth-child(1) {
      width: 100px;
    }
    td:nth-child(3),
    th:nth-child(3) {
      width: 100px;
    }
    td:nth-child(4),
    th:nth-child(4) {
      width: 100px;
    }
  }
</style>
