<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title class="text-h6">{{ config.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Edit</v-btn>
    </v-toolbar>
    <v-card-text class="pt-0 h-100">
      <div v-if="value.image && value.image.split('.').pop().includes('pdf')" id="pdf-media-block"></div>
      <v-img v-else :height="90" :width="160" :src="value.image || '/default-img.png'" class="rounded-sm"></v-img>
    </v-card-text>
    <media-modal
      v-if="modal"
      v-model="modal"
      :program="program"
      :type="config.type"
      :title="config.title"
      :data="value"
      @submit="save"
    />
  </v-card>
</template>
<script lang="ts">
  import Vue from 'vue';
  import MediaModal from './MediaModal.vue';
  import { mapGetters } from 'vuex';
  import PDFObject from 'pdfobject';

  export default Vue.extend({
    name: 'MediaBlock',
    components: {
      MediaModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
      },
    },
    data() {
      return {
        modal: false,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
      local(): any {
        return this.value ? this.value : { image: '' };
      },
    },
    watch: {
      value: {
        handler(val) {
          if (val.image && val.image.split('.').pop().includes('pdf')) {
            this.$nextTick().then(() =>
              PDFObject.embed(val.image, '#pdf-media-block', { forceIframe: true, pdfOpenParams: { toolbar: 1 } })
            );
          }
        },
        immediate: true,
      },
    },
    methods: {
      update(key: any, value: any) {
        this.$emit('input', { ...this.local, [key]: value });
      },
      toggleModal() {
        this.modal = !this.modal;
      },
      save(data: any) {
        this.$emit('input', data);
      },
    },
  });
</script>
