import { GetterTree } from 'vuex';
import { BuilderState, Page, PageTemplate } from './types';
import { RootState } from '../types';

export const getters: GetterTree<BuilderState, RootState> = {
  getTemplates(state): PageTemplate[] {
    return state.templates;
  },
  getPages(state): Page[] {
    return state.pages;
  },
  getPage(state): Page {
    return state.page;
  },
  getPageById(state): (id: number) => Nullable<Page> {
    return (id: number) => state.pages.find((page) => page.id === id) || null;
  },
  displayPagePreviewModal(state): boolean {
    return state.pagePreviewModal;
  },
  getPreviewType(state) {
    return state.previewType;
  },
  getProgramId(state) {
    return state.progamId;
  },
};
