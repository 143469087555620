import { GetterTree } from 'vuex';
import { Users, UsersState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<UsersState, RootState> = {
  getUsers(state): Users[] {
    return state.users;
  },
  getPages(state): number {
    return state.pages;
  },
};
