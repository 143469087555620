<template>
  <v-autocomplete
    type="search"
    outlined
    dense
    hide-details
    placeholder="Start typing School/Client Name..."
    :items="clients"
    item-text="name"
    item-value="id"
    v-model="lazyValue"
    :search-input.sync="search"
    :menu-props="{ closeOnClick: true }"
    :hide-no-data="loading"
    no-data-text="No Results Found"
    :loading="loading"
    clearable
    @change="$emit('change')"
  >
    <template #prepend-item>
      <v-subheader class="text-body-1">Select School</v-subheader>
    </template>
    <template #item="{ on, attrs, item }">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-avatar>
          <v-img :src="item.logoUrl" :alt="item.name"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script lang="ts">
  import API from '@/services/api/api';
  import Vue from 'vue';
  export default Vue.extend({
    props: {
      value: {
        type: Number,
      },
      debounce: {
        type: Number,
        default: 500,
      },
    },
    data() {
      return {
        clients: [],
        search: null,
        loading: false,
        debounceCancel: setTimeout((): void => undefined),
      };
    },
    computed: {
      lazyValue: {
        get(): number | null {
          return this.value;
        },
        set(value: number | null) {
          this.$emit('input', value);
        },
      },
    },
    methods: {
      async fetch() {
        try {
          this.loading = true;
          const name = this.search || undefined;
          const { data } = await API.get('/clients', { params: { name, PageIndex: 1, PageSize: 20 } });
          this.clients = data.items;
          this.lazyValue = Number(this.$route?.query?.clientId) || this.lazyValue;
        } catch (error) {
          // console.warn('Client Autocomplete could not fetch clients', error);
        } finally {
          this.loading = false;
        }
      },
    },
    watch: {
      search: {
        handler(search: string | null) {
          if (typeof search === 'string' && search.length < 4) return;
          clearTimeout(this.debounceCancel);
          this.debounceCancel = setTimeout(() => {
            this.$nextTick(async () => {
              await this.fetch();
            });
          }, this.debounce);
        },
        immediate: false,
      },
    },
    created() {
      this.search = this.$route?.query?.client || null;
    },
  });
</script>
