<template>
  <div>
    <v-card outlined>
      <v-card-title class="px-4">
        {{ config.title }}
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Bulk Edit</v-btn>
      </v-card-title>
      <v-card-subtitle class="px-4 text--secondary">{{ config.subtitle }}</v-card-subtitle>
      <div class="pt-0 pb-4 px-4 d-flex flex-column gap-4">
        <v-card v-for="player in players" outlined class="py-4" :key="player.clientRosterPlayerId">
          <v-list-item>
            <v-list-item-avatar class="my-0 rounded" color="grey" size="48" tile>
              <v-img :alt="player.rosterHeadshotAltText" :src="player.rosterHeadshot || '/default-img.png'" />
            </v-list-item-avatar>
            <v-list-item-content class="py-0">
              <v-list-item-title class="mb-2 text-body-2 font-weight-bold">
                <span v-html="`${player.firstName} ${player.lastName}`"></span>
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex align-center gap-2 text-caption">
                <span class="font-weight-bold text--primary">{{ player.position }}</span>
                <v-divider vertical />
                #{{ player.jerseyNumber }}
                <v-divider vertical />
                {{ player.class }}
                <v-divider vertical />
                {{ player.height }}
                <v-divider vertical />
                {{ player.weight }}lbs
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                tile
                class="text-capitalize grey lighten-4 rounded text--primary"
                @click="onRemove(player.clientRosterPlayerId)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </div>
    </v-card>
    <roster-edit-modal v-model="modal" :data="value.players" @submit="$emit('input', { players: $event })" />
  </div>
</template>
<script lang="ts">
  import { PlayerDetails } from '@/store/client/types';
  import Vue, { PropType } from 'vue';
  import RosterEditModal from './RosterEditModal.vue';
  export default Vue.extend({
    name: 'RosterBlock',
    components: {
      RosterEditModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object as PropType<{ players: PlayerDetails[] }>,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
      };
    },
    computed: {
      players(): PlayerDetails[] {
        return this.value.players;
        // const players = this.value.players;
        // players.sort(function (a, b) {
        //   const jerseyNumberA = typeof a.jerseyNumber === 'number' ? a.jerseyNumber.toString() : a.jerseyNumber || '';
        //   const jerseyNumberB = typeof b.jerseyNumber === 'number' ? b.jerseyNumber.toString() : b.jerseyNumber || '';
        //   const jersey = jerseyNumberA.localeCompare(jerseyNumberB, undefined, {
        //     numeric: true,
        //     sensitivity: 'base',
        //   });
        //   const lastNameA = a.lastName || '';
        // const lastNameB = b.lastName || '';
        //   const lastName = lastNameA.localeCompare(lastNameB, undefined, {
        //     numeric: true,
        //     sensitivity: 'base',
        //   });
        //   return jersey || lastName;
        // });
        // return players;
      },
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
      onRemove(id: number) {
        const { players } = this.value;
        const i = players.findIndex((player: any) => player.clientRosterPlayerId === id);
        if (i >= 0) players.splice(i, 1);
      },
    },
  });
</script>
