<template>
  <div>
    <select-confirm v-model="selectedStatus" :program="program"></select-confirm>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { SelectConfirm } from '.';
  import { DigitalProgram } from '@/store/programs/types';

  export default Vue.extend({
    components: {
      SelectConfirm,
    },
    props: {
      program: {
        type: DigitalProgram,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        selectedStatus: this.value,
      };
    },
    watch: {
      value(status: number) {
        this.selectedStatus = status;
        this.$emit('input', this.selectedStatus);
      },
    },
  });
</script>
