<template>
  <div>
    <v-card outlined>
      <v-card-title class="px-4">
        {{ config.title }}
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Edit</v-btn>
      </v-card-title>
      <v-card-subtitle class="px-4 text--secondary">{{ config.subtitle }}</v-card-subtitle>
      <div class="pt-0 pb-4">
        <v-list-item>
          <v-list-item-avatar class="my-0 rounded" color="grey" size="48" tile>
            <v-img
              :alt="coach.rosterHeadshotAltText || 'Coach Headshot'"
              :src="coach.rosterHeadshot || '/default-img.png'"
            />
          </v-list-item-avatar>
          <v-list-item-content class="py-0">
            <v-list-item-title class="mb-2 text-body-2 font-weight-bold">
              {{ coach.firstName }} {{ coach.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-caption">
              {{ coach.title }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
    <coach-edit-modal v-model="modal" :data="coach" @submit="$emit('input', { coach: $event })" />
  </div>
</template>
<script lang="ts">
  import { CoachDetails } from '@/store/client/types';
  import Vue, { PropType } from 'vue';
  import CoachEditModal from './CoachEditModal.vue';
  export default Vue.extend({
    name: 'PlayerBlock',
    components: {
      CoachEditModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object as PropType<{ coach: CoachDetails }>,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
      };
    },
    computed: {
      coach(): CoachDetails {
        return this.value.coach;
      },
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
    },
  });
</script>
