import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { UsersState } from './types';
import { RootState } from '../types';

export const state: UsersState = {
  users: [],
  pages: 1,
};

export const users: Module<UsersState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
