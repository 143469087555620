import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { ui } from './ui/index';
import { user } from './user/index';
import { client } from './client/index';
import { users } from './users/index';
import { programs } from './programs/index';
import { global } from './global';
import { builder } from './builder';
import { LOCAL_STORAGE } from '@/constants/storage';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  key: LOCAL_STORAGE.DIGITAL_PROGRAMS_USER,
});

const store: StoreOptions<RootState> = {
  modules: {
    ui,
    user,
    client,
    users,
    programs,
    global,
    builder,
  },
  plugins: [vuexLocal.plugin],
};

export default new Vuex.Store<RootState>(store);
