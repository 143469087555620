<style lang="scss">
  .select-colored.v-select {
    &.v-select--is-menu-active > .v-input__slot,
    &.v-input--is-focused > .v-input__slot {
      border-radius: inherit !important;
    }

    .v-input__slot {
      min-height: 40px;
      height: 40px;
    }

    .v-select__slot {
      justify-content: center;
    }

    &.v-text-field--outlined fieldset {
      border-width: 0px;
    }

    .v-select__selections {
      flex: 0 0 auto;
      width: initial;

      input {
        margin: 0px;
        max-width: 0px;
        min-width: 0px;
        width: 0px;
      }
    }

    .v-select__selection--comma {
      max-width: inherit;
      margin-right: 0px;
    }

    .v-input__append-inner {
      flex-shrink: initial;
      margin-top: 8px;
      margin-left: 0px;
      padding: 0;
    }
  }

  .select-colored-content .v-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>
<template>
  <v-select
    ref="btn"
    class="select-colored font-weight-bold text-caption"
    hide-details
    dark
    rounded
    outlined
    :background-color="bgColor"
    v-bind="$props"
    v-on="$listeners"
    :menu-props="{ ...menuProps, contentClass: 'select-colored-content', offsetY: true }"
  >
    <template #prepend-item v-if="header">
      <v-subheader class="text-uppercase">{{ header }}</v-subheader>
    </template>
    <template #item="{ item: { color, text }, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" class="align-stretch pl-0">
        <v-sheet class="mr-4" :color="color" width="8px"></v-sheet>
        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script lang="ts">
  import Vue from 'vue';
  import VOverflowBtn from 'vuetify/es5/components/VOverflowBtn';
  export default Vue.extend({
    extends: VOverflowBtn,
    props: {
      header: {
        type: String,
        default: '',
      },
      items: Array,
    },
    computed: {
      bgColor(): string | undefined {
        const { value } = this;
        const items = this.items as { value: any; color: string }[];
        const item = items.find((item) => item.value === value);
        if (!item) return undefined;
        return item.color;
      },
    },
    mounted() {
      const btn = this.$refs.btn as any;
      delete this.$refs.btn;
      Object.assign(this.$refs, btn.$refs);
    },
  });
</script>
