import {
  MediaBlock,
  TextFieldBlock,
  TextImageRecordBlock,
  TextAreaBlock,
  RosterBlock,
  TextImageBlock,
  StaffBlock,
  HtmlBlock,
  PlayerBlock,
  CoachBlock,
  ScheduleBlock,
  TextImageStanding,
  StandingBlock,
  RankingBlock,
} from '@/components/Builder';

export const BLOCKS_MAP = {
  header: TextFieldBlock,
  textimagerecord: TextImageRecordBlock,
  headline: TextFieldBlock,
  media: MediaBlock,
  text: TextAreaBlock,
  fullmedia: MediaBlock,
  roster: RosterBlock,
  staff: StaffBlock,
  textimage: TextImageBlock,
  textImageStanding: TextImageStanding,
  html: HtmlBlock,
  player: PlayerBlock,
  schedule: ScheduleBlock,
  coach: CoachBlock,
  standing: StandingBlock,
  rankings: RankingBlock,
};
