import { render, staticRenderFns } from "./ScheduleBlock.vue?vue&type=template&id=0bbf5e2f"
import script from "./ScheduleBlock.vue?vue&type=script&lang=ts"
export * from "./ScheduleBlock.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports