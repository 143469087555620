<template>
  <v-dialog persistent width="640" :value="value" @input="$emit('input', $event)">
    <v-card>
      <v-card-title class="pt-24 pb-4">{{ title }}</v-card-title>
      <v-card-text class="px-6 py-0">
        <label for="text-area">{{ label }}</label>
      </v-card-text>
      <div class="px-6 pb-6">
        <v-card height="300" class="mt-2" outlined>
          <div ref="holder" id="editorjs" class="custom-editor px-2 py-0 fill-height" @click="setFocus"></div>
        </v-card>
      </div>
      <v-card-actions class="px-6 pb-6 pt-0">
        <v-btn class="text-capitalize" color="primary" depressed @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import EditorJS, { LogLevels } from '@editorjs/editorjs';
  import Header from '@editorjs/header';
  export default Vue.extend({
    name: 'TextAreaModal',
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      data: {
        type: Object,
        default: () => ({
          time: Date.now(),
          blocks: [] as any[],
        }),
      },
    },
    data() {
      return {
        editor: null as EditorJS,
      };
    },
    methods: {
      async onSave() {
        const data = await this.editor.save();
        this.$emit('submit', data);
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
      async reset() {
        await this.editor.isReady;
        this.editor.destroy();
        this.editor = null;
      },
      async setFocus(event: Event) {
        if (event.target !== this.$refs.holder) {
          event.stopPropagation();
          return;
        }
        if (this.editor) {
          await this.editor.isReady;
          this.editor.focus(true);
        }
      },
    },
    watch: {
      async value(visible: boolean) {
        try {
          if (visible) {
            const { data, placeholder } = this;
            if (!this.editor) {
              this.editor = new EditorJS({
                data,
                placeholder,
                minHeight: 0,
                logLevel: 'ERROR' as LogLevels.ERROR,
                tools: {
                  header: Header,
                },
              });
            }
          } else {
            if (this.editor) this.reset();
          }
        } catch (error) {
          // console.warn(error);
        }
      },
    },
  });
</script>
<style lang="scss" scoped>
  .custom-editor {
    overflow-y: auto;
    cursor: pointer;

    :deep(.ce-settings) {
      left: -66px;
    }
  }
</style>
