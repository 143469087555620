<template>
  <v-card outlined>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
    <v-card-text>
      <v-text-field
        outlined
        hide-details
        dense
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event)"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
  import Vue from 'vue';
  export default Vue.extend({
    name: 'InlineFieldBlock',
    props: {
      title: {
        type: String,
        default: '',
      },
      subtitle: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
  });
</script>
