<template>
  <div>
    <top-bar :programId="programId"></top-bar>

    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Delete page {{ deletePageNumber }}? </v-card-title>
        <v-card-text>Are you sure you want to delete this page? This action cannot be undone.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="dialogDelete = false"> Cancel </v-btn>
          <v-btn color="red darken-1" text @click="confirmRemovePage"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container class="pt-0" fluid>
      <v-row class="flex-nowrap">
        <v-col :cols="leftColumnSize">
          <v-card :height="height" class="d-flex flex-column" rounded="lg" outlined>
            <v-card-title class="document-builder grey lighten-5">
              <span class="text-truncate d-block text-h6 text-capitalize"> Document Layout Builder </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-3 grey lighten-5">
              <v-btn block depressed class="text-capitalize" @click="editProgram" color="grey lighten-2">
                Edit Event & Team Info <v-icon small right>mdi-pencil-outline</v-icon>
              </v-btn>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pa-3 grey lighten-5">
              <v-btn block depressed class="text-capitalize" color="grey lighten-2" @click="toggleNewPageModal">
                Create New Page <v-icon small right>mdi-file-plus-outline</v-icon>
              </v-btn>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pa-0 grey lighten-5 overflow-y-auto flex-grow-1">
              <v-list color="grey lighten-3" dense class="py-0">
                <v-list-item
                  :ripple="false"
                  :input-value="currentPage === 0"
                  active-class="grey lighten-1"
                  class="px-0"
                  @click="selectPage(0)"
                >
                  <v-avatar size="40" tile color="grey lighten-4">1</v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="ml-2">Landing Page</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <draggable :list="pages" ghost-class="ghost" @change="updateOrderPage">
                  <template>
                    <v-list-item
                      v-for="page in pages"
                      :ripple="false"
                      active-class="grey lighten-1"
                      :input-value="currentPage === page.id"
                      class="px-0 item-list"
                      :key="page.id"
                      @click="selectPage(page.id)"
                    >
                      <v-avatar size="40" tile :color="`grey lighten-${currentPage === page.id ? '2' : '4'}`">
                        {{ page?.pageNumber }}
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="ml-2">{{ page.title }}</v-list-item-title>
                      </v-list-item-content>
                      <v-menu class="elevation-2">
                        <template #activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" @click.stop @mousedown.stop @touchstart.stop>
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list class="py-0">
                          <v-list-item @click="editPage(page.id)">
                            <v-list-item-icon class="mr-0">
                              <v-icon size="18">mdi-pencil-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Edit Page Details</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="removePage(page?.pageNumber, page.id)">
                            <v-list-item-icon class="mr-0">
                              <v-icon size="18">mdi-delete-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Remove Page</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item>
                  </template>
                </draggable>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pa-3 grey lighten-5">
              <v-btn
                block
                depressed
                class="text-capitalize mb-2"
                color="grey lighten-2"
                :disabled="!orderUpdated"
                @click="onSaveProgram"
              >
                Save
              </v-btn>
              <v-btn
                block
                depressed
                class="text-capitalize"
                color="grey lighten-2"
                :disabled="!canPublish"
                @click="onPublish"
              >
                Publish
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="centerColumnSize">
          <v-card :height="height" class="d-flex flex-column" outlined rounded="lg">
            <v-card-title class="grey lighten-5 py-3 justify-end">
              <span class="text-h6 text-capitalize">Page Builder</span>
              <v-spacer></v-spacer>
              <div>
                <div v-if="currentPage" class="d-flex align-center gap-4">
                  <span class="grey--text text--darken-1 text-body-2">Page Template</span>
                  <v-text-field
                    v-if="page.templateId"
                    class="text-body-1"
                    readonly
                    filled
                    dense
                    hide-details
                    :value="page.template.title"
                  ></v-text-field>
                  <v-text-field
                    v-else
                    class="text-body-1"
                    readonly
                    filled
                    dense
                    hide-details
                    value="Imported Template"
                  ></v-text-field>
                  <v-btn icon @click="editPage(page.id)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </div>
                <!-- <div class="field has-addons">
                  <v-card-text class="pa-3 grey lighten-5">
                    <v-text-field class="text-body-1" readonly filled dense hide-details disabled v-model="showURL">
                      <template slot="append">
                        <v-icon right @click.prevent="copyToClipboard('teste01')">mdi-content-copy</v-icon>
                        <v-icon right @click.prevent="copyToClipboard('teste02')">mdi-link</v-icon>
                      </template>
                    </v-text-field>
                  </v-card-text>
                </div> -->
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="grey lighten-5 flex-grow-1 overflow-y-auto">
              <div v-if="currentPage && displayLandingPage === false" class="d-flex flex-column align-stretch gap-4">
                <component
                  class="mb-"
                  v-for="block in page?.content?.blocks"
                  :is="blocksMap[block.type]"
                  :key="page.id + '-' + block.id"
                  v-model="block.data"
                  :config="block.config"
                />
              </div>
              <div v-else>
                <landing-page v-if="programLoaded" v-model="program.landingPage" ref="form"></landing-page>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-3 grey lighten-5">
              <v-btn depressed class="text-capitalize" color="primary" @click="onPageSave">Save</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="currentPage !== 0"
                @click="removePage(page?.pageNumber, page.id)"
                icon
                tile
                depressed
                light
                class="grey lighten-2 text--primary rounded"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="d-none d-xl-block" cols="4">
          <v-card :height="height" class="d-flex flex-column" outlined rounded="lg">
            <v-card-title class="grey lighten-5">
              <span class="text-h6 text-capitalize">Page Preview</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text
              class="grey lighten-5 flex-grow-1 relative d-flex flex-column align-center justify-center overflow-hidden"
            >
              <v-avatar class="absolute primary--text page-view" tile color="grey lighten-4">
                {{ currentPage === 0 ? 1 : page?.pageNumber }}
              </v-avatar>
              <div ref="wrapper" class="flex-grow-1 relative d-flex justify-center overflow-hidden">
                <svg class="fill-height" viewBox="0 0 337 693" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M42.2366 0C20.7395 0 3.31268 17.4269 3.31268 38.924V87.0152H1.52956C0.684807 87.0152 0 87.7 0 88.5448V110.638C0 111.483 0.684807 112.168 1.52956 112.168H3.31268V133.921H1.52997C0.682608 133.921 0 134.602 0 135.449V179.241C0 180.089 0.682574 180.771 1.52997 180.771H3.31268V192.836H1.52997C0.682608 192.836 0 193.517 0 194.364V238.156C0 239.004 0.682574 239.686 1.52997 239.686H3.31268V653.426C3.31268 674.923 20.7395 692.35 42.2366 692.35H294.828C316.325 692.35 333.752 674.923 333.752 653.426V223.332H335.47C336.317 223.332 337 222.65 337 221.802V150.771C337 149.924 336.317 149.243 335.47 149.243H333.752V38.924C333.752 17.4269 316.325 0 294.828 0H42.2366Z"
                    fill="black"
                  />
                </svg>
                <div class="preview" :style="{ transform: `scale(${previewZoom})` }">
                  <iframe class="frame" v-if="displayFrame" :src="previewUrl" frameborder="0"></iframe>
                </div>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-3 grey lighten-5">
              <v-btn icon tile depressed light class="grey lighten-3 text--primary rounded">
                <v-icon>$smartphone</v-icon>
              </v-btn>
              <v-btn icon tile depressed light class="transparent text--grey rounded" @click="previewPage('tablet')">
                <v-icon>$tablet</v-icon>
              </v-btn>
              <v-btn icon tile depressed light class="transparent text--grey rounded" @click="previewPage('desktop')">
                <v-icon>$desktop</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn depressed class="text-capitalize" color="primary" @click="reloadPreview">Reload</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <new-page-modal :program="programId" v-if="newPageModal" v-model="newPageModal" />
    <edit-page-modal v-model="editPageModal" />
    <new-program-modal
      v-if="editProgramModal"
      :programId="programId"
      :edit="editProgramModal"
      v-model="editProgramModal"
    />
    <page-preview-modal :current-page="currentPage === 0 ? 1 : page?.pageNumber" />
    <confirm-publish-dialog
      v-if="publishProgramModal"
      v-model="publishProgramModal"
      :title="autoPublishLiterals.dialog.publish.title"
      :message="autoPublishLiterals.dialog.publish.message"
      :program="program"
      @confirm="onPublishComplete"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import {
    TopBar,
    NewPageModal,
    EditPageModal,
    InlineFieldBlock,
    LandingPage,
    StaffBlock,
    PagePreviewModal,
  } from '@/components/Builder';

  import { Card } from '@/components';
  import { mapActions, mapGetters } from 'vuex';
  import { ConfirmPublishDialog, NewProgramModal } from '@/components/Programs';
  import { BLOCKS_MAP } from '@/constants/builder';
  import program from '@/services/program/program';
  import { PROGRAMS_STATUS, AUTO_PUBLISH_LITERALS } from '@/constants/programs';
  import { Device } from '@/store/builder/types';
  import { eventBus } from '../main';
  import draggable from 'vuedraggable';
  import config from '@/constants/config';
  import { LandingPageRef } from '@/components/Builder/LandingPage.vue';
  import ClientService from '@/services/client/client';

  export default Vue.extend({
    name: 'Builder',
    props: {
      programId: {
        type: [String, Number],
        required: true,
      },
    },
    components: {
      TopBar,
      Card,
      NewPageModal,
      InlineFieldBlock,
      NewProgramModal,
      EditPageModal,
      LandingPage,
      StaffBlock,
      PagePreviewModal,
      draggable,
      ConfirmPublishDialog,
    },
    data() {
      return {
        blocksMap: BLOCKS_MAP,
        previewHeight: null,
        observer: null,
        newPageModal: false,
        editProgramModal: false,
        publishProgramModal: false,
        currentPage: null,
        editPageModal: false,
        displayLandingPage: false,
        programLoaded: false,
        displayFrame: true,
        orderUpdated: false,
        clientName: '',
        sportName: '',
        dialogDelete: false,
        deletePageNumber: null,
        deletePageId: null,
      };
    },
    computed: {
      ...mapGetters('builder', { pages: 'getPages', page: 'getPage' }),
      ...mapGetters('programs', { program: 'getProgram' }),
      ...mapGetters('user', { token: 'getAuthToken' }),
      height(): number {
        const { $vuetify } = this;
        return $vuetify.breakpoint.height - $vuetify.application.top - 12;
      },
      previewZoom(): number {
        const { previewHeight } = this;
        return (previewHeight - previewHeight * 0.2) / (375 / 0.5625);
      },
      previewUrl(): string {
        const { programId } = this;
        const page = this.currentPage === 0 ? 1 : this.page?.pageNumber;
        return this.clientName !== '' && this.sportName != ''
          ? `${config.frontendUrl}/${this.clientName}/programs/${programId}/${page}?auth-token=${this.token}`
          : '';
      },
      leftColumnSize(): number {
        const { xl, lg } = this.$vuetify.breakpoint;
        if (xl) return 2;
        else if (lg) return 3;
        return 4;
      },
      centerColumnSize(): number {
        const { xl, lg } = this.$vuetify.breakpoint;
        if (xl) return 6;
        else if (lg) return 9;
        return 8;
      },
      canPublish(): boolean {
        return this.program?.status !== PROGRAMS_STATUS.PUBLISHED;
      },
      autoPublishLiterals() {
        return AUTO_PUBLISH_LITERALS;
      },
    },
    methods: {
      fetchSport: ClientService.fetchSport,
      fetchClient: ClientService.fetchClient,
      fetchPublicationPage: ClientService.fetchPublicationPage,
      fetchPublicationSettings: ClientService.fetchPublicationSettings,
      ...mapActions('builder', {
        fetchPages: 'fetchPages',
        fetchPage: 'fetchPage',
        savePage: 'savePage',
        deletePage: 'deletePage',
        setPagePreviewVisibility: 'setPagePreviewVisibility',
        setPagePreviewType: 'setPagePreviewType',
        setPageProgram: 'setPageProgram',
      }),
      ...mapActions('programs', { fetchProgram: 'fetchProgram' }),
      ...mapActions('ui', { showToast: 'showToast' }),
      previewPage(type: Device) {
        this.setPagePreviewType(type);
        this.setPagePreviewVisibility(true);
      },
      toggleNewPageModal() {
        this.editPageModal = false;
        this.newPageModal = !this.newPageModal;
      },
      editProgram() {
        this.editProgramModal = !this.editProgramModal;
      },
      async getProgram(programId: string | number) {
        try {
          await this.fetchProgram(programId);
        } catch (error) {
          // const msg = error?.response?.data || 'Could not fetch program details';
          // console.warn(msg);
        }
      },
      async editPage(pageId: number) {
        await this.selectPage(pageId);
        this.editPageModal = true;
      },
      async confirmRemovePage() {
        try {
          this.dialogDelete = false;
          if (this.currentPage === this.deletePageId) this.currentPage = 0;
          const { programId } = this;
          await this.deletePage({
            programId,
            pageId: this.deletePageId,
          });
        } catch (error) {
          const msg = error?.response?.data || 'Could not delete page';
          this.showToast(msg);
        }
      },
      async removePage(pageNumber: number, pageId: number) {
        this.dialogDelete = true;
        this.deletePageNumber = pageNumber;
        this.deletePageId = pageId;
      },
      async onPageSave() {
        try {
          if (this.currentPage === 0) {
            //update program landing page
            const form = this.$refs.form as LandingPageRef;
            if (form.validate()) {
              await program.updateProgramLandingPage(this.program);
            } else {
              throw new Error('form error');
            }
          } else {
            const { programId, page } = this;
            await this.savePage({
              programId,
              page,
            });
          }
          this.showToast('Page updated successfully');
        } catch (error) {
          const msg = error?.response?.data || 'Could not save page';
          this.showToast(msg);
        }
      },
      async onSaveProgram() {
        try {
          const pagesIds = this.pages.map(({ id }: { id: number }) => id);

          //update program pages order
          await program.updateProgramPagesOrder({ program: this.program, pagesIds: pagesIds });

          this.showToast('Program updated successfully');
        } catch (error) {
          const msg = error?.response?.data || 'Could not save program';
          this.showToast(msg);
        }
      },
      async selectPage(pageId: number) {
        this.currentPage = pageId;
        this.displayLandingPage = false;
        if (pageId) {
          const { programId } = this;
          await this.fetchPage({ programId, pageId });
        }
      },
      onPublish() {
        this.publishProgramModal = true;
      },
      onPublishComplete() {
        this.publishProgramModal = false;
      },
      reloadPreview() {
        // The following didn't work cause violates cross-origin policies
        // const frame = this.$refs.frame as HTMLIFrameElement;
        // frame.contentWindow.location.reload();
        this.displayFrame = false;
        this.$nextTick(() => {
          this.displayFrame = true;
        });
      },
      updateOrderPage() {
        this.orderUpdated = true;
        this.pages.forEach((item: any, index: number) => (item.pageNumber = index + 2));
      },
    },
    async created() {
      try {
        await this.getProgram(this.programId);
        this.setPageProgram(this.programId);
        const { programId } = this;
        const { clientId } = this.program;
        const { clientSportId } = this.program.sport;
        const client = await this.fetchClient(clientId);
        const sport = await this.fetchSport({ clientId, clientSportId });
        this.clientName = client.tenantId;
        this.sportName = sport?.shortName;
        await this.fetchPages(programId);
        this.currentPage = 0;
        this.programLoaded = true;
      } catch (error) {
        const msg = error?.response?.data || 'Could not fetch pages';
        this.showToast(msg);
      }
      eventBus.$on('changePage', (pageId: number) => {
        this.selectPage(pageId);
      });
    },
    async mounted() {
      const wrapper = this.$refs.wrapper as HTMLElement;
      const { height } = wrapper.getBoundingClientRect();
      this.previewHeight = height;
      const observer = new ResizeObserver((entries) => {
        const { height } = entries[0].contentRect;
        this.previewHeight = height;
      });
      observer.observe(this.$refs.wrapper as HTMLElement);
      this.observer = observer;
    },
    beforeDestroy() {
      if (this.observer) this.observer.disconnect();
    },
  });
</script>
<style lang="scss" scoped>
  .page-number {
    width: 40px;
    height: 40px;
  }

  .preview {
    position: absolute;
    transform-origin: 50% 0;
    top: 10%;
  }

  .frame {
    aspect-ratio: 9 / 16;
    width: 375px;
  }

  .page-view {
    top: 16px;
    left: 16px;
    z-index: 10;
  }
  .item-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .ghost {
    background: #bdbdbd;
  }
</style>
