import { MutationTree } from 'vuex';
import { Clients, ClientState } from './types';

export enum ClientMutations {
  SET_CLIENTS = 'SET_CLIENTS',
  SET_PAGES = 'SET_PAGES',
  SET_VIEW_TYPE = 'SET_VIEW_TYPE',
}

export const mutations: MutationTree<Clients> = {
  [ClientMutations.SET_CLIENTS](state, clients: ClientState[]) {
    state.clients = clients;
  },
  [ClientMutations.SET_PAGES](state, pages: number) {
    state.pages = pages;
  },
  [ClientMutations.SET_VIEW_TYPE](state, type: string) {
    state.clientsViewType = type;
  },
};
