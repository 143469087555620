<style scoped>
  .custom-pagination >>> .v-pagination__navigation,
  .custom-pagination >>> .v-pagination__item {
    box-shadow: none;
  }

  .custom-pagination.start >>> .v-pagination {
    justify-content: flex-start;
  }

  .custom-pagination.end >>> .v-pagination {
    justify-content: flex-end;
  }

  .theme--light .custom-pagination >>> .v-pagination__item {
    color: rgba(0, 0, 0, 0.26);
  }

  .theme--light .custom-pagination >>> .v-pagination__item--active {
    color: white;
  }
</style>
<template>
  <v-pagination v-on="$listeners" v-bind="$props" class="custom-pagination" :class="alignClass"></v-pagination>
</template>
<script lang="ts">
  import Vue from 'vue';
  import VPagination from 'vuetify/es5/components/VPagination';
  export default Vue.extend({
    name: 'Pagination',
    extends: VPagination,
    computed: {
      alignClass() {
        return ['start', 'end'].includes(this.align) ? `${this.align}` : '';
      },
    },
    props: {
      align: String,
    },
  });
</script>
