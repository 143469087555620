import { GetterTree } from 'vuex';
import { Clients, ClientState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<Clients, RootState> = {
  getClients(state): ClientState[] {
    return state.clients;
  },
  getPages(state): number {
    return state.pages;
  },
  getViewType(state): string {
    return state.clientsViewType;
  },
};
