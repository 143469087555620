import { GetterTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<UserState, RootState> = {
  getAuthToken(state): string {
    return state.user.authToken;
  },
  getUser(state): object {
    return state.user.data;
  },
  getUserRole(state): number {
    return state.user.data.roleId;
  },
};
