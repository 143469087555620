import { ActionTree } from 'vuex';
import { GlobalState } from './types';
import { RootState } from '../types';
import { GlobalMutations } from './mutations';
import API from '@/services/api/api';

export const actions: ActionTree<GlobalState, RootState> = {
  async fetchClients({ commit }, params) {
    try {
      const { data } = await API.get('/globalservices/clients', { params });
      commit(GlobalMutations.SET_CLIENTS, data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
