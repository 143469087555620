import { MutationTree } from 'vuex';
import { UserState, UserData } from './types';

export enum UserMutations {
  SET_LOGGED_IN = 'LOGGED_IN',
  SET_LOGGED_OUT = 'LOGGED_OUT',
  SET_USER_DATA = 'SET_USER_DATA',
  SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
}

export const mutations: MutationTree<UserState> = {
  [UserMutations.SET_LOGGED_IN](state) {
    state.user.loggedIn = true;
  },
  [UserMutations.SET_LOGGED_OUT](state) {
    state.user = {
      loggedIn: false,
      data: null,
      authToken: '',
    };
  },
  [UserMutations.SET_USER_DATA](state, data: UserData) {
    state.user = {
      ...state.user,
      data: data,
    };
  },
  [UserMutations.SET_AUTH_TOKEN](state, value: string) {
    state.user.authToken = value;
  },
};
