import { MutationTree } from 'vuex';
import { BuilderState, Device, Page, PageTemplate } from './types';

export enum BuilderMutations {
  SET_TEMPLATES = 'SET_TEMPLATES',
  APPEND_PAGE = 'APPEND_PAGE',
  SET_PAGES = 'SET_PAGES',
  SET_PAGE = 'SET_PAGE',
  SET_PAGE_PREVIEW_VISIBILITY = 'SET_PAGE_PREVIEW_VISIBILITY',
  SET_PAGE_PREVIEW_TYPE = 'SET_PAGE_PREVIEW_TYPE',
  SET_PROGRAM_ID = 'SET_PROGRAM_ID',
}

export const mutations: MutationTree<BuilderState> = {
  [BuilderMutations.SET_TEMPLATES](state, templates: PageTemplate[]) {
    state.templates = templates;
  },
  [BuilderMutations.APPEND_PAGE](state, page: Page) {
    state.pages.push(page);
  },
  [BuilderMutations.SET_PAGES](state, pages: Page[]) {
    state.pages = pages;
  },
  [BuilderMutations.SET_PAGE](state, page: Page) {
    state.page = page;
    const pageInList = state.pages.find((p) => p.id === page.id);
    if (pageInList) pageInList.title = page.title;
  },
  [BuilderMutations.SET_PAGE_PREVIEW_VISIBILITY](state, visible: boolean) {
    state.pagePreviewModal = visible;
  },
  [BuilderMutations.SET_PAGE_PREVIEW_TYPE](state, type: Device) {
    state.previewType = type;
  },
  [BuilderMutations.SET_PROGRAM_ID](state, programId: number) {
    state.progamId = programId;
  },
};
