import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Clients from '@/views/Clients.vue';
import Builder from '@/views/Builder.vue';
import Users from '@/views/Users.vue';
import Login from '@/views/Login.vue';
import auth from '@/services/auth/auth';
import store from '@/store';
import { COGNITO_AUTH } from '@/constants/feature_flags';
import { ROLES } from '@/constants/users';

Vue.use(VueRouter);

function requireAuth(to: Route, from: Route, next: any) {
  if (COGNITO_AUTH.ENABLED && !auth?.auth?.isUserSignedIn()) {
    store.dispatch('user/setLoggedIn', false);
  }

  if (!auth.isAuthenticated()) {
    next({ name: 'login' });

    return true;
  }

  if (to.name === 'clients') {
    //restrict clients route for clients
    const role = store.getters['user/getUserRole'];
    if (ROLES.CLIENT_ADMIN === role) {
      next({ name: 'dashboard' });
      return true;
    }
  }
  next();
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: requireAuth,
  },
  {
    path: '/clients',
    name: 'clients',
    component: Clients,
    beforeEnter: requireAuth,
  },
  {
    path: '/builder',
    name: 'builder',
    props: (route) => ({ programId: route.query.program || null }),
    component: Builder,
    beforeEnter: requireAuth,
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { layout: 'auth' },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter(to: Route, from: Route, next: any) {
      store.dispatch('user/setLoggedOut');
      next({
        path: '/login',
      });
    },
  },

  ...(COGNITO_AUTH.ENABLED
    ? [
        {
          path: '/login',
          beforeEnter() {
            auth?.auth?.getSession();
          },
        },
        {
          path: '/login/oauth2/code/cognito',
          beforeEnter(to: Route, from: Route, next: any) {
            const currUrl = window.location.href;
            auth?.auth?.parseCognitoWebResponse(currUrl);
            next();
          },
        },
        {
          path: '/logout',
          beforeEnter(to: Route, from: Route, next: any) {
            auth.logout();
            next({
              path: '/login',
              query: { redirect: to.fullPath },
            });
          },
        },
      ]
    : []),
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
