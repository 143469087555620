<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="917" :persistent="loading">
    <v-form ref="form" @submit.prevent="onSubmit" v-model="isFormValid" lazy-validation :disabled="loading">
      <v-card rounded="lg" :loading="loading ? 'white' : false">
        <v-card-title class="text-captalize white--text primary py-4 px-6 text-h6">Edit Page</v-card-title>
        <v-card-text class="pa-6">
          <label class="grey--text text--darken-1" for="title-field">Page Title</label>
          <v-text-field
            id="title-field"
            class="mt-2"
            outlined
            dense
            :rules="[isRequired]"
            v-model="formData.title"
          ></v-text-field>
          <label class="grey--text text--darken-1">Page Sponsor Image <small>(320x50PX)</small></label>
          <upload-image
            :value="formData.sponsor.image.url"
            height="50"
            width="320"
            :fileUrl="formData.sponsor.image.url"
            @input="formData.sponsor.image.url = $event"
            :client-id="program?.clientId"
            class="mb-5 mt-2"
          />
          <label class="grey--text text--darken-1" for="alt-text-field">Alt Text</label>
          <v-text-field
            id="alt-text-field"
            class="mt-2"
            outlined
            dense
            v-model="formData.sponsor.image.alt"
            required
            :rules="altRules"
          ></v-text-field>
          <label class="grey--text text--darken-1" for="image-link-field">Image URL Redirect Link</label>
          <v-text-field
            id="image-link-field"
            class="mb-5 mt-2"
            hide-details
            outlined
            dense
            v-model="formData.sponsor.image.link"
          ></v-text-field>
          <div class="mt-4 d-flex align-center gap-2">
            <v-btn color="primary" type="submit" depressed class="text-capitalize" :disabled="!isFormValid">
              Done
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset">Clear</v-btn>
            <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { UploadImage } from '@/components';
  import { VForm } from '@/types';
  import { mapActions, mapGetters } from 'vuex';
  import { Page } from '@/store/builder/types';
  export default Vue.extend({
    props: {
      value: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      UploadImage,
    },
    data() {
      return {
        altRules: [(v: string) => !!v || 'Alt Text is required'],
        loading: false,
        isFormValid: true,
        formData: {
          id: null as number,
          title: null as string,
          sponsor: {
            image: {
              url: null as string,
              alt: null as string,
              link: null as string,
            },
          },
          templateId: null as number,
          content: {},
        },
      };
    },
    computed: {
      form(): VForm {
        return this.$refs.form as VForm;
      },
      ...mapGetters('builder', { page: 'getPage' }),
      ...mapGetters('programs', { program: 'getProgram' }),
    },
    methods: {
      ...mapActions('builder', ['savePage']),
      ...mapActions('ui', { showToast: 'showToast' }),
      isRequired(value: string | number): boolean | string {
        return (
          (typeof value === 'string' && !!value.trim().length) ||
          (typeof value === 'number' && value > 0) ||
          'Field is required'
        );
      },
      reset() {
        (this.$refs.form as VForm).reset();
      },
      close() {
        this.$emit('input', false);
      },
      async onSubmit() {
        try {
          const { page, program } = this;
          this.loading = true;
          this.validate();
          if (this.isFormValid) {
            await this.savePage({
              programId: program.id,
              page,
            });
            this.close();
          }
        } catch (error) {
          const msg = error?.response?.data || 'Could not create page';
          this.showToast(msg);
        } finally {
          this.loading = false;
        }
      },
      validate() {
        this.form.validate();
      },
    },
    watch: {
      page(page: Page | null) {
        if (page) this.formData = page;
      },
    },
  });
</script>
