import { GetterTree } from 'vuex';
import { Program, ProgramsState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<ProgramsState, RootState> = {
  getPrograms(state): Program[] {
    return state.programs;
  },
  getPages(state): number {
    return state.pages;
  },
  getProgram(state): Nullable<Program> {
    return state.program;
  },
};
