<template>
  <div>
    <v-select
      :background-color="color"
      class="select-colored font-weight-bold text-caption"
      dark
      hide-details
      :menu-props="{ maxHeight: 400, contentClass: 'select-colored-content', offsetY: true }"
      :items="items"
      rounded
      outlined
      v-model="selectedStatus"
      @change="onSelect"
      :disabled="loading"
    >
      <template #prepend-item>
        <v-subheader class="text-uppercase">Status</v-subheader>
      </template>
      <template #item="{ item: { color, text }, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" class="align-stretch pl-0">
          <v-sheet class="mr-4" :color="color" width="8px"></v-sheet>
          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
    <confirm-publish-dialog
      v-if="OpenPublishProgramModal"
      v-model="OpenPublishProgramModal"
      :title="autoPublishLiterals.dialog.publish.title"
      :message="autoPublishLiterals.dialog.publish.message"
      :program="program"
      @confirm="onPublishComplete"
      @cancel="onPublishCancel"
    />
    <confirm-dialog
      v-if="OpenUnpublishProgramModal"
      v-model="OpenUnpublishProgramModal"
      :title="autoPublishLiterals.dialog.unpublish.title"
      :message="autoPublishLiterals.dialog.unpublish.message"
      @confirm="updateProgramStatus(selectedStatus)"
      @cancel="cancelSelection"
    />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import { ConfirmDialog } from '..';
  import { ConfirmPublishDialog } from '.';
  import { DigitalProgram } from '@/store/programs/types';
  import {
    AUTO_PUBLISH_LITERALS,
    PROGRAMS_STATUS,
    PROGRAMS_STATUS_COLORS,
    PROGRAMS_STATUS_OPTIONS,
  } from '@/constants/programs';

  export default Vue.extend({
    name: 'SelectConfirm',
    components: {
      ConfirmDialog,
      ConfirmPublishDialog,
    },
    props: {
      program: {
        type: DigitalProgram,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
    },
    data: function () {
      return {
        loading: false,
        selectedStatus: this.value,
        OpenPublishProgramModal: false,
        OpenUnpublishProgramModal: false,
      };
    },
    computed: {
      items() {
        return PROGRAMS_STATUS_OPTIONS;
      },
      color(): string | undefined {
        return PROGRAMS_STATUS_COLORS[this.value];
      },
      autoPublishLiterals() {
        return AUTO_PUBLISH_LITERALS;
      },
    },

    methods: {
      ...mapActions('programs', { changeProgramStatus: 'changeStatus' }),
      ...mapActions('ui', { showToast: 'showToast' }),

      async onSelect(status: number) {
        this.selectedStatus = status;
        const oldStatus = this.value;

        if (status == PROGRAMS_STATUS.PUBLISHED) {
          this.loading = true;
          this.OpenPublishProgramModal = true;
        } else if (oldStatus == PROGRAMS_STATUS.PUBLISHED) {
          this.loading = true;
          this.OpenUnpublishProgramModal = true;
        } else {
          await this.updateProgramStatus(status);
        }
      },

      async updateProgramStatus(status: number) {
        const oldStatus = this.value;
        this.loading = true;
        try {
          await this.changeProgramStatus({
            id: this.program.id,
            status: status,
            oldStatus: oldStatus,
          });
          const statusOption = PROGRAMS_STATUS_OPTIONS.find((o) => o.value == status).text;
          this.showToast(`Program status changed to <strong>${statusOption}</strong> successfully`);
          this.confirmSelection();
        } catch (error) {
          const msg = error.response?.data || 'Could not change program status';
          this.showToast(msg);
          this.cancelSelection();
        }
      },

      onPublishComplete() {
        this.$emit('input', this.selectedStatus);
        this.OpenPublishProgramModal = false;
        this.loading = false;
      },
      onPublishCancel() {
        this.OpenPublishProgramModal = false;
        this.resetStatus();
        this.loading = false;
      },

      confirmSelection() {
        this.$emit('input', this.selectedStatus);
        this.OpenUnpublishProgramModal = false;
        this.loading = false;
      },
      cancelSelection() {
        this.OpenUnpublishProgramModal = false;
        this.resetStatus();
        this.loading = false;
      },

      resetStatus() {
        this.selectedStatus = this.value;
      },
    },
    watch: {
      value() {
        this.resetStatus();
      },
    },
  });
</script>
<style lang="scss">
  .select-colored.v-select {
    &.v-select--is-menu-active > .v-input__slot,
    &.v-input--is-focused > .v-input__slot {
      border-radius: inherit !important;
    }

    .v-input__slot {
      min-height: 40px;
      height: 40px;
    }

    .v-select__slot {
      justify-content: center;
    }

    &.v-text-field--outlined fieldset {
      border-width: 0px;
    }

    .v-select__selections {
      flex: 0 0 auto;
      width: initial;

      input {
        margin: 0px;
        max-width: 0px;
        min-width: 0px;
        width: 0px;
      }
    }

    .v-select__selection--comma {
      max-width: inherit;
      margin-right: 0px;
    }

    .v-input__append-inner {
      flex-shrink: initial;
      margin-top: 8px;
      margin-left: 0px;
      padding: 0;
    }
  }

  .select-colored-content .v-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>
