import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { UiState } from './types';
import { RootState } from '../types';

export const state: UiState = {
  sidebarCollapsed: true,
  toast: {
    visible: false,
    content: '',
  },
};

export const ui: Module<UiState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
