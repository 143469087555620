<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div data-testid="clients-list">
    <v-data-table
      height="100%"
      hide-default-footer
      hide-default-header
      :page="page"
      :headers="headers"
      :items-per-page="itemsPerPage"
      :items="clients"
      class="clients-table"
      data-testid="clients-table"
    >
      <template #item="{ item }">
        <tr class="clients-row">
          <td class="text-start name">
            <v-row no-gutters>
              <v-col class="d-flex-grow-0 flex">
                <v-btn
                  x-small
                  elevation="0"
                  size="x-small"
                  color="grey lighten-4"
                  class="mr-2 text-capitalize rounded-circle edit-button"
                  data-testid="submit-btn"
                  height="36"
                  width="36"
                  @click="toggleModal(item)"
                >
                  <v-icon medium>mdi-pencil</v-icon>
                </v-btn>
                <v-avatar size="40" tile class="mr-2 rounded-circle avatar-team">
                  <v-img :src="item.logoUrl" :alt="item.name"></v-img>
                </v-avatar>
              </v-col>

              <v-col>
                {{ item.name }}
              </v-col>
            </v-row>
          </td>
          <td class="text-start location">
            <v-row no-gutters>
              <v-col class="flex-grow-0 mr-2"><v-icon small>mdi-map-marker-outline</v-icon></v-col>
              <v-col> {{ item.location }}</v-col>
            </v-row>
          </td>
          <td class="text-end">
            <v-row>
              <v-col>
                <v-btn
                  depressed
                  :href="item.name"
                  small
                  color="grey lighten-4"
                  class="mr-4 text-capitalize"
                  data-testid="submit-btn"
                  height="36"
                  :to="{ name: 'dashboard', query: { client: item.name, clientId: item.id } }"
                >
                  <span class="mr-2">View Programs</span>
                  <v-icon small>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <EditClientModal v-model="modalOpen" :client="client" v-if="modalOpen"></EditClientModal>
  </div>
</template>

<script lang="ts">
  import { ClientState } from '@/store/client/types';
  import EditClientModal from './EditClientModal.vue';

  import Vue, { PropType } from 'vue';
  const hClass = 'grey--text text--darken-1 font-weight-regular text-body-2';

  export default Vue.extend({
    name: 'ClientsList',
    components: {
      EditClientModal,
    },
    props: {
      clients: {
        type: Array as PropType<ClientState[]>,
        default: (): ClientState[] => [],
      },
      page: {
        type: Number,
      },
      pages: {
        type: Number,
      },
      itemsPerPage: {
        type: Number,
        default: 10,
      },
    },
    data: () => ({
      modalOpen: false,
      client: {} as ClientState,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        { text: '', value: 'name', sortable: false, class: hClass },
        { text: '', value: 'location', sortable: false, class: hClass },
        { text: '', value: 'programs', sortable: false, class: hClass, align: 'end' },
      ],
    }),
    methods: {
      toggleModal(client: ClientState) {
        this.client = client;
        this.modalOpen = !this.modalOpen;
      },
    },
  });
</script>
<style lang="scss" scoped>
  .avatar-team {
    border: 1px solid #e0e0e0;
  }
  .clients-row {
    height: 76px !important;
    .name {
      min-width: 300px;
    }
    .location {
      min-width: 200px;
    }
  }
</style>
