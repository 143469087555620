<template>
  <v-dialog
    content-class="elevation-2"
    width="960"
    :value="value"
    @input="$emit('input', $event)"
    persistent
    scrollable
  >
    <v-form :disabled="loadingForm" @submit.prevent="submitForm">
      <v-card rounded="lg" :loading="loadingForm ? 'white' : false">
        <v-card-title class="text-capitalize white--text primary py-6">{{
          edit ? 'Edit Program' : 'Create New Program'
        }}</v-card-title>
        <v-stepper v-model="curr" color="green">
          <v-stepper-header class="overflow-x-auto elevation-5">
            <v-stepper-step
              :editable="edit"
              v-for="(step, n) in steps"
              :key="n"
              :step="n + 1"
              :color="stepStatus(n + 1)"
            >
              {{ step.name }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-content v-for="(step, n) in steps" :step="n + 1" :key="n" class="px-0 pt-0">
            <v-card-text class="scrollable">
              <template v-if="step.position === 1">
                <v-banner v-if="edit" class="mb-4" single-line>
                  <template v-if="formIsPrefilled">
                    <v-icon slot="icon" color="warning" size="36"> mdi-alert-outline </v-icon>
                    CAUTION: Changing the info here will cause the data on the Team Info and Program Details steps to be
                    overwritten!
                  </template>
                  <template v-if="!formIsPrefilled">
                    <v-icon slot="icon" color="error" size="36"> mdi-hazard-lights </v-icon>
                    WARNING: Continuing to the next step will cause the data on the Team Info and Program Details steps
                    to be overwritten!
                  </template>
                </v-banner>
                <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
                  <template v-if="role !== ROLES.CLIENT_ADMIN">
                    <label class="grey--text text--darken-1">Select a School</label>
                    <v-autocomplete
                      class="mb-5 mt-2"
                      clearable
                      data-testid="school-input"
                      hide-details
                      :items="clients"
                      item-text="name"
                      item-value="id"
                      placeholder="Select a School"
                      :rules="schoolRules"
                      :search-input.sync="searchClient"
                      v-model="formData.clientId"
                      no-data-text="No Results Found"
                      :menu-props="{ closeOnClick: true }"
                      @blur="searchClient = null"
                      outlined
                      @change="onClientChangeAsync()"
                      dense
                      :hide-no-data="loadingClients"
                      :loading="loadingClients"
                    >
                      <template #prepend-item>
                        <v-subheader class="text-body-1">Select School</v-subheader>
                      </template>
                      <template #item="{ on, attrs, item }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-list-item-avatar>
                            <v-img :src="item.logoUrl" :alt="item.name"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template #selection="{ item }">
                        <v-avatar size="25" class="mx-4">
                          <v-img :src="item.logoUrl" :alt="item.name"></v-img>
                        </v-avatar>
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </template>

                  <template v-if="formData.clientId">
                    <label class="grey--text text--darken-1">
                      Select a Sport
                      <span v-if="!formData.sport.clientSportId">or Continue to the next step</span>
                    </label>
                    <v-select
                      hide-details
                      class="mb-5 mt-2"
                      clearable
                      v-model="formData.sport.clientSportId"
                      outlined
                      dense
                      :items="clientSports"
                      @change="onSportChangeAsync()"
                      item-text="name"
                      item-value="clientSportId"
                      placeholder="Select a Sport (optional)"
                      :disabled="loadingForm || !formData.clientId"
                    ></v-select>
                  </template>

                  <template v-if="formData.sport.clientSportId">
                    <label class="grey--text text--darken-1">
                      Select an Event
                      <span v-if="!formData.event.scheduleId">or Continue to the next step</span>
                    </label>
                    <v-select
                      hide-details
                      class="mb-5 mt-2"
                      clearable
                      v-model="formData.event.scheduleId"
                      outlined
                      dense
                      :items="sportEvents"
                      :item-text="getEventTitle"
                      item-value="id"
                      placeholder="Select an Event (optional)"
                      @change="onEventChange()"
                      :disabled="loadingForm || sportEvents?.length === 0"
                    ></v-select>
                  </template>
                </v-form>
              </template>
              <template v-if="step.position === 2">
                <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
                  <v-row>
                    <v-col>
                      <label class="grey--text text--darken-1">Client Name</label>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        v-model="formData.event.home.name"
                        class="mb-5 mt-2"
                        :rules="step.rules"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <label class="grey--text text--darken-1">Client Mascot</label>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        v-model="formData.event.home.mascot"
                        class="mb-5 mt-2"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="2">
                      <label class="grey--text text--darken-1">Current Standing</label>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        v-model="formData.event.home.standing"
                        class="mb-5 mt-2"
                        :rules="step.rules"
                        placeholder="12-0 (1)"
                      ></v-text-field>
                    </v-col> -->
                  </v-row>
                  <label class="grey--text text--darken-1">Client Logo</label>
                  <UploadImage
                    :value="formData.event.home.logoUrl"
                    :fileUrl="formData.event.home.logoUrl"
                    @input="formData.event.home.logoUrl = $event"
                    :client-id="formData.clientId"
                    :rules="imageRule(formData.event.home.logoUrl)"
                    class="mb-5 mt-2"
                  />
                  <template v-if="eventSelected">
                    <label class="grey--text text--darken-1">Client Record</label>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="formData.event.home.wins"
                          class="mb-5 mt-2"
                          type="number"
                          placeholder="Wins"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="formData.event.home.losses"
                          class="mb-5 mt-2"
                          type="number"
                          placeholder="Losses"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="formData.event.home.ties"
                          type="number"
                          class="mb-5 mt-2"
                          placeholder="Ties"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          hide-details
                          class="mb-5 mt-2"
                          v-model="formData.event.home.ranking"
                          outlined
                          dense
                          :items="ranking"
                          item-value="id"
                          placeholder="Ranking"
                          :disabled="!formData.sport.clientSportId || ranking.length === 0"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-radio-group dense v-model="formData.event.location.description" :rules="step.rules" row>
                      <v-radio label="VS" value="VS"></v-radio>
                      <v-radio label="AT" value="AT"></v-radio>
                    </v-radio-group>

                    <v-row>
                      <v-col>
                        <label class="grey--text text--darken-1">Opponent Name</label>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="formData.event.away.name"
                          class="mb-5 mt-2"
                          :rules="step.rules"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <label class="grey--text text--darken-1">Opponent Mascot</label>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="formData.event.away.mascot"
                          class="mb-5 mt-2"
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="2">
                        <label class="grey--text text--darken-1">Current Standing</label>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="formData.event.away.standing"
                          class="mb-5 mt-2"
                        :rules="step.rules"
                          placeholder="ie: 0-12 (1)"
                        ></v-text-field>
                      </v-col> -->
                    </v-row>
                    <label class="grey--text text--darken-1">Opponent School Logo</label>
                    <UploadImage
                      :value="formData.event.away.logoUrl"
                      :fileUrl="formData.event.away.logoUrl"
                      @input="formData.event.away.logoUrl = $event"
                      :client-id="formData.clientId"
                      :rules="imageRule(formData.event.away.logoUrl)"
                      key="2"
                      class="mb-5 mt-2"
                    />
                    <label class="grey--text text--darken-1">Opponent Record</label>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="formData.event.away.wins"
                          class="mb-5 mt-2"
                          type="number"
                          placeholder="Wins"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="formData.event.away.losses"
                          class="mb-5 mt-2"
                          type="number"
                          placeholder="Losses"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="formData.event.away.ties"
                          type="number"
                          class="mb-5 mt-2"
                          placeholder="Ties"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          hide-details
                          class="mb-5 mt-2"
                          v-model="formData.event.away.ranking"
                          outlined
                          dense
                          :items="ranking"
                          item-value="id"
                          placeholder="Ranking"
                          :disabled="!formData.sport.clientSportId || ranking.length === 0"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </template>
                </v-form>
              </template>
              <template v-if="step.position === 3">
                <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
                  <label class="grey--text text--darken-1">Program Title</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details=""
                    v-model="formData.title"
                    class="mb-0 mt-2"
                    :rules="step.rules"
                  ></v-text-field>
                  <small class="grey--text text--darken-1 d-block mb-5">Ex. Bulldogs, Fighting Irish, Hawks...</small>

                  <label class="grey--text text--darken-1 d-block">Location</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details=""
                    v-model="formData.event.location.name"
                    class="mb-5 mt-2"
                  ></v-text-field>

                  <template v-if="eventSelected">
                    <v-radio-group dense v-model="formData.event.location.type" :rules="step.rules" row>
                      <v-radio label="Home" value="Home"></v-radio>
                      <v-radio label="Away" value="Away"></v-radio>
                    </v-radio-group>

                    <v-row>
                      <v-col class="date-input">
                        <v-menu
                          ref="menuDate"
                          v-model="menuDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <label class="grey--text text--darken-1">Date</label>
                            <v-text-field
                              v-model="formData.event.date"
                              outlined
                              dense
                              persistent-hint
                              hint="YYYY-MM-DD format"
                              prepend-inner-icon="mdi-calendar"
                              v-bind="attrs"
                              class="mb-0 mt-2"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            landscape
                            v-model="formData.event.date"
                            @input="menuDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col>
                        <v-menu
                          ref="menuTime"
                          v-model="menuTime"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <label class="grey--text text--darken-1">Event Time</label>
                            <v-text-field
                              outlined
                              dense
                              v-model="time"
                              prepend-inner-icon="mdi-clock-time-four-outline"
                              readonly
                              class="mb-0 mt-2"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menuTime"
                            v-model="time"
                            full-width
                            @click:minute="saveTime()"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <label class="grey--text text--darken-1 d-block mt-4">Broadcaster</label>

                    <v-text-field
                      outlined
                      dense
                      hide-details=""
                      v-model="formData.event.broadcaster"
                      class="mb-5 mt-3"
                    ></v-text-field>
                  </template>
                </v-form>
              </template>
            </v-card-text>
            <v-card-actions class="px-4 pb-0 pt-4 elevation-5">
              <btn text v-if="curr !== 1" @click="curr = n">Back</btn>
              <v-btn color="primary" class="text-capitalize" @click="validate(n)" :disabled="!step.valid">
                {{ step.position != 3 ? 'Continue' : edit === true ? 'Continue' : 'Create' }}
              </v-btn>
              <v-spacer></v-spacer>
              <btn color="grey lighten-4" class="text-capitalize" @click="clearStep(n + 1)">Clear</btn>
              <v-btn color="grey lighten-5" class="text-capitalize elevation-0" @click="closeModal">Cancel</v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Btn } from '@/components';
  import { mapActions, mapGetters } from 'vuex';
  import client from '@/services/client/client';
  import program from '@/services/program/program';
  import { UploadImage } from '@/components';
  import { DateTime } from 'luxon';
  import { ROLES } from '@/constants/users';
  import router from '@/router';

  export default Vue.extend({
    components: { Btn, UploadImage },
    props: {
      value: { type: Boolean, default: false },
      programId: { type: [String, Number], default: null },
      edit: { type: Boolean, default: false },
    },
    data() {
      return {
        selected: null,
        loadingForm: false,
        loadingClients: false,
        curr: 1,
        searchClient: '',
        formData: {
          title: '',
          clientId: null,
          sport: {
            name: '',
            clientSportId: 0,
            globalSportId: 0,
          },
          event: {
            scheduleId: 0,
            location: {
              name: '',
              type: '',
              description: '',
            },
            broadcaster: '',
            date: null,
            home: {
              name: '',
              logoUrl: '',
              mascot: '',
              standing: '',
              wins: null,
              losses: null,
              ties: null,
              ranking: null,
            },
            away: {
              name: '',
              logoUrl: '',
              mascot: '',
              standing: '',
              wins: null,
              losses: null,
              ties: null,
              ranking: null,
            },
          },
          landingPage: {
            image: {
              url: '',
              link: '',
              alt: '',
            },
            isCover: true,
          },
          status: 2,
        },
        time: null,

        steps: [
          {
            name: 'Program Info',
            rules: [(v: string) => !!v || 'Required.'],
            valid: true,
            position: 1,
          },
          {
            name: 'Team Info',
            rules: [(v: string) => !!v || 'Required.'],
            valid: true,
            position: 2,
          },
          {
            name: 'Program Details',
            rules: [(v: string) => !!v || 'Required.'],
            valid: true,
            position: 3,
          },
        ],
        schoolRules: [(v: string) => !!v || 'School is required'],
        // sportRules: [(v: string) => !!v || 'Sport is required'],
        // eventRules: [(v: string) => !!v || 'Event is required'],
        valid: false,
        stepForm: [],
        clientSports: [],
        sportEvents: [],
        ranking: [
          'NR',
          '#1',
          '#2',
          '#3',
          '#4',
          '#5',
          '#6',
          '#7',
          '#8',
          '#9',
          '#10',
          '#11',
          '#12',
          '#13',
          '#14',
          '#15',
          '#16',
          '#17',
          '#18',
          '#19',
          '#20',
          '#21',
          '#22',
          '#23',
          '#24',
          '#25',
        ],
        menuDate: false,
        menuTime: false,
        selectedEvent: null,
        eventSelected: false,
        formIsPrefilled: false,
        role: this.$store.getters['user/getUserRole'],
        ROLES,
        selectedClient: null,
      };
    },
    computed: {
      ...mapGetters('client', { clients: 'getClients' }),
      ...mapGetters('user', { user: 'getUser' }),
      ...mapGetters('programs', { getProgram: 'getProgram' }),
    },

    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      ...mapActions('programs', { fetchProgram: 'fetchProgram' }),
      ...mapActions('client', { createClient: 'createClient', fetchClients: 'fetchClients' }),
      async submitForm() {
        this.loadingForm = true;

        let data = JSON.parse(JSON.stringify(this.formData));

        if (this.eventSelected) {
          const date = new Date(this.formData.event.date + ' ' + this.time);
          data.event.date = date.toISOString();
          const homeWins = data.event.home.wins ? `${data.event.home.wins}` : '0';
          const homeLosses = data.event.home.losses ? `-${data.event.home.losses}` : '-0';
          const homeTies = data.event.home.ties ? `-${data.event.home.ties}` : '';
          const homeRanking =
            data.event.home.ranking != 'NR' && data.event.home.ranking ? `(${data.event.home.ranking}) ` : '';
          let homeStading = '';
          if (data.event.home.wins || data.event.home.losses || data.event.home.ties || data.event.home.ranking) {
            homeStading = `${homeRanking}${homeWins}${homeLosses}${homeTies}`;
          }

          const awayWins = data.event.away.wins ? `${data.event.away.wins}` : '0';
          const awayLosses = data.event.away.losses ? `-${data.event.away.losses}` : '-0';
          const awayTies = data.event.away.ties ? `-${data.event.away.ties}` : '';
          const awayRanking =
            data.event.away.ranking != 'NR' && data.event.away.ranking ? `(${data.event.away.ranking}) ` : '';
          let awayStading = '';
          if (data.event.away.wins || data.event.away.losses || data.event.away.ties || data.event.away.ranking) {
            awayStading = `${awayRanking}${awayWins}${awayLosses}${awayTies}`;
          }

          data.event.home.standing = homeStading;
          data.event.away.standing = awayStading;

          delete data.event.home.wins;
          delete data.event.home.losses;
          delete data.event.home.ties;
          delete data.event.home.ranking;
          delete data.event.away.wins;
          delete data.event.away.losses;
          delete data.event.away.ties;
          delete data.event.away.ranking;
        }

        this.loadingForm = true;
        try {
          if (this.edit) {
            await program.updateProgram(data);
            await this.fetchProgram(this.programId);
          } else {
            const result = await program.createProgram(data);
            router.push({ name: 'builder', query: { program: result.id } });
          }

          this.handleSuccess();
        } catch (error) {
          this.handleFailure();
        }
        this.loadingForm = false;
      },
      handleSuccess() {
        this.showToast(`Program ${this.edit ? 'updated' : 'added'} successfully`);
        this.closeModal();
        this.loadingForm = false;
        this.$emit('save');
      },
      handleFailure() {
        this.showToast(`Could not ${this.edit ? 'update' : 'add'} the program`);
        this.loadingForm = false;
      },
      resetForm() {
        this.loadingForm = false;
        this.selected = null;
      },
      closeModal() {
        this.$emit('input', false);
      },
      clearSelectedSport() {
        this.formData.sport = {
          name: '',
          clientSportId: 0,
          globalSportId: 0,
        };
        this.clearSelectedEvent();
      },
      clearSelectedEvent() {
        this.formData.event.scheduleId = 0;
        this.selectedEvent = null;
        this.eventSelected = false;
        this.clearStep(2);
        this.clearStep(3);
      },
      clearStep(step: number) {
        if (step === 1) {
          if (this.role !== ROLES.CLIENT_ADMIN) {
            this.formData.clientId = null;
          }
          this.formData.sport = {
            name: '',
            clientSportId: 0,
            globalSportId: 0,
          };
          this.formData.event.scheduleId = 0;
        } else if (step === 2) {
          this.formData.event.home = {
            name: '',
            logoUrl: '',
            mascot: '',
            standing: '',
            wins: '',
            losses: '',
            ties: '',
            ranking: '',
          };

          this.formData.event.away = {
            name: '',
            logoUrl: '',
            mascot: '',
            standing: '',
            wins: '',
            losses: '',
            ties: '',
            ranking: '',
          };
          this.formData.event.location.description = '';
        } else {
          this.formData.title = '';
          this.formData.event.location.type = '';
          this.formData.event.location.name = '';
          this.formData.event.date = null;
          this.time = null;
          this.formData.event.broadcaster = '';
        }
      },
      stepStatus(step: number) {
        return this.curr > step ? 'green' : 'black';
      },
      validate(n: number) {
        this.steps[n].valid = false;
        let v = (this.$refs['stepForm'] as any)[n].validate();
        if (v) {
          if (n === 0 && !this.formIsPrefilled) {
            this.setPrefilledData();
          }
          this.steps[n].valid = true;
          // continue to next
          if (this.curr !== 3) {
            this.curr = n + 2;
          } else {
            this.submitForm();
          }
        }
      },
      async onClientChangeAsync() {
        this.formIsPrefilled = false;
        this.clearSelectedSport();

        const clientId = this.formData.clientId;

        if (this.role !== ROLES.CLIENT_ADMIN) {
          const clients = this.clients;
          this.selectedClient = clients.find(({ id }: { id: number }) => id === clientId);
        }

        await this.getClientSportsAsync(clientId);
      },
      async onSportChangeAsync() {
        this.formIsPrefilled = false;
        this.clearSelectedEvent();

        const clientId = this.formData.clientId;
        const sportId = this.formData.sport.clientSportId;

        // handles cleared selection action
        if (sportId === null) {
          this.clearSelectedSport();
          this.sportEvents = [];
          return;
        }

        await this.getSportEventsAsync(clientId, sportId);
      },
      onEventChange() {
        this.formIsPrefilled = false;
        // handles cleared selection action
        if (!this.formData.event.scheduleId) {
          this.clearSelectedEvent();
          return;
        }
        this.setEvent();
      },
      async getClientSportsAsync(clientId: number) {
        this.loadingForm = true;
        try {
          this.clientSports = await client.fetchSports({ clientId: clientId });
          this.loadingForm = false;
        } catch (error) {
          this.clientSports = [];
          this.showToast('Something went wrong');
          this.loadingForm = false;
        }
      },
      async getSportEventsAsync(clientId: number, sportId: number) {
        this.loadingForm = true;
        try {
          this.sportEvents = await client.fetchSportEvents({ clientId: clientId, sportId: sportId });
          this.loadingForm = false;
        } catch (error) {
          this.sportEvents = [];
          this.showToast('Something went wrong');
          this.loadingForm = false;
        }
      },
      setEvent() {
        const event = this.sportEvents.find(({ id }) => id === this.formData.event.scheduleId);
        this.selectedEvent = event;
        this.eventSelected = true;
      },
      setPrefilledData() {
        const clientId = this.formData.clientId;
        const sportId = this.formData.sport.clientSportId;

        if (this.role !== ROLES.CLIENT_ADMIN) {
          const clients = this.clients;
          this.selectedClient = clients.find(({ id }: { id: number }) => id === clientId);
        }

        let homeLogoUrl = this.selectedClient?.logoUrl as string;
        const httpRegex = /(http|https)/;
        if (homeLogoUrl !== '' && !httpRegex.test(homeLogoUrl)) {
          homeLogoUrl = `https:${homeLogoUrl}`;
        }

        this.formData.event.home.logoUrl = homeLogoUrl;
        this.formData.event.home.name = this.selectedClient?.name;

        const sport = this.clientSports.find(({ clientSportId }) => clientSportId === sportId);
        if (sport) {
          this.formData.sport.name = sport.name;
          this.formData.sport.globalSportId = sport.globalSportId;
        }

        if (this.eventSelected) {
          const date = DateTime.fromISO(this.selectedEvent?.date);
          const formattedDate = date.toFormat('yyyy-MM-dd');
          const formattedTime = date.toFormat('HH:mm');
          this.formData.event.home.mascot = this.selectedEvent?.clientMascot;
          this.formData.event.location.name = this.selectedEvent?.location;
          this.formData.event.location.type = this.selectedEvent?.locationIndicator == 'H' ? 'Home' : 'Away';
          this.formData.event.location.description = this.selectedEvent?.locationIndicator == 'H' ? 'VS' : 'AT';
          this.formData.event.away.logoUrl = this.selectedClient?.siteUrl + this.selectedEvent?.opponentLogoUrl;
          this.formData.event.away.mascot = this.selectedEvent?.opponentMascot;
          this.formData.event.away.name = this.selectedEvent?.opponentName;
          this.formData.event.broadcaster = this.selectedEvent?.provider;
          this.formData.event.date = formattedDate;
          this.time = formattedTime;
        }

        this.formData.title = this.eventSelected
          ? `${this.formData.event.home.name} ${this.selectedEvent?.clientMascot} ${this.formData.event.location.description} ${this.selectedEvent?.opponentName} ${this.selectedEvent?.opponentMascot}`
          : `${this.formData.event.home.name}`;

        this.formIsPrefilled = true;
      },
      getEventTitle(item: any) {
        const date = DateTime.fromISO(item.date);
        const formattedDate = date.toFormat('MM/dd/yyyy');
        return (item.locationIndicator == 'H' ? 'VS' : 'AT') + ' — ' + item.opponentName + ' — ' + formattedDate;
      },
      saveTime() {
        (this.$refs['menuTime'] as any)[0].save(this.time);
        this.menuTime = false;
      },
      imageRule(value: string) {
        const rules = [];
        if (!value) {
          const rule = (value: string) => !!value || 'Required.';
          rules.push(rule);
        }
        return rules;
      },
    },
    watch: {
      value(n) {
        if (n === false) this.resetForm();
      },
    },
    async created() {
      if (this.edit) {
        this.curr = 3;
      }

      try {
        if (this.role !== ROLES.CLIENT_ADMIN) {
          this.loadingClients = true;
          await this.fetchClients({ notLocal: true, PageSize: 1000 });
        } else {
          this.selectedClient = this.user.client;
          this.formData.clientId = this.selectedClient.id;
          if (!this.edit) {
            await this.getClientSportsAsync(this.formData.clientId);
          }
        }
        if (this.edit) {
          this.loadingForm = true;

          this.formData = JSON.parse(JSON.stringify(this.getProgram));
          this.eventSelected = this.formData.event.scheduleId > 0;

          const clientId = this.formData.clientId;
          const sportId = this.formData.sport.clientSportId;

          await this.getClientSportsAsync(clientId);
          await this.getSportEventsAsync(clientId, sportId);

          if (this.eventSelected) {
            const date = DateTime.fromISO(this.formData.event.date);
            const formattedDate = date.toFormat('yyyy-MM-dd');
            const formattedTime = date.toFormat('HH:MM');
            this.formData.event.date = formattedDate;
            this.formData.event.home.standing = this.formData.event.home.standing
              ? this.formData.event.home.standing
              : '';
            this.formData.event.away.standing = this.formData.event.away.standing
              ? this.formData.event.away.standing
              : '';
            const isHomeRankingNA = this.formData.event.home.standing.split(' ').length === 1;
            const isAwayRankingNA = this.formData.event.away.standing.split(' ').length === 1;
            const homeStanding = isHomeRankingNA
              ? this.formData.event.home.standing.split('-')
              : this.formData.event.home.standing.split(' ')[1].split('-');
            const awayStanding = isAwayRankingNA
              ? this.formData.event.away.standing.split('-')
              : this.formData.event.away.standing.split(' ')[1].split('-');

            // HOME
            this.formData.event.home.wins = Number.isNaN(+homeStanding[0]) ? '' : homeStanding[0];

            if (isHomeRankingNA) this.formData.event.home.losses = homeStanding[1];
            else this.formData.event.home.losses = homeStanding[1] ? homeStanding[1].split(' ')[0] : '';
            if (homeStanding[2]) {
              if (homeStanding[2].split(' ')) this.formData.event.home.ties = homeStanding[2].split(' ')[0];
              else this.formData.event.home.ties = homeStanding[2];
            } else this.formData.event.home.ties = '';
            const homeRanking = this.formData.event.home.standing.replace(/\(|\)/g, '').split(' ')[0];
            if (isHomeRankingNA) this.formData.event.home.ranking = 'NR';
            else this.formData.event.home.ranking = this.ranking.includes(homeRanking) ? homeRanking : 'NR';

            // AWAY
            this.formData.event.away.wins = Number.isNaN(+awayStanding[0]) ? '' : awayStanding[0];

            if (isHomeRankingNA) this.formData.event.away.losses = awayStanding[1];
            else this.formData.event.away.losses = awayStanding[1] ? awayStanding[1].split(' ')[0] : '';

            if (awayStanding[2]) {
              if (awayStanding[2].split(' ')) this.formData.event.away.ties = awayStanding[2].split(' ')[0];
              else this.formData.event.away.ties = awayStanding[2];
            } else this.formData.event.away.ties = '';
            const awayRanking = this.formData.event.away.standing.replace(/\(|\)/g, '').split(' ')[0];

            if (isAwayRankingNA) this.formData.event.away.ranking = 'NR';
            else this.formData.event.away.ranking = this.ranking.includes(awayRanking) ? awayRanking : 'NR';

            this.time = formattedTime;
          }

          this.formIsPrefilled = true;
          this.loadingForm = false;
        }
      } catch (error) {
        // console.warn('Could not fetch data', error);
      }
      this.loadingClients = false;
    },
  });
</script>
<style lang="scss" scoped>
  .date-input {
    position: relative;
  }
  .scrollable {
    max-height: calc(100vh - 400px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .v-card__actions > .v-btn.v-btn {
    padding: 0 16px;
  }
</style>
