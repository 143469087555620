<script lang="ts">
  import { Btn } from '.';
  export default Btn.extend({
    props: {
      rounded: { default: true },
      color: { default: 'primary' },
      large: { default: true },
    },
  });
</script>
