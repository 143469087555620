<template>
  <div>
    <v-card-text class="scrollable">
      <label class="text--secondary">Select schedule to import</label>
      <v-tabs v-model="tab" grow class="px-4">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="px-4">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <div v-if="item === 'Results'" class="pt-0 my-4 d-flex flex-column gap-4">
              <v-data-table
                class="elevation-2 mt-2 custom-table"
                :class="{ invalid: !isFormValid }"
                checkbox-color="primary"
                :headers="headersResult"
                disable-pagination
                hide-default-footer
                show-select
                fixed-header
                sort-by="firstName"
                item-key="date"
                :items="scheduleResults"
                v-model="selectedResults"
                height="360"
                :loading="isLoading"
                disable-sort
                disable-filtering
              >
                <template #[`item.date`]="props">
                  <span v-format-date:[dateFormat]="props.item.date">{{ props.item.date }}</span>
                </template>
                <template #[`item.locationIndicator`]="props">
                  <span v-if="props.item.locationIndicator">{{
                    props.item.locationIndicator === 'H' ? 'Vs' : 'At'
                  }}</span>
                </template>
              </v-data-table>
            </div>

            <div v-if="item === 'Upcoming Events'" class="pt-0 my-4 d-flex flex-column gap-4">
              <v-data-table
                class="elevation-2 mt-2 custom-table"
                :class="{ invalid: !isFormValid }"
                checkbox-color="primary"
                :headers="headersUpcoming"
                disable-pagination
                hide-default-footer
                show-select
                fixed-header
                sort-by="firstName"
                item-key="date"
                :items="scheduleUpcoming"
                v-model="selectedUpcoming"
                height="360"
                :loading="isLoading"
                disable-sort
                disable-filtering
              >
                <template #[`item.date`]="props">
                  <span v-format-date:[dateFormat]="props.item.date">{{ props.item.date }}</span>
                </template>
                <template #[`item.locationIndicator`]="props">
                  <span v-if="props.item.locationIndicator">{{
                    props.item.locationIndicator === 'H' ? 'Vs' : 'At'
                  }}</span>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack" :disabled="isFormDisabled">
        Back
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        depressed
        class="text-capitalize"
        :disabled="!isFormValid || isFormDisabled"
        @click="onSubmit"
      >
        Continue
      </v-btn>
      <span v-if="!isFormValid" class="error--text">Select a least one event</span>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset" :disabled="isFormDisabled">
        Clear
      </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import ClientService from '@/services/client/client';
  import { mapActions, mapGetters } from 'vuex';
  import { ScheduleEventsGetters } from '@/store/client/types';
  interface Injections {
    setPageTitle: (title: string) => void;
    setPageContent: (content: any) => void;
    toggleLoading: () => void;
    getPageSport: () => any;
    goNext: () => void;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'ScheduleImporter',
    inject: ['setPageTitle', 'setPageContent', 'toggleLoading', 'getPageSport', 'goNext', 'goBack', 'close'],
    data() {
      return {
        tab: 0 as number,
        items: ['Results', 'Upcoming Events'] as string[],
        dateFormat: 'M/d/yy',
        scheduleResults: [] as ScheduleEventsGetters[],
        selectedResults: [] as ScheduleEventsGetters[],
        scheduleUpcoming: [] as ScheduleEventsGetters[],
        selectedUpcoming: [] as ScheduleEventsGetters[],
        headersResult: [
          {
            text: 'Opponent Name',
            align: 'start',
            value: 'opponent.name',
            width: 200,
          },
          {
            text: 'Location',
            align: 'start',
            value: 'location',
            width: 150,
          },
          {
            text: 'Stadium Name',
            align: 'start',
            value: 'stadiumName',
            width: 150,
          },
          {
            text: 'Indicator',
            align: 'start',
            value: 'locationIndicator',
            width: 80,
          },
          {
            text: 'Date',
            align: 'start',
            value: 'date',
            width: 80,
          },
          {
            text: 'Time',
            align: 'start',
            value: 'time',
            width: 80,
          },
          {
            text: 'Result',
            align: 'start',
            value: 'result',
            width: 100,
          },
        ],
        headersUpcoming: [
          {
            text: 'Opponent Name',
            align: 'start',
            value: 'opponent.name',
            width: 200,
          },
          {
            text: 'Location',
            align: 'start',
            value: 'location',
            width: 150,
          },
          {
            text: 'Stadium Name',
            align: 'start',
            value: 'stadiumName',
            width: 150,
          },
          {
            text: 'Indicator',
            align: 'start',
            value: 'locationIndicator',
            width: 80,
          },
          {
            text: 'Date',
            align: 'start',
            value: 'date',
            width: 80,
          },
          {
            text: 'Time',
            align: 'start',
            value: 'time',
            width: 80,
          },
        ],
        isLoading: false,
        isFormValid: true,
        isFormDisabled: false,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchSport: ClientService.fetchSport,
      fetchScheduleResultsEvents: ClientService.fetchScheduleResultsEvents,
      fetchScheduleUpcomingEvents: ClientService.fetchScheduleUpcomingEvents,
      fetchPlayers: ClientService.fetchPlayers,
      fetchClient: ClientService.fetchClient,
      async importSchedule() {
        const httpRegex = /(http|https)/;
        const { clientId } = this.program;
        const client = await this.fetchClient(clientId);

        const selectedResultsMap = this.selectedResults.map((item) => {
          if (item.opponent.logo !== '' && !httpRegex.test(item.opponent.logo)) {
            item.opponent.logo = `${client.siteUrl}${item.opponent.logo}`;
          }
          return {
            ...item,
            opponentLogo: item.opponent.logo,
            opponent: item.opponent.name,
          };
        });

        const selectedUpcomingMap = this.selectedUpcoming.map((item) => {
          if (item.opponent.logo !== '' && !httpRegex.test(item.opponent.logo)) {
            item.opponent.logo = `${client.siteUrl}${item.opponent.logo}`;
          }
          return {
            ...item,
            opponentLogo: item.opponent.logo,
            opponent: item.opponent.name,
          };
        });

        const headerText = 'Schedule';
        this.setPageTitle(`${headerText} Import`);

        return {
          blocks: [
            {
              id: 1,
              data: {
                text: headerText,
              },
              type: 'header',
              config: {
                label: 'Describe what this page is about',
                title: 'Page Heading',
                placeholder: 'Page Heading Text Goes Here...',
              },
            },
            {
              id: 2,
              data: {
                alt: '',
                image: '',
                firstText: '',
                secondText: '',
                thirdText: '',
                fourthText: '',
                locationType: 'home',
              },
              type: 'textimagerecord',
              config: {
                label: 'Choose which team this page is associated with',
                title: 'Team Schedule Heading',
                imageLabel: 'School Logo',
                displayConfig: true,
                firstTextDefault: 'mascot',
                secondTextDefault: 'name',
                thirdTextDefault: '',
                fourthTextDefault: 'standing',
                placeholderFirstText: 'School Nickname/Mascot',
                placeholderSecondText: 'School Name',
                placeholderThirdText: 'Conference Record',
                placeholderFourthText: 'Overall Record',
              },
            },
            {
              id: 3,
              data: {
                results: selectedResultsMap,
                upcoming: selectedUpcomingMap,
              },
              type: 'schedule',
              config: {
                title: 'Schedule Panel',
                subtitle: 'Components that build your schedule & results page.',
              },
            },
          ],
        };
      },
      async onSubmit() {
        this.isFormValid = !!this.selectedResults.length || !!this.selectedUpcoming.length;
        if (!this.isFormValid) return;
        try {
          this.toggleLoading();
          this.isFormDisabled = true;
          const content = await this.importSchedule();
          this.setPageContent(content);
          this.goNext();
        } catch (error) {
          const msg = error?.response?.data || 'Could not import roster data';
          this.showToast(msg);
          // // console.warn(error);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
      reset() {
        this.selectedResults = [];
        this.selectedUpcoming = [];
        this.isFormValid = true;
      },
    },
    async created() {
      try {
        this.isFormDisabled = true;
        this.isLoading = true;
        const { clientId } = this.program;
        // const { clientSportId } = this.program.sport;
        let clientSportId = this.program.sport.clientSportId;
        if (!clientSportId) {
          let pageSport = this.getPageSport();
          clientSportId = pageSport.clientSportId;
        }
        this.scheduleResults = await this.fetchScheduleResultsEvents({ clientId, clientSportId });
        this.scheduleUpcoming = await this.fetchScheduleUpcomingEvents({ clientId, clientSportId });
      } catch (error) {
        this.showToast('Could not fetch roster data');
        // console.warn(error);
      } finally {
        this.isFormDisabled = false;
        this.isLoading = false;
      }
    },
    watch: {
      selectedResults(selectedResults: ScheduleEventsGetters[]) {
        if (selectedResults && selectedResults.length) this.isFormValid = true;
      },
      selectedUpcoming(selectedResults: ScheduleEventsGetters[]) {
        if (selectedResults && selectedResults.length) this.isFormValid = true;
      },
    },
  });
</script>
