import API from '@/services/api/api';

const getAuthenticatedUser = () => {
  return API.get('users/me')
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return e.response ? Promise.reject(e.response) : e;
    });
};

export default {
  getAuthenticatedUser,
};
