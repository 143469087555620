<template>
  <v-app-bar app flat color="white" height="80">
    <v-row style="width: 100%" class="flex-shrink-1 align-center flex-nowrap" dense>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 10 : 'auto'">
        <div v-if="loading" class="d-flex align-center gap-6">
          <v-skeleton-loader class="flex-grow-1" type="list-item-avatar-two-line"></v-skeleton-loader>
          <v-skeleton-loader width="88" type="sentences"></v-skeleton-loader>
          <div class="d-flex align-center">
            <v-skeleton-loader width="132" type="chip"></v-skeleton-loader>
            <v-btn icon small disabled>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-else class="d-flex align-center gap-6">
          <v-avatar size="44">
            <v-img :alt="program?.event.home.name" :src="program?.event.home.logoUrl"></v-img>
          </v-avatar>
          <div class="d-flex flex-shrink-1 flex-grow-0 gap-2 text-truncate">
            <div class="d-flex flex-column flex-shrink-1 flex-grow-0 gap-1 text-truncate">
              <p class="mb-0 grey--text text--darken-3 font-weight-bold text-body-2 text-truncate">
                {{ program?.title }}
              </p>
              <p
                class="mb-0 grey--text text--darken-1 font-weight-bold text-caption text-truncate"
                v-if="program?.sport.name"
              >
                {{ program?.sport.name }}
              </p>
            </div>
            <v-sheet v-if="program?.event.date" width="88" class="d-flex flex-column align-center justify-center">
              <p
                class="mb-0 grey--text text--darken-3 text-body-2"
                v-format-date:[dateFormat]="program?.event.date"
              ></p>
              <p class="mb-0 grey--text text--darken-1 text-caption">
                <v-icon color="grey darken-1" size="14">mdi-clock-outline</v-icon>
                <span v-format-date:[timeFormat]="program?.event.date"></span>
              </p>
            </v-sheet>
          </div>
          <v-sheet width="132">
            <status-select v-if="program?.status" v-model="program.status" :program="program"></status-select>
          </v-sheet>
        </div>
      </v-col>
      <v-col class="text-right d-xl-none">
        <v-btn depressed color="grey lighten-3" class="text-capitalize" @click="setPagePreviewVisibility(true)">
          Preview
          <v-icon right>mdi-eye</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { StatusSelect } from '@/components/Programs';
  import { mapActions, mapGetters } from 'vuex';
  export default Vue.extend({
    name: 'TopBar',
    components: {
      StatusSelect,
    },
    props: {
      programId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        loading: false,
        dateFormat: 'EEE, LLL d',
        timeFormat: 'h:mma',
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
    },
    methods: {
      ...mapActions('builder', ['setPagePreviewVisibility']),
    },
  });
</script>
