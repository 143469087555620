import { MutationTree } from 'vuex';
import { ChangeStatusPayload, Program, ProgramsState } from './types';

export enum ProgramsMutations {
  SET_PROGRAMS = 'SET_PROGRAMS',
  SET_PAGES = 'SET_PAGES',
  SET_PROGRAM_STATUS = 'SET_PROGRAM_STATUS',
  SET_PROGRAM = 'SET_PROGRAM',
}

export const mutations: MutationTree<ProgramsState> = {
  [ProgramsMutations.SET_PROGRAMS](state, programs: Program[]) {
    state.programs = programs;
  },
  [ProgramsMutations.SET_PAGES](state, pages: number) {
    state.pages = pages;
  },

  [ProgramsMutations.SET_PROGRAM_STATUS](state, { id, status }: ChangeStatusPayload) {
    if (state.program?.id == id) {
      state.program.status = status;
    }
    const program = state.programs.find((program) => program.id == id);
    if (program) {
      program.status = status;
    }
  },
  [ProgramsMutations.SET_PROGRAM](state, program: Program) {
    state.program = program;
  },
};
