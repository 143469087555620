<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title class="text-h6">{{ config.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Edit</v-btn>
    </v-toolbar>
    <v-card-text class="pt-0">
      <label for="text-area">{{ config.label }}</label>
      <v-sheet
        outlined
        rounded="lg"
        class="overflow-auto pa-2 border-grey"
        min-height="32"
        max-height="200"
        v-html="value.html"
      ></v-sheet>
    </v-card-text>
    <html-modal
      :title="config.title"
      :label="config.label"
      :placeholder="config.placeholder"
      v-model="modal"
      :data="value"
      @submit="$emit('input', $event)"
    />
  </v-card>
</template>
<script lang="ts">
  import Vue from 'vue';
  import HtmlModal from './HtmlModal.vue';
  export default Vue.extend({
    name: 'HtmlBlock',
    components: {
      HtmlModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
      };
    },
    computed: {
      preview(): string {
        return this.value.html;
      },
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
    },
  });
</script>
<style scoped lang="scss">
  .border-grey {
    border-color: rgba(0, 0, 0, 0.38) !important;

    &:hover {
      border-color: rgba(0, 0, 0, 0.86) !important;
    }
  }
</style>
