import { MutationTree } from 'vuex';
import { UiState } from './types';

export enum UiMutations {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  SHOW_TOAST = 'SHOW_TOAST',
  HIDE_TOAST = 'HIDE_TOAST',
}

export const mutations: MutationTree<UiState> = {
  [UiMutations.TOGGLE_SIDEBAR](state, value: boolean) {
    state.sidebarCollapsed = value;
  },
  [UiMutations.SHOW_TOAST](state, content: string) {
    state.toast.visible = true;
    state.toast.content = content;
  },
  [UiMutations.HIDE_TOAST](state) {
    state.toast.visible = false;
  },
};
