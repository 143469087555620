<template>
  <div>
    <div class="main-container">
      <div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
        <div class="editor-container__editor">
          <div ref="editorElement">
            <ckeditor v-model="editorData" :config="{ versionCheck: false }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import CKEditor from 'ckeditor4-vue';

  interface Injections {
    setHtml: (html: string, contentId: string) => void;
  }

  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'HtmlTextEditor',
    inject: ['setHtml'],
    components: {
      ckeditor: CKEditor.component,
    },
    props: {
      contentId: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      htmlContent: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        editorData: this.htmlContent ?? this.placeholder,
      };
    },
    watch: {
      editorData(html: string) {
        this.setHtml(html, this.contentId);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .custom-editor {
    overflow-y: auto;
    cursor: pointer;

    :deep(.ce-settings) {
      left: -66px;
    }
  }
</style>
