import { MutationTree } from 'vuex';
import { Users, UsersState } from './types';

export enum UsersMutations {
  SET_USERS = 'SET_USERS',
  SET_PAGES = 'SET_PAGES',
}

export const mutations: MutationTree<UsersState> = {
  [UsersMutations.SET_USERS](state, users: Users[]) {
    state.users = users;
  },
  [UsersMutations.SET_PAGES](state, pages: number) {
    state.pages = pages;
  },
};
