<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <v-card-text class="scrollable">
      <label class="text--secondary">Select one player to import</label>
      <v-data-table
        class="elevation-2 mt-2 custom-table"
        :class="{ invalid: !isFormValid }"
        checkbox-color="primary"
        :headers="headers"
        disable-pagination
        hide-default-footer
        show-select
        fixed-header
        sort-by="firstName"
        item-key="clientRosterPlayerId"
        :items="players"
        v-model="selected"
        height="360"
        :loading="isLoading"
        single-select
      >
        <template #item.data-table-select="{ item, select }">
          <v-checkbox
            class="mt-0"
            hide-details
            :value="item.clientRosterPlayerId === selectedPlayerId"
            on-icon="$radioOn"
            off-icon="$radioOff"
            @change="select"
            :disabled="isFormDisabled"
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack" :disabled="isFormDisabled">
        Back
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        depressed
        class="text-capitalize"
        :disabled="!isFormValid || isFormDisabled"
        @click="onSubmit"
      >
        Continue
      </v-btn>
      <span v-if="!isFormValid" class="error--text">Select one player</span>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset" :disabled="isFormDisabled">
        Clear
      </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import ClientService from '@/services/client/client';
  import { mapActions, mapGetters } from 'vuex';
  import { RosterPlayer } from '@/store/client/types';
  import { sortBy } from 'lodash-es';
  interface Injections {
    setPageTitle: (title: string) => void;
    setPageContent: (content: any) => void;
    toggleLoading: () => void;
    getPageSport: () => any;
    goNext: () => void;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'RosterImporter',
    inject: ['setPageTitle', 'setPageContent', 'toggleLoading', 'getPageSport', 'goNext', 'goBack', 'close'],
    data() {
      return {
        players: [] as RosterPlayer[],
        selected: [] as RosterPlayer[],
        headers: [
          {
            text: 'First Name',
            align: 'start',
            value: 'firstName',
          },
          {
            text: 'Last Name',
            align: 'start',
            value: 'lastName',
          },
          {
            text: 'Position',
            align: 'start',
            value: 'position',
            width: 100,
          },
          {
            text: 'Class',
            align: 'start',
            value: 'class',
            width: 180,
          },
        ],
        isLoading: false,
        isFormValid: true,
        isFormDisabled: false,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
      selectedPlayerId(): number | null {
        return this.selected.length ? this.selected[0].clientRosterPlayerId : null;
      },
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchSport: ClientService.fetchSport,
      fetchRosterPlayers: ClientService.fetchRosterPlayers,
      fetchPlayers: ClientService.fetchPlayers,
      fetchClient: ClientService.fetchClient,

      async importRoster() {
        const { clientId } = this.program;
        const client = await this.fetchClient(clientId);
        const teamInfo = this.program.event.home;
        const playersIds = this.selected.map(({ clientRosterPlayerId }) => clientRosterPlayerId);
        const playersMap = await this.fetchPlayers({ clientId, playersIds });
        const player = Object.values(playersMap)[0];
        const playerBio = player.bio;
        const httpRegex = /(http|https)/;
        if (player.rosterHeadshot !== '' && !httpRegex.test(player.rosterHeadshot)) {
          player.rosterHeadshot = `${client.siteUrl}${player.rosterHeadshot}`;
        }
        const headerText = 'Featured Player';
        this.setPageTitle(`${headerText} Import`);
        return {
          blocks: [
            {
              id: 1,
              data: {
                text: headerText,
              },
              type: 'header',
              config: {
                label: 'Describe what this page is about',
                title: 'Page Heading',
                placeholder: 'Page Heading Text Goes Here...',
              },
            },
            {
              id: 2,
              data: {
                alt: `${teamInfo.mascot} team logo`,
                image: teamInfo.logoUrl,
                firstText: 'Player to Watch',
                secondText: teamInfo.name,
                locationType: 'home',
              },
              type: 'textimage',
              config: {
                label: 'Choose which team this page is associated with',
                title: 'Section Heading',
                imageLabel: 'School Logo',
                displayConfig: false,
                firstTextDefault: 'mascot',
                secondTextDefault: 'name',
                placeholderFirstText: 'Section Title',
                placeholderSecondText: 'School Name',
              },
            },
            {
              id: 3,
              data: {
                player,
              },
              type: 'player',
              config: {
                title: 'Player',
                subtitle: 'Player bio & stats',
              },
            },
            {
              id: 4,
              data: {
                html: playerBio,
              },
              type: 'html',
              config: {
                label: 'Body',
                title: 'Featured Player Body Text',
              },
            },
          ],
        };
      },
      async onSubmit() {
        this.isFormValid = !!this.selected.length;
        if (!this.isFormValid) return;
        try {
          this.toggleLoading();
          this.isFormDisabled = true;
          const content = await this.importRoster();
          this.setPageContent(content);
          this.goNext();
        } catch (error) {
          const msg = error?.response?.data || 'Could not import roster data';
          this.showToast(msg);
          // // console.warn(error);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
      reset() {
        this.selected = [];
        this.isFormValid = true;
      },
    },
    async created() {
      try {
        this.isFormDisabled = true;
        this.isLoading = true;
        const { clientId } = this.program;
        // const { clientSportId } = this.program.sport;
        let clientSportId = this.program.sport.clientSportId;
        if (!clientSportId) {
          let pageSport = this.getPageSport();
          clientSportId = pageSport.clientSportId;
        }
        const sport = await this.fetchSport({ clientId, clientSportId });
        const { rosterId } = sport;
        const players = await this.fetchRosterPlayers({ clientId, rosterId });
        this.players = sortBy(Object.values(players), ['lastName', 'firstName']);
      } catch (error) {
        this.showToast('Could not fetch roster data');
        // // console.warn(error);
      } finally {
        this.isFormDisabled = false;
        this.isLoading = false;
      }
    },
    watch: {
      selected(selected: RosterPlayer[]) {
        if (selected && selected.length) this.isFormValid = true;
      },
    },
  });
</script>
