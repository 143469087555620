export const PROGRAMS_STATUS = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  PUBLISHED: 3,
  NEEDS_REVIEW: 4,
  LEARFIELD_ONLY: 5,
  ARCHIVED: 6,
};

export const PROGRAMS_STATUS_NAMES = {
  [PROGRAMS_STATUS.NOT_STARTED]: 'Not Started',
  [PROGRAMS_STATUS.IN_PROGRESS]: 'In Progress',
  [PROGRAMS_STATUS.PUBLISHED]: 'Published',
  [PROGRAMS_STATUS.NEEDS_REVIEW]: 'Needs Review',
  [PROGRAMS_STATUS.LEARFIELD_ONLY]: 'Learfield Only',
  [PROGRAMS_STATUS.ARCHIVED]: 'Archived',
};

export const PROGRAMS_STATUS_COLORS = {
  [PROGRAMS_STATUS.NOT_STARTED]: 'error',
  [PROGRAMS_STATUS.IN_PROGRESS]: 'warning',
  [PROGRAMS_STATUS.PUBLISHED]: 'success',
  [PROGRAMS_STATUS.NEEDS_REVIEW]: 'info',
  [PROGRAMS_STATUS.LEARFIELD_ONLY]: 'primary',
  [PROGRAMS_STATUS.ARCHIVED]: 'grey',
};

export const PROGRAMS_STATUS_OPTIONS = [
  {
    value: PROGRAMS_STATUS.NOT_STARTED,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.NOT_STARTED],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.NOT_STARTED],
  },
  {
    value: PROGRAMS_STATUS.IN_PROGRESS,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.IN_PROGRESS],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.IN_PROGRESS],
  },
  {
    value: PROGRAMS_STATUS.PUBLISHED,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.PUBLISHED],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.PUBLISHED],
  },
  {
    value: PROGRAMS_STATUS.NEEDS_REVIEW,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.NEEDS_REVIEW],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.NEEDS_REVIEW],
  },
  {
    value: PROGRAMS_STATUS.LEARFIELD_ONLY,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.LEARFIELD_ONLY],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.LEARFIELD_ONLY],
  },
  {
    value: PROGRAMS_STATUS.ARCHIVED,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.ARCHIVED],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.ARCHIVED],
  },
];

const PUBLICATIONS_PAGE_FORMAT = '<strong>Publications page</strong>';
const SCHEDULE_GAME_INFO_FORMAT =
  '<span style="text-wrap-mode: nowrap; font-weight:bold;">Schedule ' +
  '<span class="mdi mdi-chevron-right" style="margin: 0 -3px;"></span> Game Info</span>';

const PUBLISH_STATUS_CONFIRMATION = {
  title: 'Confirm Publishing Digital Program',
  message:
    `The link to this program will auto-publish to the ${PUBLICATIONS_PAGE_FORMAT} ` +
    `and, if applicable, the ${SCHEDULE_GAME_INFO_FORMAT} section of your athletics site. ` +
    'Would you like to continue with publishing?',
};

const UNPUBLISH_STATUS_CONFIRMATION = {
  title: 'Confirm Unpublishing Digital Program',
  message:
    `The link to this program will need to be manually removed from the ${PUBLICATIONS_PAGE_FORMAT} ` +
    `and, if applicable, the ${SCHEDULE_GAME_INFO_FORMAT} section of your athletics site.`,
};

const PUBLISH_TO_PUBLICATIONS_STATUS_MESSAGE = {
  success: `added to the ${PUBLICATIONS_PAGE_FORMAT}`,
  failure: `${PUBLICATIONS_PAGE_FORMAT}`,
};

const PUBLISH_TO_PROGRAM_LINK_STATUS_MESSAGE = {
  success: `added to the ${SCHEDULE_GAME_INFO_FORMAT} section`,
  failure: `${SCHEDULE_GAME_INFO_FORMAT} section`,
};

const PUBLISH_CONTACT_SUPPORT_MESSAGE =
  'Please add the link manually and/or reach out to ' +
  '<a class="blue--text" href="mailto:support@sidearmsports.com">support@sidearmsports.com</a>.';

export const AUTO_PUBLISH_LITERALS = {
  dialog: {
    publish: PUBLISH_STATUS_CONFIRMATION,
    unpublish: UNPUBLISH_STATUS_CONFIRMATION,
  },
  toast: {
    publicationsPage: PUBLISH_TO_PUBLICATIONS_STATUS_MESSAGE,
    gameProgramLink: PUBLISH_TO_PROGRAM_LINK_STATUS_MESSAGE,
    contactSupport: PUBLISH_CONTACT_SUPPORT_MESSAGE,
  },
};
