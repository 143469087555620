import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { UserState } from './types';
import { RootState } from '../types';

export const state: UserState = {
  user: {
    loggedIn: false,
    data: null,
    authToken: '',
  },
};

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
