<template>
  <div>
    <v-card outlined>
      <v-card-title class="px-4">
        {{ config.title }}
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Bulk Edit</v-btn>
      </v-card-title>
      <v-card-subtitle class="px-4 text--secondary">{{ config.subtitle }}</v-card-subtitle>
      <div class="pt-0 pb-4 px-4 d-flex flex-column gap-4">
        <v-data-table
          class="elevation-1 mt-2 standing-table-block"
          checkbox-color="primary"
          :headers="headers"
          disable-pagination
          hide-default-footer
          fixed-header
          disable-sort
          :items="value"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              height="48"
              width="48"
              :alt="item?.imageAltText || 'Opponent Logo'"
              :src="item?.image || '/default-img.png'"
              class="rounded-sm"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
    <ranking-edit-modal v-model="modal" :data="value" @submit="$emit('input', $event)" />
  </div>
</template>
<script lang="ts">
  import { TeamPerDivision } from '@/store/client/types';
  import Vue, { PropType } from 'vue';
  import RankingEditModal from './RankingEditModal.vue';
  export default Vue.extend({
    name: 'RankingBlock',
    components: {
      RankingEditModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Array as PropType<TeamPerDivision[]>,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
        headers: [
          {
            text: 'Rank',
            align: 'start',
            value: 'rank',
          },
          {
            text: 'Image',
            align: 'start',
            value: 'image',
          },
          {
            text: 'Team',
            align: 'start',
            value: 'team',
          },
          {
            text: 'Record',
            align: 'start',
            value: 'record',
          },
          {
            text: 'Prev',
            align: 'start',
            value: 'prev',
          },
        ],
      };
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
    },
  });
</script>
<style lang="scss">
  .standing-table-block {
    th {
      background: #f5f5f5 !important;
    }
    td:nth-child(1),
    th:nth-child(1) {
      width: 100px;
    }
    td:nth-child(3),
    th:nth-child(3) {
      width: 100px;
    }
    td:nth-child(4),
    th:nth-child(4) {
      width: 100px;
    }
  }
</style>
