import API from '@/services/api/api';

const fetchProgram = async (programId: string | number): Promise<any> => {
  try {
    const response = await API.get(`/programs/${programId}`);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const createProgram = async (data: any) => {
  try {
    const response = await API.post('/programs', data);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const updateProgram = async (data: any) => {
  try {
    const response = await API.put(`/programs/${data.id}`, data);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const updateProgramLandingPage = async (data: any) => {
  try {
    const response = await API.put(`/programs/${data.id}/landing-page`, data.landingPage);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const updateProgramPagesOrder = async (data: any) => {
  try {
    const response = await API.post(`/programs/${data.program.id}/pages/order`, data.pagesIds);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

export default {
  fetchProgram,
  createProgram,
  updateProgram,
  updateProgramLandingPage,
  updateProgramPagesOrder,
};
