export interface Program {
  id: number;
  title: string;
  createdDate: string;
  publishDate: string;
  clientId: number;
  scheduleId: number;
  eventDate: string;
  globalSportId: number;
  sportName: string;
  homeSchoolLogoUrl: string;
  awaySchoolLogoUrl: string;
  homeSchoolName: string;
  awaySchoolName: string;
  status: number;
  isDeleted: boolean;
}

export interface ProgramsState {
  programs: Program[];
  pages: number;
  program: Nullable<Program>;
}

export interface ChangeStatusPayload {
  id: Program['id'];
  status: Program['status'];
  oldStatus: Program['status'];
}

export interface DuplicatePayload {
  id: Program['id'];
}

export class DigitalProgram {
  id: number;
  clientId: number;
  status: number;
  sport: {
    name: string;
    clientSportId: number;
    globalSportId: number;
  };
  event: {
    // todo: rename to event.ID
    scheduleId: number;
    location: {
      name: string;
      type: string;
      description: string;
    };
    broadcaster: string;
    date: string;
    home: {
      name: string;
      logoUrl: string;
      mascot: string;
      standing: string;
      wins: string;
      losses: string;
      ties: string;
      ranking: string;
    };
    away: {
      name: string;
      logoUrl: string;
      mascot: string;
      standing: string;
      wins: string;
      losses: string;
      ties: string;
      ranking: string;
    };
  };
}
