<template>
  <div class="page-info">
    <v-card-text class="scrollable">
      <v-radio-group v-model="type" row @change="onTypeChange" :disabled="isFormDisabled">
        <v-radio label="Import" value="import"></v-radio>
        <v-radio label="Template Select" value="template"></v-radio>
      </v-radio-group>
      <v-form ref="form" v-model="isFormValid" @submit.prevent="onSubmit" lazy-validation>
        <template v-if="type === 'import'">
          <template v-if="!programHasEvent">
            <template v-if="!programHasSport">
              <label class="grey--text text--darken-1">
                Select a Sport
                <span v-if="!pageSportSelected">or Choose an available import type</span>
              </label>
              <v-select
                hide-details
                class="mb-5 mt-2"
                clearable
                v-model="selectedSportId"
                outlined
                dense
                :items="clientSports"
                @change="getSportEvents()"
                item-text="name"
                item-value="clientSportId"
                placeholder="Select a Sport (optional)"
                :disabled="clientSports?.length === 0"
              ></v-select>
            </template>
            <template v-if="programHasSport || pageSportSelected">
              <label class="grey--text text--darken-1">
                Select an Event
                <span v-if="!pageEventSelected">or Choose an available import type</span>
              </label>
              <v-select
                hide-details
                class="mb-5 mt-2"
                clearable
                v-model="selectedEventId"
                outlined
                dense
                :items="sportEvents"
                :item-text="getEventTitle"
                item-value="id"
                placeholder="Select an Event (optional)"
                @change="setEventAsync()"
                :disabled="sportEvents?.length === 0"
              ></v-select>
            </template>
          </template>
          <label class="grey--text text--darken-1">Select an Import Type</label>
          <v-select
            name="template-select"
            class="mt-2"
            clearable
            dense
            outlined
            item-disabled="disabled"
            :items="importTypes"
            placeholder="Select an Import Type"
            :rules="[isRequired]"
            v-model="importType"
            @change="onImportTypeChange"
          >
            <template v-slot:selection="{ item }">
              <img class="list-item-image" :src="item.isOpponent ? opponentLogo : program.event.home.logoUrl" alt="" />
              {{ item.isOpponent ? opponentName : program.event.home.name }} {{ item.text }}
            </template>
            <template v-slot:item="{ item }">
              <img class="list-item-image" :src="item.isOpponent ? opponentLogo : program.event.home.logoUrl" alt="" />
              {{ item.isOpponent ? opponentName : program.event.home.name }} {{ item.text }}
            </template>
          </v-select>
        </template>
        <template v-if="type === 'template'">
          <label class="grey--text text--darken-1">Select Page Template</label>
          <v-select
            name="template-select"
            class="mt-2"
            dense
            outlined
            :items="templates"
            item-text="title"
            item-value="id"
            :rules="[isRequired]"
            v-model="templateId"
            ><template v-slot:selection="{ item }">
              {{ item.title }}
            </template></v-select
          >
        </template>
      </v-form>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn
        color="primary"
        depressed
        class="text-capitalize"
        @click="onSubmit"
        :disabled="isFormDisabled || !isFormValid"
      >
        Continue
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset"> Clear </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import ClientService from '@/services/client/client';

  import { DateTime } from 'luxon';
  import { VForm } from '@/types';
  import Vue, { VueConstructor } from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  interface Injections {
    toggleLoading: () => void;
    setPageContent: (content: any) => void;
    setTemplateId: (id: number) => void;
    setType: (type: string) => void;
    setImportType: (importType: string) => void;
    getPageSport: () => any;
    getPageEvent: () => any;
    setPageSport: (sport: any) => void;
    setPageEvent: (event: any) => void;
    goNext: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'PageInfo',
    inject: [
      'toggleLoading',
      'setPageContent',
      'setTemplateId',
      'setType',
      'setImportType',
      'getPageSport',
      'getPageEvent',
      'setPageSport',
      'setPageEvent',
      'goNext',
      'close',
    ],
    data() {
      return {
        isFormValid: true,
        isFormDisabled: false,
        clientSports: [],
        sportEvents: [],
        selectedSportId: 0,
        selectedEventId: 0,
        selectedSport: {
          clientSportId: 0,
          globalSportId: 0,
        },
        selectedEvent: {
          id: 0,
          opponent: {
            name: null,
            logoUrl: null,
            mascot: null,
            standing: null,
          },
        },
        type: 'import',
        importTypes: [
          {
            isOpponent: false,
            value: 'story',
            text: 'Stories',
            disabled: false,
          },
          {
            isOpponent: false,
            value: 'roster',
            text: 'Roster',
            disabled: false,
          },
          {
            isOpponent: false,
            value: 'schedule',
            text: 'Schedule',
            disabled: false,
          },
          {
            isOpponent: false,
            value: 'featuredPlayer',
            text: 'Featured Player',
            disabled: false,
          },
          {
            isOpponent: false,
            value: 'featuredCoach',
            text: 'Featured Coach',
            disabled: false,
          },
          {
            isOpponent: false,
            value: 'coach',
            text: 'Coaches',
            disabled: false,
          },
          // {
          //   isOpponent: true,
          //   value: 'opponentStory',
          //   text: 'Opponent Stories',
          // },
          {
            isOpponent: true,
            value: 'opponentSchedule',
            text: 'Opponent Schedule',
            disabled: false,
          },
          {
            isOpponent: true,
            value: 'opponentRoster',
            text: 'Opponent Roster',
            disabled: false,
          },
          // {
          //   isOpponent: true,
          //   value: 'opponentFeaturedPlayer',
          //   text: 'Opponent Featured Player',
          // },
          // {
          //   isOpponent: true,
          //   value: 'opponentFeaturedCoach',
          //   text: 'Opponent Featured Coach',
          // },
          {
            isOpponent: true,
            value: 'opponentCoach',
            text: 'Opponent Coaches',
          },
        ],
        importType: null,
        templateId: null as number,
      };
    },
    computed: {
      ...mapGetters('builder', { templates: 'getTemplates' }),
      ...mapGetters('programs', { program: 'getProgram' }),
      programHasSport(): boolean {
        return this.program.sport.clientSportId !== 0;
      },
      programHasEvent(): boolean {
        return this.program.event.scheduleId !== 0;
      },
      pageSportSelected(): boolean {
        return this.selectedSport?.clientSportId !== 0;
      },
      pageEventSelected(): boolean {
        return this.selectedEvent.id !== 0;
      },
      opponentLogo(): string {
        return this.programHasEvent ? this.program.event.away.logoUrl : this.selectedEvent.opponent?.logoUrl;
      },
      opponentName(): string {
        return this.programHasEvent ? this.program.event.away.name : this.selectedEvent.opponent?.name;
      },
    },
    methods: {
      fetchClient: ClientService.fetchClient,
      fetchScheduleEventInfo: ClientService.fetchScheduleEventInfo,
      fetchCoaches: ClientService.fetchOpponentCoaches,
      fetchGlobalSport: ClientService.fetchGlobalSport,
      fetchOpponentInfo: ClientService.fetchOpponentInfo,
      fetchOpponentRosterBio: ClientService.fetchOpponentRosterBio,
      getOpponentTenantId: ClientService.getOpponentTenantId,
      fetchOpponentRosterPlayers: ClientService.fetchOpponentRosterPlayers,
      fetchOpponentScheduleResultEvents: ClientService.fetchOpponentScheduleResultEvents,
      fetchOpponentScheduleUpcomingEvents: ClientService.fetchOpponentScheduleUpcomingEvents,
      ...mapActions('builder', { fetchTemplates: 'fetchTemplates', fetchTemplate: 'fetchTemplate' }),
      ...mapActions('ui', { showToast: 'showToast' }),
      onTypeChange() {
        const { type } = this;
        this.setType(type);
        this.setImportType(this.importType);
        this.resetValidation();
        this.isFormValid = true;
      },
      async getClientSportsAsync() {
        const clientId = this.program.clientId;

        this.toggleLoading();

        try {
          this.clientSports = await ClientService.fetchSports({ clientId: clientId });
          this.toggleLoading();
        } catch (error) {
          this.clientSports = [];
          this.showToast('Something went wrong');
          this.toggleLoading();
        }
      },
      async getSportEvents() {
        this.clearSelectedEvent();

        const { clientId } = this.program;
        const sportId = this.programHasSport ? this.program.sport.clientSportId : this.selectedSportId;

        // handles cleared selection action
        if (!sportId) {
          this.clearSelectedSport();
          this.sportEvents = [];
          return;
        }

        const sport = this.clientSports.find(({ clientSportId }) => clientSportId === sportId);
        this.selectedSport = sport;
        this.setPageSport(this.selectedSport);

        this.toggleLoading();

        this.sportEvents = await ClientService.fetchSportEvents({ clientId: clientId, sportId: sportId }).catch(() => {
          this.sportEvents = [];
        });

        this.toggleLoading();
        this.updateImportTypesAsync();
      },
      async setEventAsync() {
        // handles cleared selection action
        if (!this.selectedEventId) {
          this.clearSelectedEvent();
          return;
        }

        const { clientId } = this.program;
        const client = await this.fetchClient(clientId);
        const event = this.sportEvents.find(({ id }) => id === this.selectedEventId);

        const httpRegex = /(http|https)/;
        if (event.opponentLogoUrl !== '' && !httpRegex.test(event.opponentLogoUrl)) {
          event.opponentLogoUrl = `${client.siteUrl}${event.opponentLogoUrl}`;
        }

        this.selectedEvent = {
          id: event.id,
          opponent: {
            name: event.opponentName ?? '',
            logoUrl: event.opponentLogoUrl ?? '',
            mascot: event.opponentMascot ?? '',
            standing: event.awayStanding ?? '',
          },
        };
        this.setPageEvent(this.selectedEvent);

        this.updateImportTypesAsync();
      },
      getEventTitle(item: any) {
        const date = DateTime.fromISO(item.date);
        const formattedDate = date.toFormat('MM/dd/yyyy');
        return (item.locationIndicator == 'H' ? 'VS' : 'AT') + ' — ' + item.opponentName + ' — ' + formattedDate;
      },
      onImportTypeChange() {
        this.setImportType(this.importType);
      },
      clearSelectedSport() {
        this.selectedSportId = 0;
        this.selectedSport = {
          clientSportId: 0,
          globalSportId: 0,
        };
        this.setPageSport(this.selectedSport);
        this.clearSelectedEvent();
      },
      clearSelectedEvent() {
        this.selectedEventId = 0;
        this.selectedEvent = {
          id: 0,
          opponent: {
            name: null,
            logoUrl: null,
            mascot: null,
            standing: null,
          },
        };
        this.setPageEvent(this.selectedEvent);
        this.updateImportTypesAsync();
      },
      async updateImportTypesAsync() {
        if (this.importType) {
          this.importType = null;
          this.resetValidation();
        }

        if (!this.programHasSport) {
          this.importTypes[1].disabled = !this.pageSportSelected;
          this.importTypes[2].disabled = !this.pageSportSelected;
          this.importTypes[3].disabled = !this.pageSportSelected;
          this.importTypes[4].disabled = !this.pageSportSelected;
          this.importTypes[5].disabled = !this.pageSportSelected;
        }

        const { clientId } = this.program;
        const { tenantId } = clientId ? await this.fetchClient(clientId) : null;

        const { globalSportId } = this.programHasSport ? this.program.sport : this.selectedSport;
        const eventId = this.programHasEvent ? this.program.event.scheduleId : this.selectedEvent.id;

        const event = eventId && tenantId ? await this.fetchScheduleEventInfo({ eventId, tenantId }) : null;
        const opponentId = event?.opponent?.id;

        const opponent = tenantId && opponentId ? await this.fetchOpponentInfo({ tenantId, opponentId }) : null;
        const opponentGlobal =
          opponent && opponent.opponentGlobalId
            ? await this.getOpponentTenantId(opponent.opponentGlobalId.toString())
            : null;

        if (opponentGlobal) {
          const opponentTenantId = opponentGlobal.client_abbreviation;

          this.selectedEvent = {
            id: this.selectedEvent.id,
            opponent: {
              name: opponentGlobal.client_name ?? '',
              logoUrl: opponentGlobal.logo ?? '',
              mascot: opponentGlobal.mascot ?? '',
              standing: opponentGlobal.standing ?? '',
            },
          };
          this.setPageEvent(this.selectedEvent);

          await this.fetchGlobalSport({ globalSportId, tenantId: opponentTenantId })
            .then((sport) => {
              const { rosterId, id: opponentSportId } = sport;

              this.fetchCoaches({ sportId: opponentSportId, tenantId: opponentTenantId })
                .then((c) => {
                  this.importTypes[8].disabled = c.length > 0 ? false : true;
                })
                .catch(() => {
                  this.importTypes[8].disabled = true;
                });

              this.fetchOpponentRosterPlayers({ rosterId, tenantId: opponentTenantId })
                .then((c) => {
                  this.importTypes[7].disabled = c.length > 0 ? false : true;
                })
                .catch(() => {
                  this.importTypes[7].disabled = true;
                });

              this.fetchOpponentScheduleResultEvents({
                sportId: opponentSportId,
                tenantId: opponentTenantId,
              })
                .then((c) => {
                  if (c.length > 0) {
                    this.importTypes[6].disabled = false;
                  } else {
                    this.fetchOpponentScheduleUpcomingEvents({
                      sportId: opponentSportId,
                      tenantId: opponentTenantId,
                    })
                      .then((d) => {
                        this.importTypes[6].disabled = c.length > 0 || d.length > 0 ? false : true;
                      })
                      .catch(() => {
                        this.importTypes[6].disabled = true;
                      });
                  }
                })
                .catch(() => {
                  this.fetchOpponentScheduleUpcomingEvents({
                    sportId: opponentSportId,
                    tenantId: opponentTenantId,
                  })
                    .then((c) => {
                      this.importTypes[6].disabled = c.length > 0 ? false : true;
                    })
                    .catch(() => {
                      this.importTypes[6].disabled = true;
                    });
                });
            })
            .catch(() => {
              this.importTypes[6].disabled = true;
              this.importTypes[7].disabled = true;
              this.importTypes[8].disabled = true;
            });
        } else {
          this.importTypes[6].disabled = true;
          this.importTypes[7].disabled = true;
          this.importTypes[8].disabled = true;
        }
      },
      async onSubmit() {
        const form = this.$refs.form as VForm;
        this.isFormValid = form.validate();
        if (!this.isFormValid) return;
        if (this.type === 'template') {
          try {
            const { templateId } = this;
            this.toggleLoading();
            this.isFormDisabled = true;
            const { page: content } = await this.fetchTemplate(templateId);
            const teamInfo = this.program.event.home;
            const data = {
              alt: `${teamInfo.mascot} team logo`,
              image: teamInfo.logoUrl,
              firstText: teamInfo.mascot,
              secondText: teamInfo.name,
              locationType: 'home',
            };
            const idx = content.blocks.findIndex((item: any) => {
              return item.type === 'textimage';
            });
            if (templateId == 9) {
              content.blocks[2].data.players[0].rosterHeadshot = '';
            }
            if (idx != -1) content.blocks[idx].data = data;
            this.setTemplateId(templateId);
            this.setPageContent(content);
            this.goNext();
          } catch (error) {
            const msg = error?.response?.data || 'Could not fetch page template';
            this.showToast(msg);
            // // console.warn(error);
          } finally {
            this.toggleLoading();
            this.isFormDisabled = false;
          }
        } else {
          this.goNext();
        }
      },
      isRequired(value: string | number): boolean | string {
        return (
          (typeof value === 'string' && !!value.trim().length) ||
          (typeof value === 'number' && value > 0) ||
          'Field is required'
        );
      },
      resetValidation() {
        (this.$refs.form as VForm).resetValidation();
      },
      reset() {
        this.type = 'template';
        this.setType(this.type);
        (this.$refs.form as VForm).reset();
      },
    },
    async created() {
      try {
        this.toggleLoading();
        this.isFormDisabled = true;

        if (!this.programHasSport) {
          await this.getClientSportsAsync();
        } else {
          this.getSportEvents();
        }

        await this.updateImportTypesAsync();

        this.setType('import');
        this.setImportType(this.importType);
        await this.fetchTemplates();
      } catch (error) {
        // console.warn('Could not fetch page templates list');
      } finally {
        this.toggleLoading();
        this.isFormDisabled = false;
      }
    },
  });
</script>
<style lang="scss">
  .page-info img,
  .list-item-image {
    width: 20px;
    margin-right: 10px;
  }
</style>
