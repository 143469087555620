<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title class="text-h6">{{ config.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Edit</v-btn>
    </v-toolbar>
    <v-card-text class="pt-0">
      <label for="text-field">{{ config.label }}</label>
      <v-text-field
        class="mt-2"
        name="text-field"
        outlined
        hide-details
        dense
        :placeholder="config.placeholder"
        :value="value.text"
        readonly
      ></v-text-field>
    </v-card-text>
    <text-field-modal
      v-model="modal"
      :title="config.title"
      :label="config.label"
      :placeholder="config.placeholder"
      :data="value.text"
      @submit="$emit('input', { text: $event })"
    ></text-field-modal>
  </v-card>
</template>
<script lang="ts">
  import Vue from 'vue';
  import TextFieldModal from './TextFieldModal.vue';
  export default Vue.extend({
    name: 'InlineFieldBlock',
    components: {
      TextFieldModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
      };
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
    },
  });
</script>
