<template>
  <v-dialog persistent width="868" :value="value" @input="$emit('input', $event)">
    <v-card>
      <v-card-title class="pt-24 pb-4">{{ title }}</v-card-title>
      <v-card-text class="px-6 pb-6 pt-0">
        <v-form :ref="'stepForm'" v-model="valid" lazy-validation>
          <upload-image
            :value="formData.image"
            :fileUrl="formData.image"
            :clientId="program.clientId"
            @input="formData.image = $event"
            :allow-pdf="page?.content?.fullPage"
            class="mb-5 m t-2"
          />
          <v-row>
            <v-col>
              <label class="grey--text text--darken-1">Alt Text</label>
              <v-text-field
                outlined
                dense
                v-model="formData.alt"
                class="mt-2"
                required
                :rules="altRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col>
              <label class="grey--text text--darken-1">Redirect URL Link</label>
              <v-text-field outlined dense hide-details v-model="formData.link" class="mt-2"></v-text-field>
            </v-col>
          </v-row>
          <template v-if="type === 'image-video'">
            <v-checkbox v-model="formData.hasCaption" hide-details label="Include Image Caption"></v-checkbox>

            <div class="mt-5" v-if="formData.hasCaption">
              <label class="grey--text text--darken-1">Image Caption</label>
              <v-text-field outlined dense hide-details v-model="formData.caption" class="mt-2"></v-text-field>
            </div>
            <v-checkbox v-model="formData.hasVideo" hide-details label="Include Video"></v-checkbox>
          </template>
          <div class="mt-5" v-if="formData.hasVideo || type === 'video'">
            <label class="grey--text text--darken-1">Video URL Link</label>
            <v-text-field outlined dense hide-details v-model="formData.videoUrl" class="mt-2"></v-text-field>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-6 pb-6 pt-0 d-flex justify-space-between">
        <v-btn class="text-capitalize" color="primary" depressed @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" color="grey lighten-2" depressed @click="clear">Clear</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import { UploadImage } from '@/components';

  export default Vue.extend({
    name: 'MediaModal',
    components: {
      UploadImage,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      value: {
        type: Boolean,
        required: true,
      },
      data: {
        type: Object,
      },
      type: {
        type: String,
        default: 'image-video',
      },
      program: {
        type: Object,
      },
    },
    computed: {
      ...mapGetters('builder', { page: 'getPage' }),
      form(): VForm {
        return this.$refs.stepForm as VForm;
      },
    },
    data() {
      return {
        valid: false,
        altRules: [
          (v: string) => {
            if (this.$data.formData.image == null || this.$data.formData.image == '') return true;
            return !!v || 'Alt Text is required';
          },
        ],
        formData: {
          image: this.data.image,
          link: this.data.link,
          alt: this.data.alt,
          hasCaption: this.data.hasCaption,
          caption: this.data.caption,
          hasVideo: this.data.hasVideo,
          videoUrl: this.data.videoUrl,
        },
      };
    },
    methods: {
      async onSave() {
        if (this.form.validate()) {
          this.$emit('submit', this.formData);
          this.close();
        }
      },
      close() {
        this.$emit('input', false);
      },
      clear() {
        this.formData = {
          image: '',
          link: '',
          alt: '',
          hasCaption: false,
          caption: '',
          hasVideo: false,
          videoUrl: '',
        };
      },
      validate() {
        this.form.validate();
      },
    },
  });
  type VForm = Vue & { validate: () => boolean };
</script>
