import aws from 'aws-sdk';
import config from '@/constants/config';
import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

interface fileResultType {
  status: number;
  file: string;
}

interface urlResultType {
  status: number;
  url: string;
}

export const fileDelete = async (fileName: string) => {
  const { secretAccessKey, accessKey, region, bucket } = config.aws.digitalPrograms;

  aws.config.update({
    secretAccessKey: secretAccessKey,
    accessKeyId: accessKey,
  });

  const s3 = new aws.S3({
    signatureVersion: 'v4',
    region: region,
  });

  const url = s3.getSignedUrl('deleteObject', {
    Bucket: bucket,
    Key: fileName,
    Expires: 10,
  });

  const result: fileResultType = {
    status: null,
    file: '',
  };

  return axios
    .delete(url)
    .then((res: AxiosResponse) => {
      console.log('res :>> ', res);
      result.status = res?.status || 200;
      return result;
    })
    .catch((e: any) => {
      result.status = e.response?.status || 400;
      return result;
    });
};

export const fileUpload = async (file: File, folder: string) => {
  const { secretAccessKey, accessKey, region, bucket } = config.aws.digitalPrograms;

  if (!secretAccessKey || !accessKey) {
    return null;
  }

  aws.config.update({
    secretAccessKey: secretAccessKey,
    accessKeyId: accessKey,
  });

  const s3 = new aws.S3({
    signatureVersion: 'v4',
    region: region,
  });

  const currentTime = DateTime.now().toFormat('yyyyMMddHHmmss');

  const key = folder + '/' + currentTime + '_' + file.name.replace(/\s/g, '-');

  const url = s3.getSignedUrl('putObject', {
    Bucket: bucket,
    Key: key,
    Expires: 10,
    ContentType: file.type,
  });

  const result: fileResultType = {
    status: null,
    file: '',
  };

  try {
    const res = await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    const bucketUrl = decodeURIComponent(res.request.responseURL).split(key)[0];
    const fullPath = bucketUrl + key;

    result.status = res?.status || 200;
    result.file = fullPath;
    return result;
  } catch (e) {
    result.status = e.response?.status || 400;
    return result;
  }
};

export const publicationUpload = async (blob: Blob, bucket: string, folder: string, filename: string) => {
  const isNextGen = bucket == 'sidearm.nextgen.sites';

  const { secretAccessKey, accessKey, region } = isNextGen ? config.aws.nextgen : config.aws.legacy;

  if (!secretAccessKey || !accessKey) {
    return null;
  }

  aws.config.update({
    secretAccessKey: secretAccessKey,
    accessKeyId: accessKey,
  });

  const s3 = new aws.S3({
    signatureVersion: 'v4',
    region: region,
  });

  const key = `${folder}/publications/${filename}`;

  const params = {
    Bucket: bucket,
    Key: key,
    Expires: 10,
    ContentType: blob.type,
    ACL: 'public-read',
  };

  const url = s3.getSignedUrl('putObject', params);

  const result: urlResultType = {
    status: null,
    url: '',
  };

  try {
    const res = await axios.put(url, blob, {
      headers: {
        'Content-Type': blob.type,
      },
    });
    const bucketUrl = decodeURIComponent(res.request.responseURL).split(key)[0];
    const fullPath = bucketUrl + key;

    result.status = res.status;
    result.url = fullPath;
    return result;
  } catch (e) {
    result.status = e.response.status;
    return result;
  }
};
