<template>
  <div>
    <v-card outlined>
      <v-card-title class="px-4">
        {{ config.title }}
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Edit</v-btn>
      </v-card-title>
      <v-card-subtitle class="px-4 text--secondary">{{ config.subtitle }}</v-card-subtitle>
      <div class="pt-0 pb-4">
        <v-list-item>
          <v-list-item-avatar class="my-0 rounded" color="grey" size="48" tile>
            <v-img :alt="player.rosterHeadshotAltText || 'Player Headshot'" :src="player.image || '/default-img.png'" />
          </v-list-item-avatar>
          <v-list-item-content class="py-0">
            <v-list-item-title class="mb-2 text-body-2 font-weight-bold">
              {{ player.firstName }} {{ player.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle class="d-flex align-center gap-2 text-caption">
              <span class="font-weight-bold text--primary">{{ player.position }}</span>
              <v-divider vertical />
              #{{ player.jerseyNumber }}
              <v-divider vertical />
              {{ player.class }}
              <v-divider vertical />
              {{ player.height }}
              <v-divider vertical />
              {{ player.weight }}lbs
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
    <player-edit-modal v-model="modal" :data="value.player" @submit="$emit('input', { player: $event })" />
  </div>
</template>
<script lang="ts">
  import { PlayerDetails } from '@/store/client/types';
  import Vue, { PropType } from 'vue';
  import PlayerEditModal from './PlayerEditModal.vue';
  export default Vue.extend({
    name: 'PlayerBlock',
    components: {
      PlayerEditModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object as PropType<{ player: PlayerDetails }>,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
      };
    },
    computed: {
      player(): PlayerDetails {
        return this.value.player;
      },
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
    },
  });
</script>
