import { CognitoAuth, StorageHelper } from 'amazon-cognito-auth-js';
import router from '@/router';
import store from '@/store';
import { COGNITO_AUTH } from '@/constants/feature_flags';
import API from '@/services/api/api';
import config from '@/constants/config';

const { appUrl } = config;
const { clientId, appDomain, redirectURI, redirectURISignout, userPoolId } = config.aws.cognito;

const authData = {
  ClientId: clientId,
  AppWebDomain: appDomain,
  TokenScopesArray: ['openid', 'email'],
  RedirectUriSignIn: appUrl + redirectURI,
  RedirectUriSignOut: appUrl + redirectURISignout,
  UserPoolId: userPoolId,
};

let auth: CognitoAuth | null = null;

if (COGNITO_AUTH.ENABLED) {
  auth = new CognitoAuth(authData);

  auth.userhandler = {
    onSuccess: function () {
      store.dispatch('user/setLoggedIn', true);

      const userName = auth?.getSignInUserSession().getAccessToken().getUsername();
      store.dispatch('user/setUserData', { userName: userName });

      const token = auth?.getSignInUserSession().getAccessToken();
      store.dispatch('user/setAuthToken', token);

      router.push('/');
    },
    onFailure: function (err) {
      store.dispatch('user/setLoggedIn', true);
      router.push({ path: '/error', query: { message: 'Login failed due to ' + err } });
    },
  };
}
function getUserInfoStorageKey() {
  const keyPrefix = 'CognitoIdentityServiceProvider.' + auth?.getClientId();
  const tokenUserName = auth?.getSignInUserSession().getAccessToken().getUsername();
  const userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
  return userInfoKey;
}

const storageHelper = new StorageHelper();
const storage = storageHelper.getStorage();

const _authenticate = (endpoint: string, data = {}, config = {}) => {
  return API.post(endpoint, data, config)
    .then((response) => {
      const { token } = response.data;
      store.dispatch('user/setAuthToken', token);
      store.dispatch('user/setLoggedIn');
      return token;
    })
    .catch((e) => {
      return e.response ? Promise.reject(e.response) : e;
    });
};

export const authenticate = (user: any) => {
  return _authenticate('auth/authenticate', {
    email: user.email,
    password: user.password,
  });
};

export const isAuthenticated = () => {
  try {
    const token = store.getters['user/getAuthToken'];
    if (token) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export default {
  auth: auth,
  login() {
    auth?.getSession();
  },
  logout() {
    if (auth?.isUserSignedIn()) {
      const userInfoKey = this.getUserInfoStorageKey();
      auth.signOut();

      storage.removeItem(userInfoKey);
    }
  },
  getUserInfoStorageKey,
  authenticate,
  isAuthenticated,
};
