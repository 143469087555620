<template>
  <div>
    <v-card outlined>
      <div class="px-4 d-flex align-center gap-2">
        <div>
          <v-card-title class="px-0">{{ config.title }}</v-card-title>
          <v-card-subtitle class="px-0 text--secondary">{{ config.subtitle }}</v-card-subtitle>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Bulk Edit</v-btn>
      </div>

      <v-tabs v-model="tab" grow class="px-4">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="px-4">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <div class="pt-0 my-4 d-flex flex-column gap-4">
              <v-card
                v-for="(member, index) in items[tab] === 'Coaches' ? sortedMembers : sortedSupportStaff"
                outlined
                class="py-4"
                :key="index"
              >
                <!-- <div class="pt-0 pb-4 px-4 d-flex flex-column gap-4"> -->
                <v-list-item>
                  <v-list-item-avatar class="my-0 rounded" color="grey" size="48" tile>
                    <v-img :src="member.rosterHeadshot || '/default-img.png'" :alt="member.rosterHeadshotAltText" />
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="mb-2 text-body-2 font-weight-bold">
                      {{ member.firstName }} {{ member.lastName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="d-flex align-center gap-2 text-caption">
                      {{ member.title }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      tile
                      class="text-capitalize grey lighten-4 rounded text--primary"
                      @click="onRemove(member.id)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- <staff-edit-modal v-model="modal" :data="value.members" @submit="$emit('input', { members: $event })" /> -->
    <staff-edit-modal
      v-model="modal"
      :data="value"
      @submit="$emit('input', { members: $event.coaches, supportMembers: $event.supportMembers })"
    />
  </div>
</template>
<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { CoachDetails } from '@/store/client/types';
  import StaffEditModal from './StaffEditModal.vue';
  export default Vue.extend({
    name: 'StaffBlock',
    components: {
      StaffEditModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object as PropType<{ members: CoachDetails[]; supportMembers?: CoachDetails[] }>,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
        tab: 0 as number,
        // items: ['Coaches', 'Support Staff'] as string[],
      };
    },
    computed: {
      sortedMembers(): CoachDetails[] {
        return this.value.members;
      },
      sortedSupportStaff(): CoachDetails[] {
        return this.value?.supportMembers;
      },
      items(): string[] {
        if (this.sortedSupportStaff) {
          return ['Coaches', 'Support Staff'];
        }
        return ['Coaches'];
      },
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
      onRemove(id: number) {
        if (this.items[this.tab] === 'Coaches') {
          const { members } = this.value;
          const i = members.findIndex((member: CoachDetails) => member.id === id);
          if (i >= 0) members.splice(i, 1);
        } else {
          const { supportMembers } = this.value;
          const i = supportMembers.findIndex((member: CoachDetails) => member.id === id);
          if (i >= 0) supportMembers.splice(i, 1);
        }
      },
    },
  });
</script>
