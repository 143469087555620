import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { ProgramsState } from './types';
import { RootState } from '../types';

export const state: ProgramsState = {
  programs: [],
  pages: 0,
  program: null,
};

export const programs: Module<ProgramsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
