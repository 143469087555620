<template>
  <v-form ref="form" @submit.prevent="onSubmit" v-model="isFormValid" lazy-validation :disabled="isFormDisabled">
    <v-card-text class="scrollable">
      <label class="grey--text text--darken-1" for="title-field">Page Title</label>
      <v-text-field id="title-field" class="mt-2" outlined dense :rules="[isRequired]" v-model="title"></v-text-field>
      <label class="grey--text text--darken-1">Page Sponsor Image</label>
      <upload-image
        :value="sponsor.image.url"
        height="50"
        width="320"
        :fileUrl="sponsor.image.url"
        @input="sponsor.image.url = $event"
        :client-id="program.clientId"
        key="2"
        class="mb-5 mt-2"
      />
      <label class="grey--text text--darken-1" for="alt-text-field">Alt Text</label>
      <v-text-field
        id="alt-text-field"
        class="mb-5 mt-2"
        outlined
        hide-details
        dense
        v-model="sponsor.image.alt"
      ></v-text-field>
      <label class="grey--text text--darken-1" for="image-link-field">Image URL Redirect Link</label>
      <v-text-field
        id="image-link-field"
        class="mb-5 mt-2"
        hide-details
        outlined
        dense
        v-model="sponsor.image.link"
      ></v-text-field>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack">Back</v-btn>
      <v-btn color="primary" type="submit" depressed class="text-capitalize" :disabled="!isFormValid"> Continue </v-btn>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset">Clear</v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </v-form>
</template>
<script lang="ts">
  import { VForm } from '@/types';
  import Vue, { VueConstructor } from 'vue';
  import { UploadImage } from '@/components';
  import { mapActions, mapGetters } from 'vuex';
  import { eventBus } from '../../main';

  interface Block {
    config: object;
    data: object;
    id: number;
    type: string;
  }

  interface Injections {
    toggleLoading: () => void;
    getPageTitle: () => string;
    getPageContent: () => any;
    getTemplateId: () => number;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'PageDetails',
    components: { UploadImage },
    inject: ['toggleLoading', 'getPageTitle', 'getPageContent', 'getTemplateId', 'goBack', 'close'],
    data() {
      return {
        isFormValid: true,
        isFormDisabled: false,
        title: '',
        sponsor: {
          image: {
            url: '',
            alt: '',
            link: '',
          },
        },
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
    },
    methods: {
      ...mapActions('builder', ['savePage']),
      ...mapActions('ui', { showToast: 'showToast' }),
      isRequired(value: string | number): boolean | string {
        return (
          (typeof value === 'string' && !!value.trim().length) ||
          (typeof value === 'number' && value > 0) ||
          'Field is required'
        );
      },
      setTitle(title: string) {
        if (!this.title) {
          this.title = title;
        }
      },
      async onSubmit() {
        const { title, sponsor, program } = this;
        this.toggleLoading();
        this.isFormDisabled = true;
        const templateId = this.getTemplateId();
        const content = this.getPageContent();

        try {
          const response = await this.savePage({
            programId: program.id,
            page: {
              title,
              content,
              templateId,
              sponsor,
            },
          });
          eventBus.$emit('changePage', response.id);
          this.close();
        } catch (error) {
          const msg = error?.response?.data || 'Could not create page';
          this.showToast(msg);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
      reset() {
        (this.$refs.form as VForm).reset();
      },
    },
  });
</script>
