<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <v-card-text class="scrollable">
      <label class="text--secondary">Select one coach to import</label>
      <v-data-table
        class="elevation-2 mt-2 custom-table"
        :class="{ invalid: !isFormValid }"
        checkbox-color="primary"
        :headers="headers"
        disable-pagination
        hide-default-footer
        show-select
        fixed-header
        sort-by="rank"
        :items="coaches"
        v-model="selected"
        height="360"
        :loading="isLoading"
        single-select
      >
        <template #item.data-table-select="{ item, select }">
          <v-checkbox
            class="mt-0"
            hide-details
            :value="item.id === selectedCoachId"
            on-icon="$radioOn"
            off-icon="$radioOff"
            @change="select"
            :disabled="isFormDisabled"
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack" :disabled="isFormDisabled">
        Back
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        depressed
        class="text-capitalize"
        :disabled="!isFormValid || isFormDisabled"
        @click="onSubmit"
      >
        Continue
      </v-btn>
      <span v-if="!isFormValid" class="error--text">Select one coach</span>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset" :disabled="isFormDisabled">
        Clear
      </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import ClientService from '@/services/client/client';
  import { mapActions, mapGetters } from 'vuex';
  import { CoachDetails } from '@/store/client/types';
  import { sortBy } from 'lodash-es';
  interface Injections {
    setPageTitle: (title: string) => void;
    setPageContent: (content: any) => void;
    toggleLoading: () => void;
    getPageSport: () => any;
    goNext: () => void;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'FeaturedCoachImporter',
    inject: ['setPageTitle', 'setPageContent', 'toggleLoading', 'getPageSport', 'goNext', 'goBack', 'close'],
    data() {
      return {
        coaches: [] as CoachDetails[],
        selected: [] as CoachDetails[],
        headers: [
          {
            text: 'Rank',
            align: 'start',
            value: 'rank',
          },
          {
            text: 'First Name',
            align: 'start',
            value: 'firstName',
          },
          {
            text: 'Last Name',
            align: 'start',
            value: 'lastName',
          },
          {
            text: 'Title',
            align: 'start',
            value: 'title',
          },
        ],
        isLoading: false,
        isFormValid: true,
        isFormDisabled: false,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
      selectedCoachId(): number | null {
        return this.selected.length ? this.selected[0].id : null;
      },
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchSportCoaches: ClientService.fetchSportCoaches,
      fetchCoach: ClientService.fetchCoach,
      fetchClient: ClientService.fetchClient,
      async importCoach() {
        const { clientId } = this.program;
        const client = await this.fetchClient(clientId);
        const teamInfo = this.program.event.home;
        const coachId = this.selected[0].id;
        const coach = await this.fetchCoach({ clientId, coachId });
        const httpRegex = /(http|https)/;
        if (coach.rosterHeadshot !== '' && !httpRegex.test(coach.rosterHeadshot)) {
          coach.rosterHeadshot = `${client.siteUrl}${coach.rosterHeadshot}`;
        }
        const headerText = 'Featured Coach';
        this.setPageTitle(`${headerText} Import`);
        return {
          blocks: [
            {
              id: 1,
              data: {
                text: headerText,
              },
              type: 'header',
              config: {
                label: 'Describe what this page is about',
                title: 'Page Heading',
                placeholder: 'Page Heading Text Goes Here...',
              },
            },
            {
              id: 2,
              data: {
                alt: `${teamInfo.mascot} team logo`,
                image: teamInfo.logoUrl,
                firstText: teamInfo.mascot,
                secondText: teamInfo.name,
                locationType: 'home',
              },
              type: 'textimage',
              config: {
                label: 'Choose which team this page is associated with',
                title: 'Team Heading',
                imageLabel: 'School Logo',
                displayConfig: true,
                firstTextDefault: 'mascot',
                secondTextDefault: 'name',
                placeholderFirstText: 'School Nickname/Mascot',
                placeholderSecondText: 'School Name',
              },
            },
            {
              id: 3,
              data: {
                coach,
              },
              type: 'coach',
              config: {
                title: 'Coach',
                subtitle: 'Coach bio',
              },
            },
            {
              id: 4,
              data: {
                html: '<p>Sample text</p>',
              },
              type: 'html',
              config: {
                label: 'Body',
                title: 'Featured Coach Body Text',
              },
            },
          ],
        };
      },
      async onSubmit() {
        this.isFormValid = !!this.selected.length;
        if (!this.isFormValid) return;
        try {
          this.toggleLoading();
          this.isFormDisabled = true;
          const content = await this.importCoach();
          this.setPageContent(content);
          this.goNext();
        } catch (error) {
          const msg = error?.response?.data || 'Could not import coaches data';
          this.showToast(msg);
          // // console.warn(error);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
      reset() {
        this.selected = [];
        this.isFormValid = true;
      },
    },
    async created() {
      try {
        this.isFormDisabled = true;
        this.isLoading = true;
        const { clientId } = this.program;
        // const { clientSportId } = this.program.sport;
        let clientSportId = this.program.sport.clientSportId;
        if (!clientSportId) {
          let pageSport = this.getPageSport();
          clientSportId = pageSport.clientSportId;
        }
        const coaches = await this.fetchSportCoaches({ clientId, clientSportId });
        this.coaches = coaches.map((p, i) => {
          return {
            ...p,
            rank: i + 1,
          };
        });
      } catch (error) {
        this.showToast('Could not fetch roster data');
        // // console.warn(error);
      } finally {
        this.isFormDisabled = false;
        this.isLoading = false;
      }
    },
    watch: {
      selected(selected: CoachDetails[]) {
        if (selected && selected.length) this.isFormValid = true;
      },
    },
  });
</script>
