<template>
  <v-dialog persistent max-width="1080" :value="value" @input="$emit('input', $event)" transition="fade-transition">
    <v-card>
      <v-card-title class="py-4">{{ title }}</v-card-title>
      <div class="px-6">
        <label for="text-area">{{ label }}</label>
        <html-text-editor :htmlContent="html" />
      </div>
      <v-card-actions class="py-4 px-6">
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" color="primary" depressed @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import HtmlTextEditor from './HtmlTextEditor.vue';

  export default Vue.extend({
    name: 'HtmlModal',
    components: {
      HtmlTextEditor,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      data: {
        type: Object,
      },
    },
    provide(): any {
      return {
        setHtml: this.setHtml,
      };
    },
    data() {
      return {
        html: '',
      };
    },
    methods: {
      setHtml(html: string) {
        this.html = html;
      },
      async onSave() {
        this.$emit('submit', { html: this.html });
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
    },
    watch: {
      value(visible: boolean) {
        if (visible) this.html = this.data.html;
      },
    },
  });
</script>
