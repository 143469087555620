<template>
  <v-card
    :color="color"
    :min-height="minHeight"
    :max-height="maxHeight"
    :height="height"
    v-on="$listeners"
    v-bind="$props"
    rounded="lg"
    outlined
    min-width="100%"
  >
    <v-card-title v-if="$slots.title" class="grey lighten-5 text-h6 text-capitalize">
      <slot name="title"></slot>
    </v-card-title>
    <v-divider v-if="$slots.title"></v-divider>
    <slot></slot>
    <v-card-actions v-if="$slots.actions">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
  import Vue from 'vue';
  import VCard from 'vuetify/es5/components/VCard';
  export default Vue.extend({
    props: ['color', 'height', 'minHeight', 'maxHeight'],
    name: 'Card',
    extends: VCard,
  });
</script>
