<template>
  <div data-testid="clients-list" class="clients-list gap-6">
    <v-card class="pa-8" outlined v-for="client in clients" :key="client.id">
      <div class="d-flex flex-column justify-center align-center gap-4">
        <div class="client-logo">
          <v-img height="96" width="96" :alt="client.name" :src="client.logoUrl"></v-img>
        </div>
        <span class="text-h6 text-center">{{ client.name }}</span>
        <div class="grey--text text--darken-1 d-flex align-center">
          <v-icon small>mdi-map-marker-outline</v-icon>
          <span class="ml-2 text-body-2">{{ client.location }}</span>
        </div>
        <v-btn
          x-small
          color="grey lighten-4"
          class="mt-3 text-capitalize rounded-circle edit-button"
          data-testid="submit-btn"
          height="36"
          width="36"
          @click="toggleModal(client)"
        >
          <v-icon medium>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          depressed
          :href="client.name"
          small
          color="grey lighten-4"
          class="mt-3 text-capitalize"
          data-testid="submit-btn"
          height="36"
          :to="{ name: 'dashboard', query: { client: client.name, clientId: client.id } }"
        >
          <span class="mr-2 font-weight-bold">View Programs</span>
          <v-icon small>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-card>
    <EditClientModal v-model="modalOpen" :client="client" v-if="modalOpen"></EditClientModal>
  </div>
</template>

<script lang="ts">
  import { ClientState } from '@/store/client/types';
  import EditClientModal from './EditClientModal.vue';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    components: {
      EditClientModal,
    },
    name: 'ClientsList',
    props: {
      clients: {
        type: Array as PropType<ClientState[]>,
        default: (): ClientState[] => [],
      },
    },
    data: () => ({
      modalOpen: false,
      client: {} as ClientState,
    }),
    methods: {
      toggleModal(client: ClientState) {
        this.client = client;
        this.modalOpen = !this.modalOpen;
      },
    },
  });
</script>
<style lang="sass" scoped>

  .edit-button
    position: absolute
    top: 0
    right: 10px
  .clients-list
    display: grid
    grid-template-columns: repeat(1, minmax(0, 1fr))
  @media (min-width: 780px)
    .clients-list
      display: grid
      grid-template-columns: repeat(3, minmax(0, 1fr))
  @media (min-width: 1024px)
    .clients-list
      display: grid
      grid-template-columns: repeat(5, minmax(0, 1fr))
</style>
