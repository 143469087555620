<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="960" persistent>
    <v-card>
      <v-card-title class="px-4 pt-4 gap-2">
        Standing Conference
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!items.multipleDivisions || maxDivisions == divisions.length"
          color="primary"
          depressed
          class="text-capitalize"
          @click="onAddDivision"
          >Add Division</v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <label class="text--secondary">Multiple Divisions</label>
              <v-radio-group row v-model="items.multipleDivisions">
                <template v-slot:label> </template>
                <v-row>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
        <div v-for="(division, key) in divisions" :key="key" class="pb-4">
          <label class="text--secondary">Division Title</label>
          <v-text-field
            class="mt-2"
            name="text-field"
            outlined
            hide-details
            dense
            placeholder="division Title"
            v-model="division.title"
          ></v-text-field>
          <v-data-table
            :key="key"
            class="standing-table"
            disable-sort
            disable-filtering
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="division.table"
            item-key="id"
            v-model="selected[key]"
            show-select
            @toggle-select-all="selectAllItems($event, key)"
          >
            <template v-slot:body="{ items }">
              <draggable
                v-if="items.length"
                v-model="divisions[key].table"
                item-key="name"
                tag="tbody"
                ghost-class="ghost"
              >
                <template>
                  <tr v-for="(item, index) in items" :key="item.id">
                    <td>
                      <v-simple-checkbox v-model="items[index].isSelected"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-edit-dialog :return-value.sync="item.rank">
                        <span v-if="item.rank" :id="`${item['id']}-0`">{{ item.rank }}</span>
                        <span v-else class="text--disabled" :id="`${item['id']}-0`">&mdash;</span>
                        <template v-slot:input>
                          <div class="py-4">
                            <v-text-field
                              outlined
                              dense
                              hide-details
                              v-model="item.rank"
                              @blur="triggerEnterKeyPressEvent($event.target.id)"
                              @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 0)"
                              @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 0)"
                              single-line
                            ></v-text-field>
                          </div>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td>
                      <v-edit-dialog :return-value.sync="item.teamImage" large persistent @save="save" @cancel="cancel">
                        <v-img
                          height="48"
                          width="48"
                          :alt="item?.teamImageAltText || 'Team Image'"
                          :src="item?.teamImage || '/default-img.png'"
                          class="rounded-sm"
                        />
                        <template v-slot:input>
                          <div class="py-4">
                            <upload-image
                              :value="item?.teamImage"
                              height="200"
                              width="200"
                              :fileUrl="item.teamImage"
                              @input="divisions[key].table[index].teamImage = $event"
                              key="2"
                              class="mb-5 mt-2"
                            />
                          </div>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td>
                      <v-edit-dialog :return-value.sync="item.team">
                        <span v-if="item.team" :id="`${item['id']}-1`">{{ item.team }}</span>
                        <span v-else class="text--disabled" :id="`${item['id']}-1`">&mdash;</span>
                        <template v-slot:input>
                          <div class="py-4">
                            <v-text-field
                              outlined
                              dense
                              hide-details
                              v-model="item.team"
                              @blur="triggerEnterKeyPressEvent($event.target.id)"
                              @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 1)"
                              @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 1)"
                              single-line
                            ></v-text-field>
                          </div>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td>
                      <v-edit-dialog :return-value.sync="item.record">
                        <span v-if="item.record" :id="`${item['id']}-2`">{{ item.record }}</span>
                        <span v-else class="text--disabled" :id="`${item['id']}-2`">&mdash;</span>
                        <template v-slot:input>
                          <div class="py-4">
                            <v-text-field
                              outlined
                              dense
                              hide-details
                              v-model="item.record"
                              @blur="triggerEnterKeyPressEvent($event.target.id)"
                              @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 2)"
                              @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 2)"
                              single-line
                            ></v-text-field>
                          </div>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td>
                      <v-edit-dialog :return-value.sync="item.prev">
                        <span v-if="item.prev" :id="`${item['id']}-3`">{{ item.prev }}</span>
                        <span v-else class="text--disabled" :id="`${item['id']}-3`">&mdash;</span>
                        <template v-slot:input>
                          <div class="py-4">
                            <v-text-field outlined dense hide-details v-model="item.prev" single-line></v-text-field>
                          </div>
                        </template>
                      </v-edit-dialog>
                    </td>
                  </tr>
                </template>
              </draggable>
              <tbody v-else>
                <tr class="v-data-table__empty-wrapper">
                  <td :colspan="++headers.length">No data available</td>
                </tr>
              </tbody>
            </template>
            <template v-slot:item.data-table-select="{ isSelected, select }">
              <v-simple-checkbox color="green" :value="isSelected" @input="select($event)"></v-simple-checkbox>
            </template>
          </v-data-table>
          <div class="d-flex gap-2">
            <v-btn depressed class="text-none" @click="onRemoveRows(key)" :disabled="!getSelectedKey(key)"
              >Remove Row(s)</v-btn
            >
            <v-btn color="primary" depressed class="text-capitalize" @click="onAddRow(key)">Add Row</v-btn>
            <v-btn
              v-show="items.divisions.length > 1"
              color="primary"
              depressed
              class="text-capitalize"
              @click="onRemoveDivision(key)"
              >Remove division</v-btn
            >
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="px-4 pb-4">
        <v-btn class="text-capitalize" depressed color="primary" @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { cloneDeep, uniqueId } from 'lodash-es';
  import { Division, Standing, TeamPerDivision } from '@/store/client/types';
  import { UploadImage } from '@/components';
  import { mixins } from '../../mixin/mixins';
  import draggable from 'vuedraggable';

  export default Vue.extend({
    name: 'StandingEditModal',
    mixins: [mixins],
    components: { draggable, UploadImage },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      data: {
        type: Object as PropType<Standing>,
        required: true,
      },
    },
    data() {
      return {
        headers: [
          {
            text: 'Rank',
            align: 'start',
            value: 'rank',
          },
          {
            text: 'Image',
            align: 'start',
            value: 'teamImage',
          },
          {
            text: 'Team',
            align: 'start',
            value: 'team',
          },
          {
            text: 'Conference Record',
            align: 'start',
            value: 'record',
          },
          {
            text: 'Overall Record',
            align: 'start',
            value: 'prev',
          },
        ],
        maxDivisions: 2,
        items: {} as Standing,
        divisions: [] as Division[],
        selected: [] as TeamPerDivision[][],
      };
    },
    methods: {
      handleTabPress(idElement: string, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.moveNextElementModal(this.items, id, order);
      },
      handleShiftTabPress(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.movePreviousElementModal(this.items, id, order);
      },
      save() {
        // console.log('Dialog Saved');
      },
      cancel() {
        // console.log('Dialog cancel');
      },
      open() {
        // console.log('Dialog open');
      },
      genId(key: number): string {
        let id = uniqueId();
        while (this.divisions[key].table.some((item) => item.id === id)) {
          id = uniqueId();
        }
        return id;
      },
      getSelectedKey(key: number) {
        return this.divisions[key] == undefined
          ? false
          : this.divisions[key].table.filter((item) => item.isSelected).length;
      },
      onAddRow(key: number) {
        this.divisions[key].table.push({
          id: this.genId(key),
          rank: null,
          team: null,
          record: null,
          prev: null,
          teamImage: '',
        });
      },
      onAddDivision() {
        if (this.maxDivisions > this.divisions.length) {
          this.divisions.push({
            id: uniqueId(),
            title: null,
            table: [
              {
                id: uniqueId(),
                rank: null,
                team: null,
                record: null,
                prev: null,
                teamImage: '',
              },
            ],
          });
        }
      },
      onRemoveRows(key: number) {
        const idsToRemove = this.divisions[key].table.filter((item) => item.isSelected).map((item) => item.id);
        this.divisions[key].table = this.divisions[key].table.filter((item) => !idsToRemove.includes(item.id));
        this.selected = [];
      },
      onRemoveDivision(key: number) {
        this.divisions.splice(key, 1);
      },
      onremove() {
        this.items.divisions.forEach((d) => {
          d.table.pop();
        });
      },
      onSave() {
        if (!this.items.multipleDivisions) this.items.divisions = [this.divisions[0]];
        else this.items.divisions = this.divisions;
        this.$emit('submit', this.items);
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
      async selectAllItems({ value }: { items: TeamPerDivision[]; value: boolean }, key: number) {
        await this.$nextTick();
        this.divisions[key].table = this.divisions[key].table.map((item) => {
          return {
            ...item,
            isSelected: value,
          };
        });
      },
    },
    watch: {
      value(visible: boolean) {
        if (visible) {
          this.items = cloneDeep(this.data);

          this.divisions = this.items.multipleDivisions
            ? cloneDeep(this.items.divisions)
            : [cloneDeep(this.items.divisions[0])];

          this.divisions = this.divisions.map((d, key) => {
            d.table = d.table.map((t: TeamPerDivision) => {
              return {
                ...t,
                isSelected: false,
                id: t.id || this.genId(key),
                teamImage: t.teamImage || '',
              };
            });
            return d;
          });
          this.selected = [];
        }
      },
    },
    // computed: {
    //   divisions(): Division[] {
    //     if (!this.items.divisions) return [];
    //     const divisions = this.items.multipleDivisions ? this.items.divisions : [this.items.divisions[0]];
    //     console.log('🚀 ~ file: StandingEditModal.vue:301 ~ divisions ~ divisions:', divisions);
    //     return divisions.map((d, key) => {
    //       d.table = d.table.map((t) => {
    //         t.id = t.id || this.genId(key);
    //         t.teamImage = t.teamImage || '';
    //         return t;
    //       });
    //       return d;
    //     });
    //   },
    // },
  });
</script>
<style lang="scss">
  .cell-truncate {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-height: 1.5rem;
  }

  .standing-table {
    th {
      background: #f5f5f5 !important;
    }
    td:nth-child(2),
    th:nth-child(2),
    td:nth-child(4),
    th:nth-child(4),
    td:nth-child(5),
    th:nth-child(5) {
      width: 100px;
    }
  }
</style>
