<template>
  <div>
    <div class="mb-2 font-weight-bold">Image</div>
    <media-block title="Image" type="image" v-model="fullPageImage.media" />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import MediaBlock from './MediaBlock.vue';

  export default Vue.extend({
    name: 'FullPageImagePanel',
    components: {
      MediaBlock,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        fullPageImage: {
          headline: '',
          media: {
            image: '',
            link: '',
            alt: '',
            caption: '',
            videoUrl: '',
            hasCaption: false,
            hasVideo: false,
          },
          body: {
            blocks: [],
          },
        },
      };
    },
    watch: {
      value: {
        handler(data: any) {
          this.fullPageImage = data;
        },
        immediate: true,
      },
      fullPageImage: {
        handler(n: any) {
          this.$emit('input', n);
        },
        deep: true,
      },
    },
  });
</script>
