<template>
  <v-dialog
    content-class="elevation-2"
    width="960"
    :value="value"
    @input="$emit('input', $event)"
    :persistent="submittingForm"
  >
    <v-form :disabled="submittingForm" @submit.prevent="submitForm">
      <v-card rounded="lg" :loading="submittingForm ? 'white' : false">
        <v-card-title class="text-captalize white--text primary py-6">Add New Client</v-card-title>
        <v-card-text class="pt-6 px-6">
          <v-sheet class="mx-auto pt-16" height="280" width="540">
            <h3 class="mb-8 text-h5 font-weight-bold text-center grey--text">Search From Our List of Schools</h3>
            <v-autocomplete
              type="search"
              class="mt-0 pt-0"
              hide-details
              prepend-icon="mdi-magnify"
              placeholder="School Name"
              :items="clients"
              item-text="name"
              item-value="id"
              v-model="selected"
              :menu-props="{ closeOnClick: true }"
              :hide-no-data="loadingClients"
              no-data-text="No Results Found"
              :loading="loadingClients"
            >
              <template #prepend-item>
                <v-subheader class="text-body-1">Select School</v-subheader>
              </template>
              <template #item="{ on, attrs, item }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-avatar>
                    <v-img :src="item.logoUrl" :alt="item.name"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template #selection="{ item }">
                <v-avatar size="25" class="mx-4">
                  <v-img :src="item.logoUrl" :alt="item.name"></v-img>
                </v-avatar>
                {{ item.name }}
              </template>
            </v-autocomplete>
          </v-sheet>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <btn type="submit" color="primary" v-if="selected" :disabled="!selected || submittingForm">Add</btn>
          <btn @click="closeModal" :text="!!selected" :color="selected ? '' : 'primary'" :disabled="submittingForm">
            Cancel
          </btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Btn } from '@/components';
  import { mapActions, mapGetters } from 'vuex';
  import { GlobalClient } from '@/store/global/types';
  export default Vue.extend({
    components: { Btn },
    props: {
      value: { type: Boolean, default: false },
    },
    data() {
      return {
        selected: null,
        submittingForm: false,
        loadingClients: false,
      };
    },
    computed: {
      ...mapGetters('global', { clients: 'getClients' }),
    },
    methods: {
      async submitForm() {
        this.submittingForm = true;
        try {
          const client: GlobalClient = this.clients.find((client: GlobalClient) => client.id === this.selected);
          if (!client) throw new Error();
          const { id, name, logoUrl, siteUrl, location, clientAbbreviation } = client;
          await this.createClient({
            globalClientId: id,
            tenantId: clientAbbreviation,
            name,
            logoUrl,
            siteUrl,
            location,
          });
          this.handleSuccess();
        } catch (error) {
          this.handleFailure();
        }
        this.submittingForm = false;
      },
      handleSuccess() {
        this.showToast('Client added successfully');
        this.closeModal();
        this.$emit('save');
      },
      handleFailure() {
        this.showToast('Could not add client');
        this.submittingForm = false;
      },
      resetForm() {
        this.submittingForm = false;
        this.selected = null;
      },
      closeModal() {
        this.$emit('input', false);
      },
      ...mapActions('ui', { showToast: 'showToast' }),
      ...mapActions('global', ['fetchClients']),
      ...mapActions('client', ['createClient']),
    },
    watch: {
      value(n) {
        if (n === false) this.resetForm();
      },
    },
    async created() {
      try {
        this.loadingClients = true;
        await this.fetchClients({ notLocal: true });
      } catch (error) {
        // console.warn('Could not fetch global clients', error);
      }
      this.loadingClients = false;
    },
  });
</script>
