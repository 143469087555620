import { ActionTree } from 'vuex';
import { BuilderState, SavePagePayload, DeletePagePayload, Device } from './types';
import { RootState } from '../types';
import { BuilderMutations } from './mutations';
import API from '@/services/api/api';

export const actions: ActionTree<BuilderState, RootState> = {
  async fetchTemplates({ commit }) {
    try {
      const { data } = await API.get('/templates');
      commit(BuilderMutations.SET_TEMPLATES, data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchTemplate(_, id: number) {
    try {
      const { data } = await API.get(`/templates/${id}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async savePage({ commit, dispatch }, payload: SavePagePayload) {
    const {
      page: { id, templateId, title, content, sponsor },
      programId,
    } = payload;
    const url = `/programs/${programId}/pages${id ? `/${id}` : ''}`;
    const requestData = { templateId, title, content, sponsor };
    const method = id ? 'put' : 'post';
    try {
      const { data } = await API[method](url, requestData);
      if (id) await dispatch('fetchPage', { programId, pageId: id });
      else commit(BuilderMutations.APPEND_PAGE, data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchPages({ commit }, programId: string) {
    try {
      const { data } = await API.get(`/programs/${programId}/pages`);
      commit(BuilderMutations.SET_PAGES, data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchPage({ commit }, payload: { programId: number; pageId: number }) {
    const { pageId, programId } = payload;
    try {
      const { data } = await API.get(`/programs/${programId}/pages/${pageId}`);
      commit(BuilderMutations.SET_PAGE, data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deletePage({ dispatch }, payload: DeletePagePayload) {
    const { programId, pageId } = payload;
    try {
      await API.delete(`/programs/${programId}/pages/${pageId}`);
      dispatch('fetchPages', programId);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setPagePreviewVisibility({ commit }, visible: boolean) {
    commit(BuilderMutations.SET_PAGE_PREVIEW_VISIBILITY, visible);
  },
  setPagePreviewType({ commit }, type: Device) {
    commit(BuilderMutations.SET_PAGE_PREVIEW_TYPE, type);
  },
  setPageProgram({ commit }, progamId: number) {
    commit(BuilderMutations.SET_PROGRAM_ID, progamId);
  },
};
