<template>
  <v-btn v-on="$listeners" v-bind="$props" depressed class="text-capitalize font-weight-bold">
    <slot></slot>
  </v-btn>
</template>
<script lang="ts">
  import Vue from 'vue';
  import VBtn from 'vuetify/es5/components/VBtn';
  export default Vue.extend({
    name: 'Btn',
    extends: VBtn,
  });
</script>
