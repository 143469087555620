import { GetterTree } from 'vuex';
import { UiState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<UiState, RootState> = {
  sidebarCollapse(state): boolean {
    return state.sidebarCollapsed;
  },
  toastVisible(state): boolean {
    return state.toast.visible;
  },
  toastContent(state): string {
    return state.toast.content;
  },
};
