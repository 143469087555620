<template>
  <div>
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default Vue.extend({
    data: () => ({}),
  });
</script>
