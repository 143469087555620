import { MutationTree } from 'vuex';
import { GlobalClient, GlobalState } from './types';

export enum GlobalMutations {
  SET_CLIENTS = 'SET_CLIENTS',
}

export const mutations: MutationTree<GlobalState> = {
  [GlobalMutations.SET_CLIENTS](state, clients: GlobalClient[]) {
    state.clients = clients;
  },
};
