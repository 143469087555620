<template>
  <!-- eslint-disable vue/valid-v-slot -->
  <div class="dashboard-view">
    <app-bar>
      Digital Programs Users
      <template #actions>
        <nav-action-primary data-testid="add-user-btn" @click="createUser()">Add new user</nav-action-primary>
      </template>
    </app-bar>
    <v-container class="pt-0" fluid>
      <card class="overflow-hidden">
        <template #title>All Users</template>
        <v-sheet v-if="users">
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-subheader class="px-0">Search</v-subheader>
                <search
                  dense
                  placeholder="Organization or school name"
                  v-model="search"
                  @submit="submitSearch"
                ></search>
              </v-col>
              <v-col v-if="user.roleId < roles.CLIENT_ADMIN" cols="3">
                <v-subheader class="px-0">Role</v-subheader>
                <v-select
                  outlined
                  dense
                  hide-details
                  :items="roleFilterOptions"
                  v-model="filters.roleId"
                  @change="refreshList"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            height="100%"
            hide-default-footer
            :page="page"
            :items-per-page="10"
            :headers="headers"
            :items="users"
            data-testid="user-list"
          >
            <template #item.org="{ item }">
              <v-avatar size="24" tile class="mr-2">
                <template v-if="item.clientId">
                  <v-img :src="item.client.logoUrl" :alt="item.client.name"></v-img>
                </template>
                <template v-else></template>
              </v-avatar>
              {{ item.clientId ? item.client.name : '' }}
            </template>
            <template #item.role="{ item }">
              <v-sheet class="py-2" width="160" color="transparent">
                <select-colored
                  header="Roles"
                  v-model="item.roleId"
                  :items="rolesOptions"
                  :menu-props="{ maxHeight: 220, minWidth: 370 }"
                  :disabled="item.isDeleted"
                ></select-colored>
              </v-sheet>
            </template>
            <template #item.status="{ item }">
              <span v-if="!item.isDeleted">{{ item.isActive ? 'Active' : 'Inactive' }}</span>
              <span v-else>Deleted</span>
            </template>
            <template #item.edit="{ item }">
              <v-menu min-width="326" v-if="!item.isDeleted" class="elevation-2">
                <template #activator="{ on, attrs }">
                  <v-btn icon color="grey lighten-1" data-testid="user-actions" v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list class="py-0">
                  <v-subheader class="text-uppercase"> Actions </v-subheader>
                  <v-list-item data-testid="edit-user" @click="editUser(item.id)">
                    <v-list-item-content>
                      <v-list-item-title>Edit User</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="confirmDelete(item.id)">
                    <v-list-item-content>
                      <v-list-item-title>Delete User</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!item.isActive" @click="changeUserStatus(true, item.id)">
                    <v-list-item-content>
                      <v-list-item-title>Activate User</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else @click="changeUserStatus(false, item.id)">
                    <v-list-item-content>
                      <v-list-item-title>Deactivate</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template #footer>
              <div class="v-data-footer">
                <v-spacer></v-spacer>

                <pagination
                  class="my-3"
                  align="end"
                  v-model="options.page"
                  total-visible="10"
                  :length="pages"
                ></pagination>
              </div>
            </template>
          </v-data-table>
        </v-sheet>
        <v-sheet
          min-height="400"
          class="fill-height text-center text--disabled d-flex flex-column align-center justify-center"
          v-else
        >
          <div>
            <v-icon color="secondary lighten-4" size="52">mdi-account-outline</v-icon>
            <p class="mt-4 mb-2 text-h6 font-weight-medium">You haven't added any users yet.</p>
            <p class="mb-0 text-body-2">Click the button in the upper right corner to add users.</p>
          </div>
        </v-sheet>
        <v-sheet
          v-if="loading"
          min-height="400"
          class="fill-height text-center d-flex flex-column align-center justify-center"
          color="transparent"
        >
          <v-progress-circular indeterminate size="48"></v-progress-circular>
        </v-sheet>
      </card>
    </v-container>
    <create-user :selectedUser="selectedUser" :edit="userEdit" v-model="modalOpen" v-if="modalOpen" />
    <confirm-dialog
      v-model="modalDeleteOpen"
      @confirm="deleteUserAction"
      message="Are you sure you want to delete this user?"
      v-if="modalDeleteOpen"
    />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { AppBar, NavActionPrimary, Card, Pagination, Search, SelectColored, ConfirmDialog } from '@/components';
  import { CreateUser } from '@/components/Users';
  import { mapGetters, mapActions } from 'vuex';
  import { USER_ROLES, ROLES } from '@/constants/users';

  export default Vue.extend({
    components: {
      AppBar,
      Card,
      NavActionPrimary,
      Pagination,
      Search,
      SelectColored,
      CreateUser,
      ConfirmDialog,
    },
    name: 'Dashboard',
    data() {
      const hClass = 'grey--text text--darken-1 font-weight-regular text-body-2';
      return {
        page: 1,
        headers: [
          { text: 'Organization', value: 'org', sortable: false, class: hClass },
          { text: 'Username', value: 'name', sortable: false, class: hClass },
          { text: 'Role', value: 'role', sortable: false, class: hClass },
          { text: 'Status', value: 'status', sortable: false, class: hClass },
          { text: 'Edit', value: 'edit', width: 48, align: 'center', sortable: false, class: hClass },
        ],
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        roles: ROLES,
        rolesOptions: USER_ROLES,
        modalOpen: false,
        modalDeleteOpen: false,
        selectedUser: {},
        selectedUserId: null,
        userEdit: false,
        loading: false,
        filters: {
          keyword: '',
          roleId: null as number,
        },
        search: '',
      };
    },
    computed: {
      ...mapGetters('users', { users: 'getUsers', pages: 'getPages' }),
      ...mapGetters('user', { user: 'getUser' }),
      roleFilterOptions(): any[] {
        return this.rolesOptions.filter((role) => role.value >= this.user.roleId);
      },
      length(): number {
        return Math.ceil(this.users.items.length / 10);
      },
    },
    methods: {
      ...mapActions('users', {
        fetchUsers: 'fetchUsers',
        fetchUser: 'fetchUser',
        changeStatus: 'changeStatus',
        deleteUser: 'deleteUser',
      }),
      ...mapActions('ui', { showToast: 'showToast' }),

      async editUser(userId: number) {
        const user = await this.fetchUser(userId);
        this.selectedUser = user;
        this.userEdit = true;
        this.modalOpen = true;
      },
      async changeUserStatus(userStatus: boolean, userId: number) {
        try {
          const payload = {
            status: userStatus,
            userId: userId,
          };
          await this.changeStatus(payload);
          this.refreshList();
          this.showToast('Status updated successfully');
        } catch (error) {
          this.showToast(error.response.data);
        }
      },
      confirmDelete(userId: number) {
        this.selectedUserId = userId;
        this.modalDeleteOpen = true;
      },
      async deleteUserAction() {
        try {
          await this.deleteUser(this.selectedUserId);
          this.refreshList();
          this.showToast('User deleted successfully');
        } catch (error) {
          this.showToast(error.response.data);
        }
      },
      async refreshList() {
        this.loading = true;
        try {
          let { filters } = this;
          const { roleId } = filters;
          const keyword = filters.keyword.trim() || undefined;
          const PageSize = this.options.itemsPerPage;
          let { page: PageIndex } = this.options;
          await this.fetchUsers({ PageIndex, PageSize, keyword, roleId });
        } catch (error) {
          this.showToast('Could not fetch users list');
          // console.warn(error);
        }
        this.loading = false;
      },
      submitSearch() {
        const { search } = this;
        this.filters.keyword = search;
        this.options.page = 1;
        this.refreshList();
      },
      createUser() {
        this.userEdit = false;
        this.modalOpen = !this.modalOpen;
      },
    },
    async created() {
      try {
        this.refreshList();
      } catch (error) {
        // console.warn('Could not fetch users', error);
      }
    },
    watch: {
      options: {
        handler() {
          this.refreshList();
        },
        deep: true,
      },
      search(newVal: string) {
        if (newVal === '') {
          this.filters.keyword = '';
          this.options.page = 1;
          this.refreshList();
        }
      },
    },
  });
</script>
