<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-dialog :value="value" @input="$emit('input', $event)" fullscreen persistent scrollable>
    <v-card>
      <v-card-title class="px-4 pt-4 gap-2 elevation-5" style="z-index: 10">
        Roster
        <v-spacer></v-spacer>
        <v-btn depressed class="text-capitalize" @click="onRemove" :disabled="!selectedPlayers.length">Remove</v-btn>
        <v-btn color="primary" depressed class="text-capitalize" @click="onAdd">Add Player</v-btn>
      </v-card-title>
      <!-- :sort-by="sort" -->
      <v-card-text class="pa-0">
        <v-data-table
          class="roster-table"
          disable-sort
          disable-filtering
          disable-pagination
          hide-default-footer
          :headers="headers"
          :items="players"
          item-key="clientRosterPlayerId"
          v-model="selected"
          show-select
          :options.sync="options"
          @toggle-select-all="selectAllItems"
        >
          <template v-slot:body="{ items }">
            <draggable v-model="players" item-key="name" tag="tbody" ghost-class="ghost">
              <template>
                <tr v-for="(item, index) in items" :key="item.id">
                  <td>
                    <v-simple-checkbox v-model="items[index].isSelected"></v-simple-checkbox>
                  </td>
                  <td>
                    <v-edit-dialog
                      :return-value.sync="item.rosterHeadshot"
                      large
                      persistent
                      @save="save"
                      @cancel="cancel"
                    >
                      <v-img
                        height="48"
                        width="48"
                        :alt="item?.rosterHeadshotAltText || 'Player Headshot'"
                        :src="item?.rosterHeadshot || '/default-img.png'"
                        class="rounded-sm"
                      />
                      <template v-slot:input>
                        <div class="py-4">
                          <upload-image
                            :value="item?.rosterHeadshot"
                            height="200"
                            width="200"
                            :fileUrl="item.rosterHeadshot"
                            @input="items[index].rosterHeadshot = $event"
                            key="2"
                            class="mb-5 mt-2"
                          />
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.jerseyNumber">
                      <span v-if="item.jerseyNumber" :id="`${item.clientRosterPlayerId}-0`">{{
                        item.jerseyNumber
                      }}</span>
                      <span v-else class="text--disabled" :id="`${item.clientRosterPlayerId}-0`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.jerseyNumber"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.clientRosterPlayerId, 0)"
                            @keydown.shift.tab.prevent="
                              handleShiftTabPress($event.target.id, item.clientRosterPlayerId, 0)
                            "
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.firstName">
                      <span v-if="item.firstName" :id="`${item.clientRosterPlayerId}-1`">{{ item.firstName }}</span>
                      <span v-else class="text--disabled" :id="`${item.clientRosterPlayerId}-1`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.firstName"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.clientRosterPlayerId, 1)"
                            @keydown.shift.tab.prevent="
                              handleShiftTabPress($event.target.id, item.clientRosterPlayerId, 1)
                            "
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.lastName">
                      <span v-if="item.lastName" :id="`${item.clientRosterPlayerId}-2`">{{ item.lastName }}</span>
                      <span v-else class="text--disabled" :id="`${item.clientRosterPlayerId}-2`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.lastName"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.clientRosterPlayerId, 2)"
                            @keydown.shift.tab.prevent="
                              handleShiftTabPress($event.target.id, item.clientRosterPlayerId, 2)
                            "
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.position">
                      <span v-if="item.position" :id="`${item.clientRosterPlayerId}-3`">{{ item.position }}</span>
                      <span v-else class="text--disabled" :id="`${item.clientRosterPlayerId}-3`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.position"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.clientRosterPlayerId, 3)"
                            @keydown.shift.tab.prevent="
                              handleShiftTabPress($event.target.id, item.clientRosterPlayerId, 3)
                            "
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.class">
                      <span v-if="item.class" :id="`${item.clientRosterPlayerId}-4`">{{ item.class }}</span>
                      <span v-else class="text--disabled" :id="`${item.clientRosterPlayerId}-4`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.class"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.clientRosterPlayerId, 4)"
                            @keydown.shift.tab.prevent="
                              handleShiftTabPress($event.target.id, item.clientRosterPlayerId, 4)
                            "
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.height">
                      <span v-if="item.height" :id="`${item.clientRosterPlayerId}-5`">{{ item.height }}</span>
                      <span v-else class="text--disabled" :id="`${item.clientRosterPlayerId}-5`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.height"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.clientRosterPlayerId, 5)"
                            @keydown.shift.tab.prevent="
                              handleShiftTabPress($event.target.id, item.clientRosterPlayerId, 5)
                            "
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.weight">
                      <span v-if="item.weight" :id="`${item.clientRosterPlayerId}-6`">{{ item.weight }}</span>
                      <span v-else class="text--disabled" :id="`${item.clientRosterPlayerId}-6`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.weight"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.clientRosterPlayerId, 6)"
                            @keydown.shift.tab.prevent="
                              handleShiftTabPress($event.target.id, item.clientRosterPlayerId, 6)
                            "
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.hometown">
                      <span v-if="item.hometown" :id="`${item.clientRosterPlayerId}-7`">{{ item.hometown }}</span>
                      <span v-else class="text--disabled" :id="`${item.clientRosterPlayerId}-7`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.hometown"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.clientRosterPlayerId, 7)"
                            @keydown.shift.tab.prevent="
                              handleShiftTabPress($event.target.id, item.clientRosterPlayerId, 7)
                            "
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.highSchool">
                      <span v-if="item.highSchool" :id="`${item.clientRosterPlayerId}-8`">{{ item.highSchool }}</span>
                      <span v-else class="text--disabled" :id="`${item.clientRosterPlayerId}-8`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.highSchool"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.clientRosterPlayerId, 8)"
                            @keydown.shift.tab.prevent="
                              handleShiftTabPress($event.target.id, item.clientRosterPlayerId, 8)
                            "
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog large :return-value.sync="item.bio">
                      <v-btn icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <template v-slot:input>
                        <v-sheet class="pt-4" width="540">
                          <html-text-editor :htmlContent="item.bio" :contentId="index.toString()" />
                        </v-sheet>
                      </template>
                    </v-edit-dialog>
                  </td>
                </tr>
              </template>
            </draggable>
          </template>
          <template v-slot:item.data-table-select="{ isSelected, select }">
            <v-simple-checkbox color="green" :value="isSelected" @input="select($event)"></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="pa-4 elevation-5" style="z-index: 10">
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="primary" @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { cloneDeep, uniqueId } from 'lodash-es';
  import { PlayerDetails } from '@/store/client/types';
  import { UploadImage } from '@/components';
  import { mixins } from '../../mixin/mixins';
  import draggable from 'vuedraggable';
  import HtmlTextEditor from './HtmlTextEditor.vue';

  export default Vue.extend({
    name: 'RosterEditModal',
    mixins: [mixins],
    components: {
      UploadImage,
      draggable,
      HtmlTextEditor,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      data: {
        type: Array as PropType<PlayerDetails[]>,
        required: true,
      },
    },
    provide(): any {
      return {
        setHtml: this.setHtml,
      };
    },
    data() {
      return {
        // sort: null,
        options: null,
        headers: [
          { text: 'Image', value: 'rosterHeadshot', sortable: false },
          { text: '#', value: 'jerseyNumber' },
          { text: 'First Name', value: 'firstName' },
          { text: 'Last Name', value: 'lastName' },
          { text: 'Pos.', value: 'position' },
          { text: 'Class', value: 'class' },
          { text: 'Ht.', value: 'height' },
          { text: 'Wt.', value: 'weight' },
          { text: 'Hometown', value: 'hometown' },
          { text: 'High School', value: 'highSchool' },
          { text: 'Bio', value: 'bio', cellClass: 'custom-cell' },
        ],
        items: [] as PlayerDetails[],
        selected: [] as PlayerDetails[],
        players: [] as PlayerDetails[],
      };
    },
    methods: {
      handleTabPress(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.moveNextElementModal(this.items, id, order, 'clientRosterPlayerId');
      },
      handleShiftTabPress(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.movePreviousElementModal(this.items, id, order, 'clientRosterPlayerId');
      },
      save() {
        // console.log('Dialog Saved');
      },
      cancel() {
        // console.log('Dialog cancel');
      },
      open() {
        // console.log('Dialog open');
      },
      genId(): number {
        let id = parseInt(uniqueId());
        while (this.players.some((item) => item.clientRosterPlayerId === id)) {
          id = parseInt(uniqueId());
        }
        return id;
      },
      onAdd() {
        this.players.push({
          clientRosterPlayerId: this.genId(),
          firstName: null,
          lastName: null,
          jerseyNumber: null,
          hometown: null,
          highSchool: null,
          position: null,
          height: null,
          weight: null,
          class: null,
          bio: null,
          isCaptain: false,
          previousSchool: null,
          rosterHeadshot: null,
          rosterHeadshotAltText: null,
          actionShot: null,
          actionShotAltText: null,
        });
      },
      onRemove() {
        const idsToRemove = this.selectedPlayers.map((item) => item.clientRosterPlayerId);
        this.players = this.players.filter((item) => !idsToRemove.includes(item.clientRosterPlayerId));
      },
      setHtml(html: string, contentId: string) {
        const index = +contentId;
        this.players[index].bio = html;
      },
      onSave() {
        this.players.map((c) => {
          delete c?.isSelected;
          return c;
        });
        this.$emit('submit', this.players);
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
      async selectAllItems({ value }: { items: PlayerDetails[]; value: boolean }) {
        await this.$nextTick();
        this.players = this.players.map((item) => {
          return {
            ...item,
            isSelected: value,
          };
        });
      },
    },
    computed: {
      selectedPlayers(): PlayerDetails[] {
        return this.players.filter((item: PlayerDetails) => item.isSelected);
      },
    },
    watch: {
      value(visible: boolean) {
        if (visible) {
          this.players = cloneDeep(this.data).map((el) => {
            return {
              ...el,
              isSelected: false,
            };
          });
          this.selected = [];
        }
        //   if (visible) this.items = sortBy(cloneDeep(this.data), ['jerseyNumber']);
        // if (visible)
        //   this.items = cloneDeep(this.data).sort(function (a, b) {
        //     const jerseyNumberA = typeof a.jerseyNumber === 'number' ? a.jerseyNumber.toString() : a.jerseyNumber || '';
        //     const jerseyNumberB = typeof b.jerseyNumber === 'number' ? b.jerseyNumber.toString() : b.jerseyNumber || '';
        //     const jersey = jerseyNumberA.localeCompare(jerseyNumberB, undefined, {
        //       numeric: true,
        //       sensitivity: 'base',
        //     });
        //     const lastNameA = a.lastName || '';
        //     const lastNameB = b.lastName || '';
        //     const lastName = lastNameA.localeCompare(lastNameB, undefined, {
        //       numeric: true,
        //       sensitivity: 'base',
        //     });
        //     return jersey || lastName;
        //   });
      },
      // options: {
      //   deep: true,
      //   handler() {
      // console.log(this.options?.sortBy[0], this.options?.sortDesc[0]);
      // if (Object.keys(this.options).length !== 0) {
      //   this.sort = this.options.sortBy[0];
      //   if (this.options?.sortDesc[0]) this.sort = this.sort + ':desc';
      // }
      //   },
      // },
    },
  });
</script>
<style lang="scss">
  .cell-truncate {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-height: 1.5rem;
  }

  .roster-table {
    .v-data-table__wrapper {
      padding: 16px;
    }
    .custom-cell {
      max-width: 100px;

      .v-small-dialog__activator {
        @extend .cell-truncate;

        .v-small-dialog__activator__content {
          @extend .cell-truncate;
          display: block;
        }
      }
    }
  }
</style>
