<template>
  <v-text-field
    v-bind="$props"
    v-on="$listeners"
    prepend-inner-icon="mdi-magnify"
    class="search-field rounded-r-0"
    hide-details
    outlined
  >
    <template #append-outer>
      <btn color="primary" :disabled="value.length < 3" class="search-btn rounded-l-0" @click="$emit('submit')">
        {{ btnText }}
      </btn>
    </template>
  </v-text-field>
</template>
<script lang="ts">
  import Vue from 'vue';
  import VTextField from 'vuetify/es5/components/VTextField';
  import Btn from './Btn.vue';
  export default Vue.extend({
    extends: VTextField,
    name: 'Search',
    components: { Btn },
    props: {
      btnText: { type: String, default: 'search' },
      value: { type: String },
    },
  });
</script>
<style lang="sass">
  .search-field.v-text-field .v-input__append-outer
    margin: 0 !important

  .search-field
    & .v-btn.search-btn
      height: 56px

    &.v-input--dense .v-btn.search-btn
      height: 40px
</style>
