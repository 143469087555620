<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title class="text-h6">{{ config.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Edit</v-btn>
    </v-toolbar>
    <v-card-text class="pt-0">
      <label for="text-area">{{ config.label }}</label>
      <v-textarea
        :placeholder="config.placeholder"
        readonly
        no-resize
        class="mt-2"
        name="text-area"
        outlined
        hide-details
        dense
        :value="preview"
      ></v-textarea>
    </v-card-text>
    <text-area-modal
      :title="config.title"
      :label="config.label"
      :placeholder="config.placeholder"
      v-model="modal"
      :data="value"
      @submit="$emit('input', $event)"
    />
  </v-card>
</template>
<script lang="ts">
  import Vue from 'vue';
  import TextAreaModal from './TextAreaModal.vue';
  export default Vue.extend({
    name: 'TextAreaBlock',
    components: {
      TextAreaModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({
          time: Date.now(),
          blocks: [] as any[],
        }),
      },
    },
    data() {
      return {
        modal: false,
      };
    },
    computed: {
      preview(): string {
        return this.value.blocks.map((block: any) => block.data.text).join('\n');
      },
    },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
    },
  });
</script>
