export const mixins = {
  methods: {
    moveNextElementModal(listTable: any, id: number, order: number, propIndex = 'id') {
      const currentElById: HTMLElement = document.getElementById(`${id}-${order}`);
      currentElById.click();
      let nextElByIndex: HTMLElement = document.getElementById(`${id}-${++order}`);
      if (!nextElByIndex) {
        const indexEl: number = listTable.findIndex((item: any) => item[propIndex] === id);
        if (indexEl + 1 < listTable.length) {
          id = listTable[indexEl + 1][propIndex];
          nextElByIndex = document.getElementById(`${id}-${0}`);
        }
      }
      if (nextElByIndex) {
        nextElByIndex.parentElement.click();
      }
    },
    movePreviousElementModal(listTable: any, id: number, order: number, propIndex = 'id') {
      const currentElById: HTMLElement = document.getElementById(`${id}-${order}`);
      currentElById.click();
      let previuosElByIndex: HTMLElement = document.getElementById(`${id}-${--order}`);
      if (!previuosElByIndex) {
        const indexEl: number = listTable.findIndex((item: any) => item[propIndex] === id);
        id = listTable[indexEl - 1][propIndex];
        previuosElByIndex = document.getElementById(`${id}-${0}`);
      }
      if (previuosElByIndex) {
        previuosElByIndex.parentElement.click();
      }
    },
    triggerEnterKeyPressEvent(id: string) {
      const inputEl: HTMLElement = document.getElementById(id);
      // Create a new keyboard event with the key "Enter"
      const enterKeyPressEvent: KeyboardEvent = new KeyboardEvent('keydown', {
        key: 'Enter',
        keyCode: 13,
        code: 'Enter',
        which: 13,
        bubbles: true,
        cancelable: true,
      });
      // Dispatch the event to simulate the Enter key press
      inputEl.dispatchEvent(enterKeyPressEvent);
    },
  },
};
