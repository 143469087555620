import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import { Smartphone, Tablet, Desktop } from '@/components/Icons';
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.shades.black,
        error: '#CE2D35',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
    options: { customProperties: true },
  },
  icons: {
    values: {
      smartphone: {
        component: Smartphone,
      },
      tablet: {
        component: Tablet,
      },
      desktop: {
        component: Desktop,
      },
    },
  },
});
