<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title class="text-h6">Landing Page Options</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="px-6 py-0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-radio-group v-model="formData.isCover" row class="pt-0 mt-0">
          <!--<v-radio label="Countdown View" :value="false"></v-radio>-->
          <v-radio label="Full Page Cover Image" :value="true"></v-radio>
        </v-radio-group>
        <label class="grey--text text--darken-1">Cover image</label>
        <upload-image
          :value="value.image.url"
          @input="formData.image.url = $event"
          :fileUrl="value.image.url"
          :clientId="0"
          :requiredHeight="formData.isCover ? null : 1920"
          :requiredWidth="formData.isCover ? null : 1600"
          class="mb-5 mt-2"
          allow-pdf
        />
        <label class="grey--text text--darken-1">Alt Text</label>
        <v-text-field
          class="mt-2"
          autofocus
          autocomplete="off"
          dense
          name="text-field"
          outlined
          v-model="formData.image.alt"
          required
          :rules="altRules"
        ></v-text-field>

        <label class="grey--text text--darken-1">Image URL Redirect Link</label>
        <v-text-field
          class="mt-2"
          autocomplete="off"
          dense
          name="text-field"
          outlined
          v-model="formData.image.link"
        ></v-text-field>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
  import { UploadImage } from '@/components';

  import Vue from 'vue';
  const LandingPage = Vue.extend({
    name: 'LandingPage',
    components: {
      UploadImage,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        valid: true,
        altRules: [
          (v: string) => {
            if (this.$data.formData.image.url == null || this.$data.formData.image.url == '') return true;
            return !!v || 'Alt Text is required';
          },
        ],
        formData: {
          image: {
            url: this.value.image.url || null,
            link: this.value.image.link || null,
            alt: this.value.image.alt || null,
          },
          isCover: true, // set as true temporally DIGPROGRAM-1255
        },
      };
    },
    methods: {
      validate() {
        return this.form.validate();
      },
    },
    computed: {
      form(): VForm {
        return this.$refs.form as VForm;
      },
    },
    watch: {
      formData: {
        handler: function (data) {
          this.$emit('input', data);
        },
        deep: true,
      },
    },
  });
  export type LandingPageRef = InstanceType<typeof LandingPage>;
  export default LandingPage;
  export type VForm = Vue & { validate: () => boolean };
</script>
