import config from '@/constants/config';
import store from '@/store';
import axios, { AxiosRequestConfig } from 'axios';
import router from '@/router';

const API = axios.create({ baseURL: config.apiUrl });

API.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = store.getters['user/getAuthToken'];
  if (token) config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch('user/setLoggedOut');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

export const connectionToSideArmApi = (tenantId: string) => {
  return axios.create({
    baseURL: config.sidearmApiUrl,
    headers: {
      tenant: tenantId,
      'X-Api-Key': config.apiKey,
    },
  });
};
export const connectionToTenantApi = (url: string) => {
  return axios.create({
    baseURL: url,
  });
};
export default API;
