export const parseHtmlAndReplaceUrls = (html: string, baseUrl: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const elementsWithUrls = doc.querySelectorAll('a, img');

  elementsWithUrls.forEach((element) => {
    const attr = element.tagName === 'A' ? 'href' : 'src';
    const url = element.getAttribute(attr);

    if (url && !url.startsWith('http') && !url.startsWith('//')) {
      element.setAttribute(attr, new URL(url, baseUrl).href);
    }
  });

  return doc.documentElement.innerHTML;
};
