<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="sidebarCollapse"
    class="grey darken-4"
    dark
    app
    mini-variant-width="68"
    disable-resize-watcher
    mobile-breakpoint="0"
    touchless
  >
    <v-list class="py-4">
      <v-list-item class="justify-center">
        <v-list-item-avatar color="white" size="48" class="ma-0">
          <v-img :src="avatarURL"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item v-if="!sidebarCollapse">
        <v-list-item-content class="text-center pt-4 pb-2">
          <v-list-item-title class="subtitle-2 mb-2">
            {{ displayName }}
          </v-list-item-title>
          <v-list-item-subtitle class="caption grey--text text--lighten-1">
            {{ user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>

    <v-list class="pt-0">
      <v-list-item-group active-class="active-item" color="grey lighten-4" role="navigation">
        <v-list-item v-for="item in items" :key="item.title" link :to="item.route" :aria-label="item.title">
          <template v-slot:default="{ active }">
            <v-list-item-icon>
              <v-icon :color="active ? 'white' : 'grey darken-1'">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :class="active ? 'white--text' : 'grey--text'">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <v-list dense role="button" :aria-expanded="!sidebarCollapse ? 'true' : 'false'">
        <v-list-item @click.stop="collapse()">
          <v-list-item-icon>
            <v-icon>
              {{ !sidebarCollapse ? 'mdi-toggle-switch-outline' : 'mdi-toggle-switch-off-outline' }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Collapse Sidebar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import { ROLES } from '@/constants/users';

  export default Vue.extend({
    name: 'Sidebar',
    computed: {
      ...mapGetters('ui', ['sidebarCollapse']),
      ...mapGetters('user', { user: 'getUser' }),
      isSuperAdmin(): boolean {
        return this.user.roleId === ROLES.SUPER_ADMIN;
      },
      isLearfieldAdmin(): boolean {
        return this.user.roleId === ROLES.LEARFIELD_ADMIN;
      },
      avatarURL(): string {
        if (this.isSuperAdmin) return '/sidearm-logo.png';
        if (this.isLearfieldAdmin) return '/learfield-logo.png';
        return this.user.client.logoUrl;
      },
      displayName(): string {
        if (this.isSuperAdmin) return 'SIDEARM Admin';
        if (this.isLearfieldAdmin) return 'Learfield Admin';
        return this.user.client.name;
      },
    },
    data() {
      return {
        drawer: true,
        items: [],
        mini: true,
      };
    },
    methods: {
      ...mapActions('ui', ['toggleSidebar']),
      collapse() {
        this.toggleSidebar(!this.sidebarCollapse);
      },
    },
    mounted() {
      const role = this.$store.getters['user/getUserRole'];

      this.items = [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', route: { name: 'dashboard' } },
        ...(role !== ROLES.CLIENT_ADMIN
          ? [
              {
                title: 'Clients',
                icon: 'mdi-account-multiple-outline',
                route: { name: 'clients' },
              },
            ]
          : []),

        { title: 'Users', icon: 'mdi-account-outline', route: { name: 'users' } },
        { title: 'Logout', icon: 'mdi-logout', route: { name: 'logout' } },
      ];
    },
  });
</script>
<style lang="sass" scoped>
  .active-item
    border-left: 3px solid #fafafa
    &:before
      opacity: 0.08
</style>
