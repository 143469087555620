import { ActionTree } from 'vuex';
import { Clients, ClientState } from './types';
import { RootState } from '../types';
import { ClientMutations } from './mutations';
import API from '@/services/api/api';

export const actions: ActionTree<Clients, RootState> = {
  async fetchClients({ commit }, params) {
    try {
      const {
        data: { pages, items },
      } = await API.get('/clients', { params: { PageSize: 9999, ...params } });
      commit(ClientMutations.SET_CLIENTS, items);
      commit(ClientMutations.SET_PAGES, pages);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createClient({ dispatch }, client: ClientState) {
    try {
      await API.post('/clients', client);
      dispatch('fetchClients');
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateClient({ dispatch }, client: ClientState) {
    try {
      await API.put(`/clients/${client.id}`, client);
      dispatch('fetchClients');
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setViewType({ commit }, value): void {
    commit(ClientMutations.SET_VIEW_TYPE, value);
  },
};
