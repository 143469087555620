import { ActionTree } from 'vuex';
import { Users, UsersState } from './types';
import { RootState } from '../types';
import { UsersMutations } from './mutations';
import API from '@/services/api/api';

export const actions: ActionTree<UsersState, RootState> = {
  async fetchUsers({ commit }, params) {
    try {
      const {
        data: { pages, items },
      } = await API.get('/users', { params: { PageIndex: 1, PageSize: 15, ...params } });
      commit(UsersMutations.SET_USERS, items);
      commit(UsersMutations.SET_PAGES, pages);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchUser({ dispatch }, userId: string) {
    try {
      const response = await API.get(`/users/${userId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createUser({ dispatch }, user: Users) {
    try {
      await API.post('/users', user);
      dispatch('fetchUsers');
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateUser({ dispatch }, user: Users) {
    try {
      await API.put(`/users/${user.id}`, user);
      dispatch('fetchUsers');
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteUser({ dispatch }, userId: string) {
    try {
      await API.delete(`/users/${userId}`);
      dispatch('fetchUsers');
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async changeStatus({ dispatch }, payload: any) {
    try {
      await API.put(`/users/${payload.status ? 'activate' : 'deactivate'}/${payload.userId}`);
      dispatch('fetchUsers');
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
