<template>
  <v-dialog width="960" :value="value" persistent @input="$emit('input', $event)">
    <v-card rounded="lg">
      <v-card-title class="text-captalize white--text primary py-6">{{
        !edit ? 'Add New User' : 'Edit user'
      }}</v-card-title>
      <v-card-text class="pt-6 px-6">
        <v-sheet>
          <v-form lazy-validation :disabled="submittingForm" @submit.prevent="submitForm" v-model="valid" ref="form">
            <label class="grey--text text--darken-1 login-lbl">User Name</label>
            <v-text-field
              v-model="formData.name"
              class="login-input mt-2 mb-5"
              :rules="nameRules"
              data-testid="user-name"
              ref="userName"
              required
              dense
              outlined
              hide-details
            ></v-text-field>

            <label class="grey--text text--darken-1 login-lbl">User Email</label>
            <v-text-field
              v-model="formData.email"
              class="login-input mt-2 mb-5"
              :rules="emailRules"
              data-testid="user-email"
              ref="userEmail"
              required
              dense
              outlined
              hide-details
            ></v-text-field>

            <template v-if="role !== ROLES.CLIENT_ADMIN">
              <label class="grey--text text--darken-1 login-lbl">User Role</label>

              <v-select
                hide-details
                class="mb-5 mt-2"
                v-model="formData.roleId"
                :rules="roleRules"
                ref="userRole"
                outlined
                dense
                :items="role === ROLES.SUPER_ADMIN ? roles : adminRoles"
                item-text="label"
                item-value="value"
              ></v-select>
            </template>

            <template v-if="formData.roleId === ROLES.CLIENT_ADMIN">
              <label class="grey--text text--darken-1 login-lbl">Select School</label>

              <v-autocomplete
                class="mb-5 mt-2"
                data-testid="school-input"
                hide-details
                :items="clients"
                item-text="name"
                item-value="id"
                :rules="schoolRules"
                :search-input.sync="search"
                v-model="formData.clientId"
                no-data-text="No Results Found"
                :menu-props="{ closeOnClick: true }"
                @blur="search = null"
                outlined
                dense
                :hide-no-data="loadingClients"
                :loading="loadingClients"
              >
                <template #prepend-item>
                  <v-subheader class="text-body-1">Select School</v-subheader>
                </template>
                <template #item="{ on, attrs, item }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-avatar>
                      <v-img :src="item.logoUrl" :alt="item.name"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template #selection="{ item }">
                  <v-avatar size="25" class="mx-4">
                    <v-img :src="item.logoUrl" :alt="item.name"></v-img>
                  </v-avatar>
                  {{ item.name }}
                </template>
              </v-autocomplete>
            </template>
            <label class="grey--text text--darken-1 login-lbl">Temporary User Password</label>

            <v-text-field
              v-model="formData.password"
              class="login-input mt-2 mb-5"
              :rules="!edit ? passwordRules : []"
              data-testid="user-password"
              ref="userPass"
              type="password"
              required
              dense
              outlined
              hide-details
            ></v-text-field>

            <v-card-actions class="px-0 pb-6">
              <btn type="submit" v-if="filled" :disabled="!filled || submittingForm" color="primary">{{
                !edit ? 'Add' : 'Update'
              }}</btn>
              <btn @click="closeModal" :disabled="submittingForm" :text="filled" color="primary"> Cancel </btn>
            </v-card-actions>
          </v-form>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Btn } from '@/components';
  import { VForm } from '../../types';
  import { mapGetters, mapActions } from 'vuex';
  import { ROLES } from '@/constants/users';

  export default Vue.extend({
    components: { Btn },
    props: {
      value: { type: Boolean, default: false },
      selectedUser: { type: Object, required: false },
      edit: { type: Boolean, default: false },
    },
    computed: {
      ...mapGetters('client', { clients: 'getClients' }),

      form(): VForm {
        return this.$refs.form as VForm;
      },
    },
    methods: {
      ...mapActions('client', ['fetchClients']),
      ...mapActions('ui', { showToast: 'showToast' }),
      ...mapActions('users', ['createUser', 'updateUser']),

      async submitForm() {
        this.submittingForm = true;

        if (this.role === ROLES.CLIENT_ADMIN) {
          this.formData.roleId = ROLES.CLIENT_ADMIN;
        }

        try {
          if (!this.edit) {
            await this.createUser(this.formData);
          } else {
            await this.updateUser(this.formData);
          }

          this.handleSuccess();
        } catch (error) {
          const errorMsg = error.response.data ? error.response.data : error.response.message;
          this.handleFailure(errorMsg);
        }
        this.submittingForm = false;
      },
      handleSuccess() {
        this.showToast(`User ${!this.edit ? 'added' : 'updated'} successfully`);
        this.closeModal();
        this.$emit('save');
      },
      handleFailure(msg: string) {
        this.showToast(msg);
        this.added = false;

        this.submittingForm = false;
      },
      resetForm() {
        this.filled = null;
        this.added = false;
        this.formData = {
          name: '',
          email: '',
          clientId: null,
          roleId: null,
          password: '',
        };
      },
      closeModal() {
        this.form.resetValidation();
        this.$emit('input', false);
      },
    },
    watch: {
      value(n) {
        if (n === false) this.resetForm();
      },

      formData: {
        handler: function (data) {
          this.filled = false;
          if (data.name != '' && data.email != '' && data.password != '' && data.roleId) {
            if (this.form.validate()) {
              this.filled = true;
            } else {
              this.filled = false;
            }
          }
        },
        deep: true,
      },
    },
    data() {
      return {
        valid: false,
        filled: null,
        added: false,
        search: '',
        formData: {
          name: '',
          email: '',
          clientId: null,
          roleId: null,
          password: '',
        },
        passwordRules: [(v: string) => !!v || 'Password is required'],
        roleRules: [(v: string) => !!v || 'Role is required'],
        nameRules: [(v: string) => !!v || 'Name is required'],
        schoolRules: [(v: string) => !!v || 'School is required'],
        emailRules: [
          (v: string) => !!v || 'E-mail is required',
          (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        loadingClients: false,
        roles: [
          { label: 'Super Admin', value: ROLES.SUPER_ADMIN },
          { label: 'Learfield Admin', value: ROLES.LEARFIELD_ADMIN },
          { label: 'Client Admin', value: ROLES.CLIENT_ADMIN },
        ],
        adminRoles: [
          { label: 'Learfield Admin', value: ROLES.LEARFIELD_ADMIN },
          { label: 'Client Admin', value: ROLES.CLIENT_ADMIN },
        ],
        submittingForm: false,
        ROLES,
        role: this.$store.getters['user/getUserRole'],
      };
    },
    async created() {
      if (this.selectedUser && this.edit) {
        this.formData = this.selectedUser;
        this.filled = true;
      }

      try {
        this.loadingClients = true;

        //handle auth user role
        if (this.role === ROLES.CLIENT_ADMIN) {
          this.formData.roleId = ROLES.CLIENT_ADMIN;
        }

        await this.fetchClients();
      } catch (error) {
        // console.warn('Could not fetch global clients', error);
      }
      this.loadingClients = false;
    },
  });
</script>
