import { ActionTree } from 'vuex';
import { ChangeStatusPayload, DuplicatePayload, ProgramsState } from './types';
import { RootState } from '../types';
import API from '@/services/api/api';
import { ProgramsMutations } from './mutations';

export const actions: ActionTree<ProgramsState, RootState> = {
  async fetchPrograms({ commit }, params) {
    try {
      const {
        data: { pages, items },
      } = await API.get('/programs', { params: { PageIndex: 1, PageSize: 15, ...params } });
      commit(ProgramsMutations.SET_PROGRAMS, items);
      commit(ProgramsMutations.SET_PAGES, pages);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async changeStatus({ commit }, payload: ChangeStatusPayload) {
    const { id, status, oldStatus } = payload;
    try {
      commit(ProgramsMutations.SET_PROGRAM_STATUS, { id, status });
      await API.put(`/programs/${id}/status`, undefined, { params: { status } });
    } catch (error) {
      commit(ProgramsMutations.SET_PROGRAM_STATUS, { id, status: oldStatus });
      return Promise.reject(error);
    }
  },
  async fetchProgram({ commit }, programId: string) {
    try {
      const { data } = await API.get(`/programs/${programId}`);
      commit(ProgramsMutations.SET_PROGRAM, data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async duplicate(_, payload: DuplicatePayload) {
    try {
      const { id } = payload;
      await API.post(`/programs/${id}/clone`);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
