<template>
  <v-card outlined>
    <v-card-title class="px-4">
      {{ config.title }}
      <v-spacer></v-spacer>
      <div class="d-flex flex-row align-center justify-center">
        <v-select
          v-if="showSetLocationTypeSelect"
          name="template-select"
          class="config mr-2"
          dense
          outlined
          @change="setLocationType"
          :items="locationTypes"
          item-text="title"
          item-value="value"
          hide-details
          v-model="locationType"
        ></v-select>
        <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="toggleModal">Edit</v-btn>
      </div>
    </v-card-title>
    <v-card-subtitle class="px-4 text--secondary">{{ config.subtitle }}</v-card-subtitle>
    <v-card-text class="pt-0">
      <label for="text-field">{{ config.label }}</label>
      <v-row class="mt-2">
        <v-col cols="1">
          <v-img :height="48" :width="48" :src="value.image || '/default-img.png'" class="rounded-sm"></v-img>
        </v-col>
        <v-col>
          <v-text-field
            class="mt-2"
            name="text-field"
            outlined
            hide-details
            dense
            :placeholder="config.placeholderFirstText"
            :value="value.firstText"
            readonly
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="mt-2"
            name="text-field"
            outlined
            hide-details
            dense
            :placeholder="config.placeholderSecondText"
            :value="value.secondText"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="value.thirdText !== undefined && value.fourthText">
        <v-col>
          <v-text-field
            class="mt-2"
            name="text-field"
            outlined
            hide-details
            dense
            :placeholder="config.placeholderThirdText"
            :value="value.thirdText"
            readonly
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="mt-2"
            name="text-field"
            outlined
            hide-details
            dense
            :placeholder="config.placeholderFourthText"
            :value="value.fourthText"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <text-image-modal
      v-if="modal"
      v-model="modal"
      :title="config.title"
      :config="config"
      :data="value"
      :program="program"
      @submit="save"
    ></text-image-modal>
  </v-card>
</template>
<script lang="ts">
  import Vue from 'vue';
  import TextImageModal from './TextImageModal.vue';
  import { mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'TextImageStanding',
    components: {
      TextImageModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
        modalConfig: false,
        dataObject: undefined,
        locationType: 'home',
        locationTypes: [
          {
            value: 'home',
            title: 'Home Team',
          },
          {
            value: 'away',
            title: 'Away Team',
          },
        ],
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
      showSetLocationTypeSelect(): boolean {
        let awayTeamAvailable = this.program.event.away.name !== '';
        return this.config.displayConfig && awayTeamAvailable;
      },
    },
    // created() {
    //   if (this.config.displayConfig) {
    //     this.setLocationType();
    //   }
    // },
    methods: {
      toggleModal() {
        this.modal = !this.modal;
      },
      save(data: any) {
        data.locationType = this.locationType;
        this.$emit('input', data);
      },
      setLocationType() {
        this.dataObject = this.value;

        this.dataObject.image =
          this.locationType === 'home' ? this.program.event.home.logoUrl : this.program.event.away.logoUrl;

        if (
          (this.config.firstTextDefault && this.locationType !== this.dataObject.locationType) ||
          this.dataObject.firstText === ''
        ) {
          const defaultValue = this.config.firstTextDefault;
          this.dataObject.firstText =
            this.locationType === 'home'
              ? this.program.event.home[defaultValue]
              : this.program.event.away[defaultValue];
        }

        if (
          (this.config.secondTextDefault && this.locationType !== this.dataObject.locationType) ||
          this.dataObject.secondText === ''
        ) {
          const defaultValue = this.config.secondTextDefault;
          this.dataObject.secondText =
            this.locationType === 'home'
              ? this.program.event.home[defaultValue]
              : this.program.event.away[defaultValue];
        }

        if (this.dataObject.alt === '') {
          this.dataObject.alt = `${
            this.locationType === 'home' ? this.program.event.home.mascot : this.program.event.away.mascot
          } team logo`;
        }

        this.dataObject.locationType = this.locationType;
        this.$emit('input', this.dataObject);
      },
    },
  });
</script>
<style lang="scss" scoped>
  .config {
    width: 160px;
  }
</style>
