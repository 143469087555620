import { ActionTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '../types';
import { UserMutations } from './mutations';
import auth from '@/services/auth/auth';
import user from '@/services/user/user';
import store from '@/store';

export const actions: ActionTree<UserState, RootState> = {
  setLoggedIn({ commit }): void {
    commit(UserMutations.SET_LOGGED_IN);
  },
  setLoggedOut({ commit }): void {
    commit(UserMutations.SET_LOGGED_OUT);
  },
  setUserData({ commit }, data): void {
    commit(UserMutations.SET_USER_DATA, data);
  },
  setAuthToken({ commit }, value): void {
    commit(UserMutations.SET_AUTH_TOKEN, value);
  },
  async fetchUser({ commit }): Promise<string> {
    try {
      const authUser = await user.getAuthenticatedUser();

      store.dispatch('user/setUserData', authUser);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async login({ commit }, payload): Promise<string> {
    try {
      await auth.authenticate(payload);
      await store.dispatch('user/fetchUser');
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
