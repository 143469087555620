<template>
  <div>
    <sidebar />
    <v-main id="main-content">
      <router-view id="announce" aria-live="polite"></router-view>
    </v-main>
  </div>
</template>

<script>
  import Sidebar from './Sidebar.vue';
  import Vue from 'vue';

  export default Vue.extend({
    components: {
      Sidebar,
    },
    data: () => ({}),
  });
</script>
