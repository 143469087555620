<template>
  <v-dialog persistent width="640" :value="value" @input="$emit('input', $event)">
    <v-form ref="form" @submit.prevent="onSubmit" v-model="isValid" lazy-validation>
      <v-card>
        <v-card-title class="pt-6 pb-4 px-6">{{ title }}</v-card-title>
        <v-card-text class="px-6 py-0">
          <label for="text-field">{{ label }}</label>
          <v-text-field
            class="mt-2"
            autofocus
            autocomplete="off"
            dense
            name="text-field"
            outlined
            v-model="text"
            :rules="[required]"
            :placeholder="placeholder"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="px-6 pb-6 pt-4">
          <v-btn type="submit" class="text-capitalize" color="primary" depressed :disabled="!isValid">Done</v-btn>
          <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
  import { VForm } from '@/types';
  import Vue from 'vue';
  export default Vue.extend({
    name: 'TextAreaModal',
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      data: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        text: '',
        isValid: false,
      };
    },
    methods: {
      required(value: string) {
        return (typeof value === 'string' && !!value.trim()) || 'Field is required';
      },
      onSubmit() {
        const form = this.$refs.form as VForm;
        if (!form.validate()) return;
        this.$emit('submit', this.text);
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
      reset() {
        const form = this.$refs.form as VForm;
        form.reset();
      },
    },
    watch: {
      value(visible: boolean) {
        if (!visible) this.reset();
        else if (this.data) this.text = this.data;
      },
    },
  });
</script>
