import { ActionTree } from 'vuex';
import { UiState } from './types';
import { RootState } from '../types';
import { UiMutations } from './mutations';

export const actions: ActionTree<UiState, RootState> = {
  toggleSidebar({ commit }, value): void {
    commit(UiMutations.TOGGLE_SIDEBAR, value);
  },
  showToast({ commit }, value): void {
    commit(UiMutations.SHOW_TOAST, value);
  },
  hideToast({ commit }): void {
    commit(UiMutations.HIDE_TOAST);
  },
};
