<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <v-card-text class="scrollable">
      <label class="text--secondary">Select players to import</label>
      <v-data-table
        class="elevation-2 mt-2 custom-table"
        :class="{ invalid: !isFormValid }"
        checkbox-color="primary"
        :headers="headers"
        disable-sort
        disable-pagination
        hide-default-footer
        show-select
        fixed-header
        item-key="clientRosterPlayerId"
        :items="players"
        v-model="selected"
        height="360"
        :loading="isLoading"
        @toggle-select-all="selectAllItems"
      >
        <template v-slot:body="{ items }">
          <draggable v-model="players" item-key="name" tag="tbody" ghost-class="ghost">
            <template>
              <tr v-for="(item, index) in items" :key="item.id">
                <td>
                  <v-simple-checkbox v-model="items[index].isSelected"></v-simple-checkbox>
                </td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.lastName }}</td>
                <td>{{ item.position }}</td>
                <td>{{ item.class }}</td>
              </tr>
            </template>
          </draggable>
        </template>
        <template v-slot:item.data-table-select="{ isSelected, select }">
          <v-simple-checkbox color="green" :value="isSelected" @input="select($event)"></v-simple-checkbox>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack" :disabled="isFormDisabled">
        Back
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        depressed
        class="text-capitalize"
        :disabled="!isFormValid || isFormDisabled"
        @click="onSubmit"
      >
        Continue
      </v-btn>
      <span v-if="!isFormValid" class="error--text">Select a least one player</span>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset" :disabled="isFormDisabled">
        Clear
      </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import ClientService from '@/services/client/client';
  import { mapActions, mapGetters } from 'vuex';
  import { PlayerDetails, RosterPlayer } from '@/store/client/types';
  import { sortBy } from 'lodash-es';
  import draggable from 'vuedraggable';
  interface Injections {
    setPageTitle: (title: string) => void;
    setPageContent: (content: any) => void;
    toggleLoading: () => void;
    getPageSport: () => any;
    goNext: () => void;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'RosterImporter',
    inject: [
      'setPageTitle',
      'setPageContent',
      'toggleLoading',
      'getPageSport',
      'getPageEvent',
      'goNext',
      'goBack',
      'close',
    ],
    components: { draggable },
    data() {
      return {
        players: [] as RosterPlayer[],
        selected: [] as RosterPlayer[],
        headers: [
          {
            text: 'First Name',
            align: 'start',
            value: 'firstName',
          },
          {
            text: 'Last Name',
            align: 'start',
            value: 'lastName',
          },
          {
            text: 'Position',
            align: 'start',
            value: 'position',
            width: 100,
          },
          {
            text: 'Class',
            align: 'start',
            value: 'class',
            width: 180,
          },
        ],
        isLoading: false,
        isFormValid: true,
        isFormDisabled: false,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
      selectedPlayers(): RosterPlayer[] {
        return this.players.filter((item: RosterPlayer) => !!item?.isSelected);
      },
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchSport: ClientService.fetchSport,
      fetchRosterPlayers: ClientService.fetchRosterPlayers,
      fetchClient: ClientService.fetchClient,
      fetchPlayers: ClientService.fetchPlayers,
      async selectAllItems({ value }: { items: PlayerDetails[]; value: boolean }) {
        await this.$nextTick();
        this.players = this.players.map((item) => {
          return {
            ...item,
            isSelected: value,
          };
        });
      },
      async resetSelectedItems() {
        this.players = this.players.map((item) => {
          return {
            ...item,
            isSelected: false,
          };
        });
      },
      async importRoster() {
        const { clientId } = this.program;
        const client = await this.fetchClient(clientId);
        const teamInfo = this.program.event.home;
        const playersIds = this.selectedPlayers.map(({ clientRosterPlayerId }) => clientRosterPlayerId);
        const playersMap = await this.fetchPlayers({ clientId, playersIds });
        const players = Object.values(playersMap).sort((a: PlayerDetails, b: PlayerDetails) => {
          return playersIds?.indexOf(a.clientRosterPlayerId) - playersIds?.indexOf(b.clientRosterPlayerId) ?? 0;
        });
        players.map((p) => {
          const httpRegex = /(http|https)/;
          if (p.rosterHeadshot !== '' && !httpRegex.test(p.rosterHeadshot)) {
            p.rosterHeadshot = `${client.siteUrl}${p.rosterHeadshot}`;
          }
          if (!p.rosterHeadshot.includes('images')) {
            p.rosterHeadshot = teamInfo.logoUrl;
          }
        });
        const headerText = 'Roster';
        this.setPageTitle(`${headerText} Import`);
        return {
          blocks: [
            {
              id: 1,
              data: { text: headerText },
              type: 'header',
              config: {
                label: 'Describe what this page is about',
                title: 'Page Heading',
                placeholder: 'Page Heading Text Goes Here...',
              },
            },
            {
              id: 2,
              type: 'textimage',
              config: {
                label: 'Choose which team this page is associated with',
                title: 'Team Heading',
                imageLabel: 'School Logo',
                displayConfig: true,
                firstTextDefault: 'mascot',
                secondTextDefault: 'name',
                placeholderFirstText: 'School Nickname/Mascot',
                placeholderSecondText: 'School Name',
              },
              data: {
                alt: `${teamInfo.mascot} team logo`,
                image: teamInfo.logoUrl,
                firstText: teamInfo.mascot,
                secondText: teamInfo.name,
                locationType: 'home',
              },
            },
            {
              id: 3,
              data: {
                players,
              },
              type: 'roster',
              config: {
                title: 'Roster Panel',
                subtitle: 'Components that build your roster page. Player bio & stats included within player cards',
              },
            },
          ],
        };
      },
      async onSubmit() {
        this.isFormValid = !!this.selectedPlayers.length;
        if (!this.isFormValid) return;
        try {
          this.toggleLoading();
          this.isFormDisabled = true;
          const content = await this.importRoster();
          this.setPageContent(content);
          this.goNext();
        } catch (error) {
          const msg = error?.response?.data || 'Could not import roster data';
          this.showToast(msg);
          // // console.warn(error);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
      reset() {
        this.selected = [];
        this.resetSelectedItems();
        this.isFormValid = true;
      },
    },
    async created() {
      try {
        this.isFormDisabled = true;
        this.isLoading = true;
        const { clientId } = this.program;
        // const { clientSportId } = this.program.sport;
        let clientSportId = this.program.sport.clientSportId;
        if (!clientSportId) {
          let pageSport = this.getPageSport();
          clientSportId = pageSport.clientSportId;
        }
        const sport = await this.fetchSport({ clientId, clientSportId });
        const { rosterId } = sport;
        const players = await this.fetchRosterPlayers({ clientId, rosterId });
        this.players = sortBy(Object.values(players), ['lastName', 'firstName']);
      } catch (error) {
        this.showToast('Could not fetch roster data');
        // // console.warn(error);
      } finally {
        this.isFormDisabled = false;
        this.isLoading = false;
      }
    },
    watch: {
      selectedPlayers(selected: RosterPlayer[]) {
        if (selected && selected.length) this.isFormValid = true;
      },
    },
  });
</script>
