import config from '@/constants/config';
import { SQSClient, GetQueueUrlCommand, CreateQueueCommand, SendMessageCommand } from '@aws-sdk/client-sqs';

const { secretAccessKey, accessKey, region, queueName } = config.aws.sqs;

const client = new SQSClient({
  region: region,
  credentials: {
    accessKeyId: accessKey,
    secretAccessKey: secretAccessKey,
  },
});

const getSqsQueueUrl = async (): Promise<string> => {
  const command = new GetQueueUrlCommand({ QueueName: queueName });
  try {
    const response = await client.send(command);
    return response.QueueUrl;
  } catch (e) {
    if (e.name == 'QueueDoesNotExist') {
      return await createSqsQueue();
    }
    return '';
  }
};

const createSqsQueue = async (): Promise<string> => {
  const command = new CreateQueueCommand({
    QueueName: queueName,
    Attributes: {
      ReceiveMessageWaitTimeSeconds: '20',
    },
  });
  try {
    const response = await client.send(command);
    return response.QueueUrl;
  } catch (e) {
    return '';
  }
};

const sendSqsMessage = async (tenantId: string, eventId: number, link: string) => {
  if (!secretAccessKey || !accessKey) {
    return null;
  }
  const sqsQueueUrl = await getSqsQueueUrl();
  const command = new SendMessageCommand({
    QueueUrl: sqsQueueUrl,
    MessageAttributes: {
      TenantId: {
        DataType: 'String',
        StringValue: tenantId,
      },
      EventId: {
        DataType: 'String',
        StringValue: eventId.toString(),
      },
      Link: {
        DataType: 'String',
        StringValue: link,
      },
    },
    MessageBody: "An event's Digital Program published status has been updated",
  });

  const response = await client.send(command);
  return response;
};

export { sendSqsMessage };
