<template>
  <v-container fluid fill-height class="grey darken-4 login-container">
    <v-layout flex align-center justify-center>
      <v-flex sm2 elevation-0>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="d-flex flex-column align-center mb-3">
            <v-img height="48" width="48" class="mb-4" src="login-logo.png"></v-img>
            <p class="login-title">Digital Programs Log In</p>
          </div>
          <label class="grey--text text--lighten-1 login-lbl">Email:</label>
          <v-text-field
            v-model="formData.email"
            class="login-input mt-2"
            :rules="emailRules"
            label="Email"
            data-testid="email-input"
            required
            dark
            solo
            outlined
          ></v-text-field>

          <label class="grey--text text--lighten-1 login-lbl">Password:</label>
          <v-text-field
            class="login-input mt-2"
            v-model="formData.password"
            :rules="passwordRules"
            type="password"
            data-testid="password-input"
            label="Password"
            required
            solo
            dark
            outlined
          ></v-text-field>
          <v-alert class="mt-4 mb-4" v-if="displayError" data-testid="error-message" type="error" icon="mdi-account">
            {{ errorMessage }}
          </v-alert>
          <v-btn
            block
            x-large
            color="grey lighten-4"
            class="mr-4 text-capitalize"
            data-testid="submit-btn"
            @click="submit"
          >
            Log In
          </v-btn>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { VForm } from '../types';
  import { mapActions } from 'vuex';
  import router from '@/router';

  export default Vue.extend({
    data: () => ({
      valid: true,
      formData: {
        email: '',
        password: '',
      },
      displayError: false,
      emailRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [(v: string) => !!v || 'Password is required'],
      select: null,
      checkbox: false,
      errorMessage: 'Something went wrong',
    }),
    computed: {
      form(): VForm {
        return this.$refs.form as VForm;
      },
    },
    methods: {
      ...mapActions('user', ['login']),

      async submit() {
        if (this.form.validate()) {
          try {
            await this.login(this.formData);
            router.push('/');
          } catch (err) {
            this.errorMessage = err.data || 'Something went wrong';
            this.displayError = true;
          }
        }
      },
    },
  });
</script>
<style lang="scss" scoped>
  .login-container {
    height: 100vh;
    .login-lbl {
      font-size: 14px;
    }

    .login-title {
      color: #e8e8e8;
    }
  }
</style>
