import { DateTime } from 'luxon';
import { DirectiveFunction } from 'vue';

const formatDate: DirectiveFunction = (el, binding) => {
  const { value, arg } = binding;
  if (typeof value !== 'string') return;
  const date = DateTime.fromISO(value);
  if (date.isValid) {
    el.innerHTML = typeof arg === 'string' && arg.trim() ? date.toFormat(arg) : date.toLocaleString();
  } else {
    // console.warn('Could not parse date on element', el);
  }
};

export default formatDate;
