export const USER_ROLES = [
  {
    value: 1,
    text: 'Super Admin',
    color: 'grey darken-4',
  },
  {
    value: 2,
    text: 'Learfield Admin',
    color: 'grey darken-1',
  },
  {
    value: 3,
    text: 'Client Admin',
    color: 'grey',
  },
];

export const ROLES = {
  SUPER_ADMIN: 1,
  LEARFIELD_ADMIN: 2,
  CLIENT_ADMIN: 3,
};
