<template>
  <div class="clients-view">
    <app-bar>
      Digital Programs Clients
      <template #actions>
        <nav-action-primary @click="toggleModal()">Add new Client</nav-action-primary>
      </template>
    </app-bar>
    <v-container class="pt-0" fluid>
      <card>
        <template #title>All Clients</template>
        <v-card-text class="px-6 pb-6 pt-4">
          <v-row>
            <v-col>
              <v-subheader class="px-0">Search</v-subheader>
              <search dense placeholder="School Name" v-model="searchTmp" @submit="submitSearch" />
            </v-col>
            <v-col offset-md="6" class="d-flex align-end justify-end">
              <v-btn
                large
                depressed
                :color="viewType === 'grid' ? 'primary' : 'grey lighten-4'"
                class="mr-4 text-capitalize font-weight-bold"
                data-testid="submit-btn"
                height="36"
                @click="setView('grid')"
              >
                <span class="mr-2">Grid View</span> <v-icon>mdi-view-grid-outline</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                :color="viewType === 'list' ? 'primary' : 'grey lighten-4'"
                class="mr-4 text-capitalize font-weight-bold"
                data-testid="submit-btn"
                height="36"
                @click="setView('list')"
              >
                <span class="mr-2">List View</span> <v-icon>mdi-format-list-bulleted</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-sheet
            color="transparent"
            min-height="294"
            v-if="loading"
            class="d-flex flex-column align-center justify-center py-16"
          >
            <v-progress-circular indeterminate size="48"></v-progress-circular>
          </v-sheet>
          <v-sheet color="transparent" v-if="!loading && clients.length" :class="viewType === 'grid' ? 'pa-6' : ''">
            <ClientsList
              :clients="clients"
              :page="page"
              :pages="pages"
              :items-per-page="15"
              v-if="viewType === 'list'"
              data-testid="client-list"
            />
            <ClientsGrid :clients="clients" v-else data-testid="client-grid" />
          </v-sheet>
          <v-sheet
            color="transparent"
            v-if="!loading && !clients.length"
            min-height="294"
            class="text-center text--disabled d-flex flex-column align-center justify-center"
          >
            <div v-if="hasFilters">
              <v-icon color="secondary lighten-4" size="52">mdi-text-search-variant</v-icon>
              <p class="mt-4 mb-2 text-h6 font-weight-medium">No results found.</p>
              <p class="mb-0 text-body-2">Please try changing the filters or search term.</p>
            </div>
            <div v-else>
              <v-icon color="secondary lighten-4" size="52">mdi-school-outline</v-icon>
              <p class="mt-4 mb-2 text-h6 font-weight-medium">You haven't added any clients yet.</p>
              <p class="mb-0 text-body-2">Click the button in the upper right corner to find and add clients.</p>
            </div>
          </v-sheet>
        </v-card-text>
        <v-divider v-if="pages"></v-divider>
        <template v-if="pages" #actions>
          <v-spacer></v-spacer>
          <pagination v-model="page" total-visible="10" :length="pages" />
        </template>
      </card>
    </v-container>
    <NewClientModal v-model="modalOpen" @save="refreshList"></NewClientModal>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { AppBar, NavActionPrimary, Card, Search, Pagination } from '@/components';
  import { ClientsList, NewClientModal, ClientsGrid } from '@/components/Clients';
  import { mapActions, mapGetters } from 'vuex';

  export default Vue.extend({
    components: {
      AppBar,
      Card,
      NavActionPrimary,
      NewClientModal,
      ClientsList,
      Search,
      Pagination,
      ClientsGrid,
    },
    name: 'Clients',
    computed: {
      ...mapGetters('client', { clients: 'getClients', pages: 'getPages', viewType: 'getViewType' }),
      hasFilters(): boolean {
        return this.search.length > 0;
      },
    },
    methods: {
      ...mapActions('client', ['fetchClients', 'setViewType']),
      ...mapActions('ui', { showToast: 'showToast' }),
      toggleModal() {
        this.modalOpen = !this.modalOpen;
      },
      submitSearch() {
        const { searchTmp } = this;
        const isValid = searchTmp.length === 0 || searchTmp.length > 3;
        this.search = isValid ? searchTmp : '';
        this.page = 1;
        this.refreshList();
      },
      async refreshList() {
        this.loading = true;
        try {
          const { search } = this;
          let { page } = this;
          await this.fetchClients({ name: search || undefined, PageIndex: page });
          if (this.pages > 0 && page > this.pages) {
            this.page = page = this.pages;
            await this.fetchClients({ name: search || undefined, PageIndex: page });
          }
        } catch (error) {
          this.showToast('Could not fetch clients list');
          // console.warn(error);
        }
        this.loading = false;
      },
      setView(type: string) {
        this.setViewType(type);
      },
    },
    data() {
      return {
        modalOpen: false,
        loading: true,
        searchTmp: '',
        search: '',
        page: 1,
      };
    },
    created() {
      this.refreshList();
    },
    watch: {
      page() {
        this.refreshList();
      },
      searchTmp(newVal: string) {
        if (newVal === '') {
          this.search = '';
          this.page = 1;
          this.refreshList();
        }
      },
    },
  });
</script>
