<template>
  <div>
    <div class="d-flex align-center">
      <div class="img-preview mr-4">
        <div v-if="imageUrl && imageUrl.split('.').pop().includes('pdf')" id="pdf-upload-image"></div>
        <v-img
          v-else
          :height="requiredDimensions ? 50 : height"
          :width="requiredDimensions ? 150 : width"
          :src="imageUrl || '/default-img.png'"
          class="rounded-sm"
        ></v-img>
      </div>
      <v-sheet v-if="requiredDimensions" outlined color="grey lighten-1" class="mr-4" rounded>
        <v-card outlined elevation="0" class="img-dimensions d-flex align-center text-body-1 pa-3 black--text">
          <v-icon medium color="black" class="pr-2"> mdi-image-outline </v-icon>{{ requiredWidth }}px x
          {{ requiredHeight }}px</v-card
        >
      </v-sheet>
      <label class="custom-file-upload grey lighten-4 font-weight-bold text-capitalize black--text ml-4">
        <v-file-input
          :accept="accept"
          v-model="file"
          class="file-input"
          :rules="rules"
          ref="myFile"
          @change="selectedFile"
          @click="$event.target.value = null"
          truncate-length="15"
        ></v-file-input>
        Choose {{ allowPdf ? `File` : `Image` }}
      </label>

      <v-btn
        large
        depressed
        color="grey lighten-4 ml-4"
        class="mr-4 text-capitalize font-weight-bold black--text"
        height="36"
        @click="clearImage()"
      >
        <span class="mr-2">Clear Image</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { fileDelete, fileUpload } from '@/utils/aws';
  import { mapActions } from 'vuex';
  import PDFObject from 'pdfobject';

  export default Vue.extend({
    name: 'UploadImage',
    props: {
      fileUrl: {
        type: String,
        default: '',
        required: false,
      },
      requiredHeight: {
        type: Number,
        default: null,
        required: false,
      },
      requiredWidth: {
        type: Number,
        default: null,
        required: false,
      },
      clientId: {
        type: Number,
        required: false,
      },
      value: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
      },
      height: {
        type: String,
        default: '60',
        required: false,
      },
      width: {
        type: String,
        default: '60',
        required: false,
      },
      allowPdf: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data: function () {
      return {
        imageUrl: this.fileUrl || null,
        maxFileSize: 5000000,
        file: null,
      };
    },
    watch: {
      fileUrl: {
        handler(val) {
          this.imageUrl = val;

          if (val && val.split('.').pop().includes('pdf')) {
            this.$nextTick().then(() => {
              PDFObject.embed(val, '#pdf-upload-image', { forceIframe: true, pdfOpenParams: { toolbar: 1 } });
            });
          }
        },
        immediate: true,
      },
    },
    computed: {
      accept() {
        if (this.allowPdf) return 'image/*,.pdf';
        return 'image/*';
      },
      requiredDimensions(): boolean {
        return !!this.requiredHeight && !!this.requiredWidth;
      },
      s3Folder(): string {
        return `digital_programs/${this.clientId || 0}/images`;
      },
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),

      selectedFile() {
        let file: File = this.file;

        if (file.size > this.maxFileSize) {
          this.showToast(`The image size must be lower than 5mb`);
          return false;
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async (evt) => {
          let file: File = this.file;

          if (file.type.indexOf('image/') === 0) {
            let img = new Image();

            img.onload = async () => {
              await this.checkDimensions(img);
            };

            img.src = evt.target.result as string;
          }

          this.uploadFile(file);
        };

        // reader.onerror = (evt) => {
        //   console.error(evt);
        // };
      },
      async uploadFile(file: File) {
        const result = await fileUpload(file, this.s3Folder);

        if (result.status !== 200) {
          this.showToast('Something went wrong');
        } else {
          this.imageUrl = result.file;
          this.$emit('input', this.imageUrl);
        }
      },
      clearImage() {
        this.imageUrl = '';
        this.$emit('input', this.imageUrl);
        fileDelete(this.value);
      },
      async checkDimensions(img: HTMLImageElement) {
        if (this.requiredDimensions) {
          if (img.width !== this.requiredWidth || img.height !== this.requiredHeight) {
            this.showToast(`The image dimensions must be ${this.requiredWidth}px x ${this.requiredHeight}px`);
            return false;
          }
        }
      },
    },
  });
</script>
<style lang="sass" scoped>
  input[type='file']
    display: none

  .custom-file-upload
    display: inline-block
    padding: 6px 12px
    cursor: pointer
    font-size: 0.875rem
    white-space: nowrap

  .img-dimensions
    width: 380px
    height: 41px

  .file-input
    display: none

  #pdf
    border: 1px solid gray
    min-height: 255px // min-height to show toolbar
    min-width: 425px // min-width to show toolbar
    display: grid
</style>
